import { Row, Col, Container, Button, Form } from "react-bootstrap";
import {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
  useRef,
} from "react";
import {
  AddNewCount,
  AddNewGroove,
  UpdateGroove,
} from "../../Services/wps_groove-services";
import { useLocation } from "react-router-dom";
import {
  isAlphanumericAllCharacters,
  isAlphanumericAllCharactersextra,
} from "../../functions/validations";
import axios from "axios";
import { getPresignedURL } from "../../Services/Welder-services";

import defaultimg from "../../icons/noimage.png";
import { AddLogs } from "../../Services/wps-logs-services";
import {
  General_Single_Input_select,
  Single_Input,
  Single_Input_textara,
  Single_Input_textaraNotes,
} from "./WPSObjects/WPS_Process_Grid";
import {
  IsUserMasterBand,
  getAllUserMaster,
  getbyuserid,
} from "../../Services/user-service";
import {
  Base_HT,
  MessageTypes,
  wire_brush,
  WpsDetail,
  WPSH_PWHeatTre_Type,
  WPSH_Tech_BackGougingMethod,
  WPSPage1,
  WPSPage1_Overlay,
  WPSPage3,
  WPS_Groove_Detail,
  WPS_Overlay_Detail,
  yesno,
  wps_field_Val,
  wps_field_Val_Overlay,
  WPSPage2,
} from "./WPSObjects/WPSGroove_entity";
import { Action, Status } from "./WPSObjects/WPSGroove_entity";
import {
  WPS_Image,
  WPS_ImageGO,
  WPS_Image_Groove,
} from "./WPSObjects/WPS_Image";
import { DisableFields } from "./WPSObjects/WPS_common";
import { WPSHeader } from "./WPSObjects/WPSHeader";
import { SearchSelect, SearchSelectprename } from "../utility/search-select";
import {
  Date_Input,
  Date_InputUser,
} from "../utility/CommonComponents/Date_Component";

const WeldingProcedureSpec = forwardRef(
  (
    {
      headerObj,
      setheaderObj,
      detailObj,
      setdetailObj,
      copydata,
      logObj,
      setKey,
      tabno,
      setlogObj,
      setIsEditedPage3,
    },
    ref
  ) => {
    const location = useLocation();

    let userprofile = sessionStorage.getItem("UserProfile");
    let Band = sessionStorage.getItem("Band");
    console.log("fdzfdz", Band);
    let username = sessionStorage.getItem("UserName");

    let folderwld = "wld_signatures";
    let folderwps = "WPS_Groove";

    let formattedDate;

    const avatar = { defaultimg };
    const [action, setaction] = useState(Action.ActionEdit);

    const FilemaxSize = 1 * 1024 * 1024;

    const [WPStype, setWPStype] = useState(location.state?.WPStype);

    const [users, setUsers] = useState(null);

    const [userApproveSignature, setuserApproveSignature] = useState(null);

    const [IsEdited, setIsEdited] = useState(false);

    const navigate = useLocation();

    const [ImageView1, setImageView1] = useState(false);
    const [ImageView2, setImageView2] = useState(false);
    const [ImageView3, setImageView3] = useState(false);

    const [profile, setProfile] = useState("");
    const [profile1, setProfile1] = useState("");
    const [profile2, setProfile2] = useState("");

    const [previewUrl, setPreviewUrl] = useState([]);

    const [previewUrl1, setPreviewUrl1] = useState();
    const [previewUrl2, setPreviewUrl2] = useState();
    const [previewUrl3, setPreviewUrl3] = useState();

    const [previewuser, setPreviewuser] = useState(
      headerObj?.wps_signaturePrepared
        ? headerObj?.wps_signaturePrepared
        : avatar.defaultimg
    );
    const [previewapproved, setPreviewapproved] = useState(
      headerObj?.wps_signatureApproved
        ? headerObj?.wps_signatureApproved
        : avatar.defaultimg
    );
    const [Readonly, setReadonly] = useState(
      DisableFields(headerObj, Status, Action, Action.ActionEdit)
        ? "disabled"
        : ""
    );
    const [previewapproveduser, setPreviewapproveduser] = useState(
      avatar.defaultimg
    );

    // const [profile, setProfile] = useState("");
    const [today, setToday] = useState("");

    console.log("headerr", headerObj);

    const todaynew = new Date();
    const yyyy = todaynew.getFullYear();
    let mm = todaynew.getMonth() + 1;
    let dd = todaynew.getDate();
    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    const todayDate = yyyy + "-" + mm + "-" + dd;
    const maxDate = todaynew;
    const minDate = yyyy + "-" + mm + "-" + dd;

    // console.log("detaillllll", detailObj);

    const [imgObj, setimgObj] = useState([]);
    const [imgDataObj, setimgDataObj] = useState(
      headerObj.wld_pdf_image ? headerObj.wld_pdf_image : []
    );

    const fetchUserMaster = async () => {
      const result = await getAllUserMaster();
      console.log("allllalla", result);
      const active = result.filter((item) => item.um_status === "Active");
      setUsers(active);
    };

    const fetchUserId = async () => {
      try {
        // Ensure users and headerObj are defined and have the necessary properties
        if (!headerObj || !headerObj.WPSH_ApprovedBy_Name) {
          console.error(
            "users or headerObj.WPSH_ApprovedBy_Name is not defined"
          );
          return;
        }

        // Filter users to find the one matching the approved name
        const Approveduserid = users?.filter(
          (item) => item?.um_name === headerObj?.WPSH_ApprovedBy_Name
        );
        console.log("abcdaaaaaaa", Approveduserid);

        // Check if the user was found
        if (Approveduserid.length === 0) {
          console.error("No user found with the approved name");
          return;
        }

        if (Approveduserid && Approveduserid.length > 0) {
          setuserApproveSignature(Approveduserid[0]);
        } else {
          console.error("No data returned from getbyuserid");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    console.log("userApproveSignature", userApproveSignature);

    console.log("jsjsjsjsjss", users);
    useEffect(() => {
      fetchUserMaster();
    }, []);

    useEffect(() => {
      fetchUserId();
    }, [users, headerObj?.WPSH_ApprovedBy_Name]);

    const handleChange = (e) => {
      console.log("change1");
      if (action === Action.ActionEdit) setIsEdited(true);
      setIsEditedPage3(true);
      const { name, value } = e.target;

      setheaderObj((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };

    const [valueNotes, setValueNotes] = useState("");
    const maxNewLines = 13; // Maximum allowed new lines

    const handleChangeNotes = (e) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      setIsEditedPage3(true);
    
      const { name, value } = e.target;
      
      // Split the input text into lines based on existing newlines
      const lines = value.split('\n');
      
      // Limit each line to 100 characters
      const formattedLines = lines.map(line => {
        if (line.length > 100) {
          // Break the line into chunks of 100 characters
          return line.match(/.{1,100}/g).join('\n');
        }
        return line;
      });
    
      // Join the lines back together
      let formattedValue = formattedLines.join('\n'); // Use 'let' here instead of 'const'
      
      // Count the number of new lines
      const newLinesCount = (formattedValue.match(/\n/g) || []).length;
      
      if (newLinesCount <= maxNewLines) {
        setValueNotes(formattedValue);
    
        // Update the header object state with the new value
        setheaderObj((prevState) => ({
          ...prevState,
          [name]: formattedValue,
        }));
      } else {
        e.preventDefault(); // Prevent further input if the new line limit is reached
      }
    };

const handleKeyDown = (event) => {
  if (event.key === "Enter") {
    const newLinesCount = (valueNotes.match(/\n/g) || []).length;
    if (newLinesCount >= maxNewLines) {
      event.preventDefault(); // Prevent adding more than 13 new lines
    }
  }
};

    const handleChangeRadio = async (e) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      if (!(await validate(detaildata)) && !(await validate2(detaildata))) {
        const { name, value } = e.target;

        setheaderObj((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else {
        alert(
          "Cannot Approve or Reject the Record, Please Fill Required Fields of all 3 Pages."
        );
      }
    };

    const handleSelectChange = (e) => {
      console.log("change2");
      if (action === Action.ActionEdit) setIsEdited(true);
      setIsEditedPage3(true);
      const { name, value } = e.target;
      setheaderObj((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      setError((prevState) => ({
        ...prevState,
        [name]: { status: false, message: "" },
      }));
    };

    //    console.log("no",location.state?.WPS_Change_No)

    const [error, setError] = useState({
      wps_No: {},
      wps_QualifiedTo: {},
      wps_SupportingPQR: {},
      wps_ReferenceDoc: {},
      wps_Scope: {},
      wps_JointDesc: {},
      wps_Type: {},
      wps_WeldedTo: {},
      wps_GNoType: {},
      wps_GNoWeldedTo: {},
      wps_GNoBacking: {},
      wps_PNoBacking: {},
      wps_Retainer: {},
      wps_Notes: {},
      WPSH_PHT_ApplicationStd: {},
      WPSH_PHT_Desc: {},
      WPSH_PWHeatTre_Temperature: {},
      WPSH_PWHeatTre_Time: {},
      WPSH_PWHeatTre_Heating_Rate: {},
      WPSH_PWHeatTre_Heating_Method: {},
      WPSH_PWHeatTre_Cooling_Rate: {},
      WPSH_PWHeatTre_Cooling_Method: {},
      // WPSH_PWHeatTre_Type: {},
      WPSH_PWHeatTre_Notes: {},
      WPSH_PWHeatTre_Weld_Notes: {},
      WPSH_PWHeatTre_Post_Heat_temp: {},
      WPSH_PWHeatTre_Post_Heat_time: {},
      WPSH_PWHeatTre_Wrap_insulation_material: {},
      WPSH_PHT_Root_spacing: {},
      WPSH_PWHeatTre_ISR_Temp: {},
      WPSH_PWHeatTre_ISR_Time: {},
      WPSH_Tech_Change_Electrode_dia: {},

      WPSH_Tech_BackGougingMethod: {},
      WPSH_Tech_ClosedtooutChamber: {},
      WPSH_Tech_GroovePreparation: {},
      WPSH_Tech_Inital_InterpassCleaning: {},
      WPSH_Tech_Peening: {},
      WPSH_Tech_SupplementaryMFControl: {},
      WPSH_Tech_SurfacePreparation: {},
      WPSH_Tech_UseOfThermalProcess: {},
      WPSH_Tech_Weld_deposit_chemistry: {},
      WPSH_Tech_Oscillation: {},
      WPSH_Notes: {},
      WPSH_PreparedBy_Date: {},
      WPSH_PreparedBy_Name: {},
      WPSH_ApprovedBy_Date: {},
      WPSH_ApprovedBy_Name: {},
      WPSH_PreparedBy_Remark: {},
      WPSH_ApprovedBy_Remark: {},
      WPSH_Tech_Oscillation_Width: {},
      WPSH_Tech_Oscillation_Freq: {},
      WPSH_Tech_Filler_Metal_Del: {},
    });

    const validate = async () => {
      // let entries = Object.entries(restObj,detaildata);
      let entries1;
      let entries3;
      console.log("Third Page", WPSPage3);
      entries3 = Object.entries(WPSPage3);
      console.log("Third Page1");
      if (WPStype === "Groove") {
        entries1 = Object.entries(WPSPage1);
      } else if (WPStype === "Overlay") {
        entries1 = Object.entries(WPSPage1_Overlay);
      }
      console.log("Third Page2");
      let isError = false;

      entries1.map(([key, val]) => {
        if (headerObj[key] === "") {
          isError = true;
          error[key] = {
            status: true,
            message: `This field is required`,
          };
        }
      });

      entries3.map(([key, val]) => {
        if (headerObj[key] === "") {
          isError = true;
          error[key] = {
            status: true,
            message: `This field is required`,
          };
        }
      });

      console.log("validate", error);
      setError((prevState) => ({
        ...prevState,
        ...error,
      }));

      return isError;
    };

    const validate2 = async (detaildata) => {
      console.log("validate");
    
      var errortype = WPStype === "Groove" ? WPS_Groove_Detail : WPS_Overlay_Detail;
      var errortypeVal = WPStype === "Groove" ? wps_field_Val : wps_field_Val_Overlay;
    
      var validprocess = detailObj.filter((item) => item.WPSD_Process !== "");
    
      let entries = Object.entries(detaildata);
      let entries3 = Object.entries(WPSPage2);
      let localError = { ...error }; // Use a local variable to track errors
      var regExp = /^[A-Za-z0-9\s,+:;.@#$&()*!-<>%/_]+$/;
      let isError = false;
    
      errortype.forEach((cols) => {
        entries.forEach(([key, val]) => {
          console.log("validate2_pg2_key", key);
    
          var value = "";
          var msg = "";
          console.log("detailObj[key]_pag2", detailObj[key][cols]);
    
          if (cols.Type === "S") {
            console.log("cols.Type === S", detailObj[key][cols.Column]);
            value = detailObj[key][cols.Column];
            msg = `1) At least one field of each process is Mandatory.\n2) No Special Characters other than space, comma, +, -, colon, semicolon, dot, @, #, $, &, (), *, !, _, / are allowed.`;
    
            if (!value.match(regExp)) {
              console.log("Error found in value", value);
              isError = true;
              localError[cols.Column] = {
                status: true,
                message: msg,
              };
            }
    
          } else if (cols.Type === "A") {
            console.log("here_value_pg2", detailObj[key][cols.Column]);
            console.log("here_value_pg2_111", detailObj[key][cols.Column][1]);
    
            // Extract values at indices 0, 1, 2
            const values = [
              detailObj[key]?.[cols.Column]?.[0],
              detailObj[key]?.[cols.Column]?.[1],
              detailObj[key]?.[cols.Column]?.[2],
            ];
    
            // Check if at least one value is non-empty
            const hasValue = values.some(val => val !== undefined && val !== "" && val !== null);
            const msg = `1) At least one field of each process is Mandatory.\n2) No Special Characters other than space, comma, +, -, colon, semicolon, dot, @, #, $, &, (), *, !, _, / are allowed.`;
    
            if (!hasValue) {
              console.log("blankcheck");
              isError = true;
              localError[cols.Column] = {
                status: true,
                message: msg,
              };
            } else {
              // Check each value for special characters
              let errorFound = values.some(val => val !== undefined && val !== "" && val !== null && !val.match(regExp));
              if (errorFound) {
                isError = true;
                localError[cols.Column] = {
                  status: true,
                  message: msg,
                };
              } else {
                console.log("blankcheck out");
                localError[cols.Column] = {
                  status: false,
                  message: "",
                };
              }
            }
          }
          console.log("Final_here_value_pg2", value);
        });
      });
    
      console.log("eerrval", localError, isError);
    
      entries3.forEach(([key, val]) => {
        if (headerObj[key] === "") {
          isError = true;
          localError[key] = {
            status: true,
            message: "This field is required",
          };
        }
      });
    
      errortypeVal.forEach((cols) => {
        entries.forEach(([key, val]) => {
          console.log("validate2_pg2_key", key);
      
          if (cols.Type === "A") {
            console.log("here_value_pg2", detailObj[key][cols.Column]);
      
            // Extract values at indices 0, 1, 2
            const values = [
              detailObj[key]?.[cols.Column]?.[0],
              detailObj[key]?.[cols.Column]?.[1],
              detailObj[key]?.[cols.Column]?.[2],
            ];
      
            // Initialize error status for this column
            let columnHasError = false;
            const msg = `1) At least one field of each process is Mandatory.\n2) No Special Characters other than space, comma, +, -, colon, semicolon, dot, @, #, $, &, (), *, !, _, / are allowed.`;
      
            // Check if at least one value is non-empty
            const hasValue = values.some(val => val !== undefined && val !== "" && val !== null);
      
            if (!hasValue) {
              console.log("blankcheck");
              columnHasError = true;
            } else {
              // Check each value for special characters
              for (let i = 0; i < values.length; i++) {
                const val = values[i];
                if (val !== undefined && val !== "" && val !== null && !val.match(regExp)) {
                  // console.log(Error found in element ${i}:, ${val});
                  columnHasError = true;
                  break; // Break out of the loop as soon as an error is found
                }
              }
            }
      
            // Update the error status for this column
            if (columnHasError) {
              console.log("noooooeee");
              isError = true;
              localError[cols.Column] = {
                status: true,
                message: msg,
              };
            } else {
              console.log("blankcheck out");
              // Only update to false if there wasn't an error previously
              if (!localError[cols.Column]?.status) {
                localError[cols.Column] = {
                  status: false,
                  message: "",
                };
              }
            }
          }
        });
      });
      
      // Update the state only once at the end
      setError(localError);
      
      // Check if any error status is true in the localError object
      const hasError = Object.values(localError).some((err) => err.status === true);
      if (!hasError) {
        isError = false;
      }
      
      console.log("fmmdm", localError, isError);
      return isError;
    };



    const uploadImage = async (item) => {
      let unique = headerObj.WPSH_Project_No + "_" + headerObj.WPSH_WPS_No;
      const result = await getPresignedURL(folderwps, item.value.name);
      const posturl = result.url;
      // setPreviewUrl(posturl);
      console.log("img", posturl);
      await axios.put(posturl, item.value);
      // console.log("putt",posturl,fileInput.files[0])
    };

    const handleCount = () => {
      let result;

      if (action === Action.ActionEdit) {
        if (headerObj.WPSH_Status === Status.StatusApproved) {
          console.log("no", location.state?.WPS_Change_No);

          headerObj.WPS_Change_No = location.state?.WPS_Change_No;
          result = AddNewCount(headerObj);
        }
      }
    };

    const handleAdd = async (approvalstatus) => {
      let result = false;
      let previousStatus = headerObj.WPSH_Status;
      console.log("headerObj.WPSH_Status", headerObj.WPSH_Status);
      console.log("Tempstatus2", headerObj.WPSH_Status);
      if (headerObj?.WPSH_Status === Status.StatusApproved) {
        console.log("handle count3", headerObj.WPSH_Status);

        handleCount();
      }
      console.log("Third page", headerObj.WPSH_Approval_Status, approvalstatus);
      if (
        headerObj.WPSH_Approval_Status !== Status.StatusTempSaved &&
        headerObj.WPSH_Approval_Status !== undefined
      )
        headerObj.WPSH_Status = headerObj.WPSH_Approval_Status;
      else headerObj.WPSH_Status = approvalstatus;
      // if(approvalstatus !=="")
      //   headerObj.WPSH_Status =  approvalstatus;

      if (Band === "P" || Band === "T") {
        headerObj.wps_signatureApproved = headerObj.wps_signatureApproved;
        headerObj.WPSH_ApprovedBy_Name = headerObj.WPSH_ApprovedBy_Name;
        // headerObj.WPSH_ApprovedBy_Date = todayDate;
        headerObj.WPSH_ApprovedBy = headerObj.WPSH_ApprovedBy_Name;
      }
      if (Band !== "T" || Band !== "P") {
        headerObj.WPSH_PreparedBy_Name = headerObj.WPSH_PreparedBy_Name;
        // headerObj.WPSH_PreparedBy_Date = todayDate;
        headerObj.wps_signaturePrepared = headerObj.wps_signaturePrepared;
      }
      console.log("third page", "1");
      let res = await AddNewGroove(headerObj)
        .catch((e) => {
          console.log("error in third page", e);
          result = false;
          //return result
        })
        .then(async () => {
          try {
            if (profile !== "") {
              if (headerObj?.wld_pdf_image[0]?.Image1[0] !== "") {
                console.log(
                  "insideUrl",
                  headerObj?.wld_pdf_image[0]?.Image1[0]
                );
                const result = await getPresignedURL(
                  folderwps,
                  headerObj.wld_pdf_image[0].Image1[0]
                );
                console.log(result);
                const posturl = result.url;
                setPreviewUrl1(posturl);
                console.log("img", posturl);
                console.log("puttprofile", profile);

                if (profile !== "") {
                  await axios.put(posturl, profile);
                }
                console.log("puttprofile111", profile);
                setImageView1(true);
              }
            }
            if (profile1 !== "") {
              if (headerObj?.wld_pdf_image[1].Image2[0] !== "") {
                console.log("insideUrl", headerObj?.wld_pdf_image[1].Image2[0]);
                const result = await getPresignedURL(
                  folderwps,
                  headerObj?.wld_pdf_image[1].Image2[0]
                );
                console.log(result);
                const posturl = result.url;
                setPreviewUrl2(posturl);
                console.log("img", posturl);

                console.log("puttprofile1", profile1);
                if (profile1 !== "") {
                  await axios.put(posturl, profile1);
                }

                console.log("puttprofile", profile1);
                setImageView2(true);
              }
            }

            if (profile2 !== "") {
              if (headerObj?.wld_pdf_image[2].Image3[0] !== "") {
                console.log("insideUrl", headerObj?.wld_pdf_image[2].Image3[0]);
                const result = await getPresignedURL(
                  folderwps,
                  headerObj?.wld_pdf_image[2].Image3[0]
                );
                console.log(result);
                const posturl = result.url;
                setPreviewUrl3(posturl);
                console.log("img", posturl);
                console.log("puttprofile2", profile2);
                if (profile2 !== "") {
                  await axios.put(posturl, profile2);
                }
                console.log("puttprofile", profile2);
                setImageView3(true);
              }
            }
          } catch (error) {
            if (error.request) {
              console.log("hii", error.request);
            } else {
              console.log("hiiiii", `Error: ${error.message}`);
            }
          }
          result = true;
          console.log("third page", result, "done");
          if (imgObj.length > 0) {
            for (let item of imgObj) {
              console.log("in loop images", item);
              if (item.value.name) await uploadImage(item);
            }
          }
          console.log("third page", result, "image done");
          if (
            action == Action.ActionEdit &&
            previousStatus == Status.StatusApproved //only if its Approved add logs
          ) {
            console.log("Add logs3");
            await AddLogs(logObj);
            console.log("third page", result, "log done");
          }
          //return result
        });
      console.log("Third page last", result);
      return Promise.resolve(result);
    };

    const checkIfUsernameExists = async () => {
      //return users && users.some((user) => user.um_band === "P" || user.um_band === "T" );
      let res = await IsUserMasterBand();
      console.log("IsUserMasterBand Y/N", res.data.message);
      if (res != undefined) return res.data.message;
      else return false;
    };

    const handleSubmitTemp = async () => {
      var res = false;

      console.log("third page Submit", IsEdited);

      if (IsEdited) {
        setIsEdited(false);
        setIsEditedPage3(false);
        // res = await handleAdd(Status.StatusTempSaved)
        console.log("third page Submit no ");
        res = await handleAdd(Status.StatusTempSaved);

        if (res) {
          console.log("Third Page Tab", tabno);
          // toast.success("Temporary Saved WPS Data Successfully")
          return MessageTypes.Temp;
        } else {
          return MessageTypes.Fail;
        }
      }

      return MessageTypes.None;
    };

    var detaildata = detailObj.filter((item) => item.WPSD_Process !== "");

    const handleSubmit = async () => {
      setIsEdited(false);
      setIsEditedPage3(false);

      console.log("wps_detail", detaildata);
      var result = false;
      const isUsernamePresent = await checkIfUsernameExists();

      if (isUsernamePresent) {
        headerObj.WPSH_Status = Status.StatusTempSaved;
      }

      //Va;idate All pages
      if (!(await validate(detaildata)) && !(await validate2(detaildata))) {
        result = handleAdd(Status.StatusPending);

        if (result) {
          // setKey(+tabno - 2)
          console.log("Key", tabno);
          // toast.success("WPS Data Added Successfully")
          return MessageTypes.Success;
        } else {
          return MessageTypes.Fail;

          // toast.warning("Failed, WPS Data could not be saved")
        }
      } else {
        // toast.warning("Required Fields not filled ")
        setTimeout(() => {
          if (action === Action.ActionAdd) {
            alert("Please Temp Save Data Before Leaving this Page");
          }
        }, 500);
        return MessageTypes.RequiredAll;
      }
    };

    useImperativeHandle(ref, () => ({
      funcOnNext: async () => {
        var res = false;
        res = await handleSubmit();
        return res;
        //  if(WPStype ==="Overlay"){
        //   res = await handleSubmit();
        //   return res
        //  }
      },

      funcOnNextTemp: async () => {
        var res = false;
        console.log("is temporary", res);
        res = await handleSubmitTemp();
        console.log("is temporary", res);
        return res;
      },
    }));

    const getImgURL = (name) => {
      let temp = previewUrl.filter((item) => {
        return item.name === name;
      });
      return temp[0]?.value;
    };

    const handleOndragOver = (event) => {
      event.preventDefault();
    };

    const handleOndrop = (event) => {
      let imageFile = event.dataTransfer.files[0];
      // handleFile(imageFile);
      console.log("drop", imageFile);
    };

    const handleFile1 = (file, index, e) => {
      const maxSize = FilemaxSize;
      if (file.size > maxSize) {
        alert("File size exceeds the allowed limit. Add File Less Than 1 Mb");
        file = null;
        return;
      }

      let uploadextension = file.name.split(".");
      console.log("fffnfifm", uploadextension);

      if (
        uploadextension[1] !== "jpeg" &&
        uploadextension[1] !== "png" &&
        uploadextension[1] !== "jpg"
      ) {
        console.log("nooooouploadeddddd");
        alert("Please Upload Jpeg,png or jpg images");
        file = null;
        return;
      }

      if (index == 0) {
        setProfile(file);
        headerObj.wld_pdf_image[0].Image1[0] = file.name;
        setPreviewUrl1(URL.createObjectURL(file));
      } else if (index == 1) {
        setProfile1(file);
        setPreviewUrl2(URL.createObjectURL(file));
        headerObj.wld_pdf_image[1].Image2[0] = file.name;
      } else if (index == 2) {
        setProfile2(file);
        setPreviewUrl3(URL.createObjectURL(file));
        headerObj.wld_pdf_image[2].Image3[0] = file.name;
      }
    };

    const handleFile = (e) => {
      let unique = headerObj.WPSH_Project_No + "_" + headerObj.WPSH_WPS_No;

      console.log("FILEEe", unique + "_" + e.target.files[0], e.target.files);

      console.log("FILEEedddd", e.target.files[0].name);

      let file = e.target.files[0].name;

      console.log("FILEEefffdddd", file);

      let uploadextension = file.split(".");
      console.log("fffnfifm", uploadextension);

      if (
        uploadextension[1] !== "jpeg" &&
        uploadextension[1] !== "png" &&
        uploadextension[1] !== "jpg"
      ) {
        console.log("nooooouploadeddddd");
        alert("Please Upload Jpeg,png or jpg images");
        file = null;
        return;
      }

      setimgObj(imgObj.filter((item) => item.name !== e.target.name));
      let img = { name: e.target.name, value: e.target.files[0] };
      setimgObj((result) => [...result, img]);

      setimgDataObj(imgDataObj.filter((item) => item.name !== e.target.name));
      let img1 = { name: e.target.name, value: e.target.files[0].name };
      setimgDataObj((result) => [...result, img1]);

      setPreviewUrl(previewUrl.filter((item) => item.name !== e.target.name));
      let a = {
        name: e.target.name,
        value: URL.createObjectURL(e.target.files[0]),
      };
      setPreviewUrl((prev) => [...prev, a]);
    };
    //console.log("iiiiiii", previewUrl);

    useEffect(() => {
      if (imgDataObj.length > 0) {
        setheaderObj((prevState) => ({
          ...prevState,
          ["wld_pdf_image"]: imgDataObj,
        }));
      }
    }, [imgDataObj]);

    const fetchImages = async (item) => {
      try {
        let unique = headerObj.WPSH_Project_No + "_" + headerObj.WPSH_WPS_No;
        if (item.value != "" || item.value != undefined) {
          const result = await getPresignedURL(folderwps, item.value);
          const geturl = result.url;
          console.log("geturl", geturl);
          let a = { name: item.name, value: geturl };
          setPreviewUrl((prev) => [...prev, a]);
          //await axios.get(geturl);
        }
      } catch (error) {
        if (error.request) {
          console.log(error.request);
        } else {
          console.log(`Error: ${error.message}`);
        }
      }
    };

    useEffect(() => {
      if (headerObj.wld_pdf_image) {
        for (let item of headerObj.wld_pdf_image) {
          fetchImages(item);
        }
      }
    }, []);

    // const fileInputimg = useRef(null);
    const fileInputRef = useRef(null);

    const fetchbyuserprofile = async (userprofile) => {
      try {
        console.log("updateWeldingEngg_userprofile", userprofile);
        console.log("updateWeldingEngg_folderwld", folderwld);
        if (userprofile) {
          const result = await getPresignedURL(folderwld, userprofile);
          console.log("updateWeldingEngg_url", result.url);

          const geturl = result.url;
          setPreviewuser(geturl);
          console.log("Previewuser_geturl", geturl);
        }
      } catch (error) {
        if (error.request) {
          console.log(error.request);
        } else {
          console.log(`Error: ${error.message}`);
        }
      }
    };

    const fetchapproveUserProfile = async (userprofile) => {
      try {
        console.log("updateWitness_userprofile", userprofile);
        console.log("updateWitness_folderwld", folderwld);
        if (userprofile) {
          const result = await getPresignedURL(folderwld, userprofile);
          console.log("updateWitness_url", result.url);
          const geturl = result.url;
          setPreviewapproved(geturl);
          console.log("setPreviewapproved_geturl", geturl);
        }
      } catch (error) {
        if (error.request) {
          console.log(error.request);
        } else {
          console.log(`Error: ${error.message}`);
        }
      }
    };

    useEffect(() => {
      formattedDate = new Date().toLocaleDateString("en-GB");
      setToday(formattedDate);
      console.log("here", headerObj.WPSH_Prepared_By, headerObj.WPSH_Status);
      setReadonly(
        DisableFields(headerObj, Status, Action, action) ? "disabled" : ""
      );
      console.log("here", Readonly, "ddd");

      // headerObj.WPSH_PreparedBy_Name = username
    }, []);

    useEffect(() => {
      if (
        action === Action.ActionEdit &&
        headerObj?.wps_signaturePrepared !== ""
      ) {
        console.log("enterrrImggg");

        fetchbyuserprofile(headerObj?.wps_signaturePrepared);
      }

      if (
        action === Action.ActionEdit &&
        headerObj?.wps_signatureApproved !== ""
      ) {
        console.log("enterrrImggg");
        fetchapproveUserProfile(headerObj?.wps_signatureApproved);
      }
    }, []);

    const fetchImagestube1 = async (item) => {
      if (item) {
        console.log("insideUrl", item, headerObj?.wld_pdf_image[0]?.Image1[0]);
        const result = await getPresignedURL(folderwps, item);
        console.log(result);
        const posturl = result.url;
        setPreviewUrl1(posturl);
        console.log("img", posturl);
      }
    };

    const fetchImagestube2 = async (item) => {
      if (item) {
        console.log("insideUrl", item, headerObj?.wld_pdf_image[1]?.Image2[0]);
        const result = await getPresignedURL(folderwps, item);
        console.log(result);
        const posturl = result.url;
        setPreviewUrl2(posturl);
        console.log("img2", posturl);
      }
    };

    const fetchImagestube3 = async (item) => {
      if (item) {
        console.log("insideUrl", item, headerObj?.wld_pdf_image[2]?.Image3[0]);
        const result = await getPresignedURL(folderwps, item);
        console.log(result);
        const posturl = result.url;
        setPreviewUrl3(posturl);
        console.log("img3", posturl);
      }
    };

    useEffect(() => {
      if (
        action === Action.ActionEdit &&
        headerObj?.wld_pdf_image[0]?.Image1[0] !== ""
      ) {
        fetchImagestube1(headerObj?.wld_pdf_image[0]?.Image1[0]);
      }

      if (
        action === Action.ActionEdit &&
        headerObj?.wld_pdf_image[1]?.Image2[0] !== ""
      ) {
        fetchImagestube2(headerObj?.wld_pdf_image[1]?.Image2[0]);
      }
      if (
        action === Action.ActionEdit &&
        headerObj?.wld_pdf_image[2]?.Image3[0] !== ""
      ) {
        fetchImagestube3(headerObj?.wld_pdf_image[2]?.Image3[0]);
      }
    }, []);

    useEffect(() => {
      if (
        ImageView1 === true &&
        headerObj?.wld_pdf_image[0]?.Image1[0] !== ""
      ) {
        console.log("hiiipic1");
        fetchImagestube1(headerObj?.wld_pdf_image[0]?.Image1[0]);
      }
    }, [ImageView1]);

    useEffect(() => {
      if (
        ImageView2 === true &&
        headerObj?.wld_pdf_image[1]?.Image2[0] !== ""
      ) {
        console.log("hiiipic2");
        fetchImagestube2(headerObj?.wld_pdf_image[1]?.Image2[0]);
      }
    }, [ImageView2]);

    useEffect(() => {
      if (
        ImageView3 === true &&
        headerObj?.wld_pdf_image[2]?.Image3[0] !== ""
      ) {
        console.log("hiiipic3");
        fetchImagestube3(headerObj?.wld_pdf_image[2]?.Image3[0]);
      }
    }, [ImageView3]);

    var isoverlay = WPStype === "Groove" ? false : true;

    var groclass = WPStype === "Groove" ? "row-margin" : "d-none";
    var overclass = WPStype === "Overlay" ? "row-margin" : "d-none";

    const [isOverlay, setOverlay] = useState(isoverlay);

    const [grooveclass, setgrooveclass] = useState(groclass);

    const [overlayclass, setoverlayclass] = useState(overclass);

    //console.log("class", isOverlay, overlayclass);

    return (
      <Row className="font">
        <WPSHeader headerObj={headerObj}></WPSHeader>
        <Row style={{ padding: "20px 60px" }}>
          <Row className="mb-2" style={{ position: "relative", left: "15px" }}>
            <span
              className="WPS-blue-text"
              style={{ position: "relative", right: "11px" }}
            >
              Joints (QW-402)
            </span>
            <div
              className="d-flex mb-4"
              style={{ position: "relative", right: "11px" }}
            >
              <div>
                <input
                  type="radio"
                  name="wld_Joints_Type"
                  value="Joint section"
                  checked={headerObj.wld_Joints_Type === "Joint section"}
                  onChange={(e) => handleChange(e)}
                />
                <span className="ms-2">Joint section</span>
              </div>
              <div className="ms-5">
                <input
                  type="radio"
                  name="wld_Joints_Type"
                  value="Production drawing"
                  // checked={this.state.site === result.SITE_NAME}
                  // onChange={this.onSiteChanged}
                  checked={headerObj.wld_Joints_Type === "Production drawing"}
                  onChange={(e) => handleChange(e)}
                />
                <span className="ms-2">Production drawing</span>
              </div>
            </div>

            {headerObj.wld_Joints_Type === "Production drawing" ? (
              ""
            ) : (
              <>
                <WPS_Image_Groove
                  handleFile={handleFile1}
                  previewUrl1={previewUrl1}
                  previewUrl2={previewUrl2}
                  previewUrl3={previewUrl3}
                  headerObj={headerObj}
                  setheaderObj={setheaderObj}
                  getImgURL={getImgURL}
                  imgObj={imgObj}
                  name="fileInput"
                  name1="fileInput1"
                  name2="fileInput2"
                  name3="fileInput3"
                  type="file"
                  accept="image/*"
                  fileInputRef={fileInputRef}
                  readOnly={Readonly}
                  // disabled={isDisabled}
                ></WPS_Image_Groove>
                <Row className={`${grooveclass}` && "mb-1 mt-3"}>
                  <Col className={grooveclass} md={6}>
                    <Single_Input
                      name="WPSH_PHT_Root_spacing"
                      type="text"
                      maxLength={50}
                      status={error.WPSH_PHT_Root_spacing.status}
                      value={headerObj.WPSH_PHT_Root_spacing}
                      handleChange={handleChange}
                      label="Root Spacing"
                      message={error.WPSH_PHT_Root_spacing.message}
                      onInput={(e) => {
                        let error = isAlphanumericAllCharacters(e.target.value);
                        setError((prevState) => ({
                          ...prevState,
                          WPSH_PHT_Root_spacing: error,
                        }));
                      }}
                    ></Single_Input>
                  </Col>
                </Row>
              </>
            )}
          </Row>
        </Row>

        <Row style={{padding: "20px 60px" }} className="WPS-Page-row-padding">
          <Row className="mb-2" style={{ position: "relative", left: "15px" }}>
            <span
              className="WPS-blue-text"
              style={{ position: "relative", right: "11px" }}
            >
              Post Weld Heat Treatment (QW-407)
            </span>
            <Row className="mb-1">
              <Col className="mb-1" md={3}>
                <Single_Input
                  name="WPSH_PWHeatTre_Temperature"
                  type="text"
                  maxLength={28}
                  status={error.WPSH_PWHeatTre_Temperature.status}
                  value={headerObj.WPSH_PWHeatTre_Temperature}
                  handleChange={handleChange}
                  // label= "Post Weld Heat/Treatment Temperature (°C)"
                  label="Post Weld H/T Temp(°C)"
                  message={error.WPSH_PWHeatTre_Temperature.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPSH_PWHeatTre_Temperature: error,
                    }));
                  }}
                ></Single_Input>
              </Col>
              <Col className="mb-1" md={3}>
                <div class="T-input w-100">
                  <input
                    type="text"
                    id="WPSH_PWHeatTre_Time"
                    name="WPSH_PWHeatTre_Time"
                    maxLength={20}
                    placeholder=" "
                    className={
                      error.WPSH_PWHeatTre_Time.status
                        ? " T-input placeHolderInput inputText text-field-with-error"
                        : "T-input placeHolderInput inputText"
                    }
                    // value={ProjectObj.wld_ProjectNumber}
                    // onChange={handleChange}
                    value={headerObj.WPSH_PWHeatTre_Time}
                    onChange={handleChange}
                    onInput={(e) => {
                      let error = isAlphanumericAllCharacters(e.target.value);

                      setError((prevState) => ({
                        ...prevState,
                        WPSH_PWHeatTre_Time: error,
                      }));
                    }}
                  />
                  <label for="WPSH_PWHeatTre_Time">Time (Minutes)</label>
                </div>
                {headerObj.WPSH_PWHeatTre_Temperature !== "" ? (
                  <div className="required-text1">
                    {error.WPSH_PWHeatTre_Time.status && (
                      <Form.Text className="text-danger">
                        {error.WPSH_PWHeatTre_Time.message}
                      </Form.Text>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </Col>
              <Col className="mb-1" md={3}>
                <div class="T-input w-100">
                  <input
                    type="text"
                    id="WPSH_PWHeatTre_Heating_Rate"
                    name="WPSH_PWHeatTre_Heating_Rate"
                    maxLength={28}
                    placeholder=" "
                    className={
                      error.WPSH_PWHeatTre_Heating_Rate.status
                        ? " T-input placeHolderInput inputText text-field-with-error"
                        : "T-input placeHolderInput inputText"
                    }
                    // value={ProjectObj.wld_ProjectNumber}
                    // onChange={handleChange}
                    value={headerObj.WPSH_PWHeatTre_Heating_Rate}
                    onChange={handleChange}
                    onInput={(e) => {
                      let error = isAlphanumericAllCharacters(e.target.value);
                      setError((prevState) => ({
                        ...prevState,
                        WPSH_PWHeatTre_Heating_Rate: error,
                      }));
                    }}
                  />
                  <label for="WPSH_PWHeatTre_Heating_Rate">
                    Heating Rate (°C/Hr)
                  </label>
                </div>
                {headerObj.WPSH_PWHeatTre_Temperature !== "" ? (
                  <div className="required-text1">
                    {error.WPSH_PWHeatTre_Heating_Rate.status && (
                      <Form.Text className="text-danger">
                        {error.WPSH_PWHeatTre_Heating_Rate.message}
                      </Form.Text>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </Col>
              <Col className="mb-1" md={3}>
                <Single_Input
                  name="WPSH_PWHeatTre_Heating_Method"
                  type="text"
                  maxLength={20}
                  status={error.WPSH_PWHeatTre_Heating_Method.status}
                  value={headerObj.WPSH_PWHeatTre_Heating_Method}
                  handleChange={handleChange}
                  // label= "Post Weld Heat/Treatment Temperature (°C)"
                  label="Loading Temperature(°C)"
                  message={error.WPSH_PWHeatTre_Heating_Method.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPSH_PWHeatTre_Heating_Method: error,
                    }));
                  }}
                ></Single_Input>
              </Col>
            </Row>
            <Row className="mb-1">
              <Col className="mb-1" md={3}>
                <Single_Input
                  name="WPSH_PWHeatTre_Cooling_Rate"
                  type="text"
                  maxLength={28}
                  status={error.WPSH_PWHeatTre_Cooling_Rate.status}
                  value={headerObj.WPSH_PWHeatTre_Cooling_Rate}
                  handleChange={handleChange}
                  label=" Cooling Rate (°C/Hr)"
                  message={error.WPSH_PWHeatTre_Cooling_Rate.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPSH_PWHeatTre_Cooling_Rate: error,
                    }));
                  }}
                ></Single_Input>
              </Col>
              <Col md={3}>
                <Single_Input
                  name="WPSH_PWHeatTre_Cooling_Method"
                  type="text"
                  maxLength={20}
                  status={error.WPSH_PWHeatTre_Cooling_Method.status}
                  value={headerObj.WPSH_PWHeatTre_Cooling_Method}
                  handleChange={handleChange}
                  // label= "Post Weld Heat/Treatment Temperature (°C)"
                  label="UnLoading Temperature(°C)"
                  message={error.WPSH_PWHeatTre_Cooling_Method.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPSH_PWHeatTre_Cooling_Method: error,
                    }));
                  }}
                ></Single_Input>
              </Col>
              <Col md={3}>
                <General_Single_Input_select
                  name="WPSH_PWHeatTre_Type"
                  // readOnly={isReadonly}
                  value={headerObj.WPSH_PWHeatTre_Type}
                  // status={error.WPSH_PWHeatTre_Type.status}
                  // message={error.WPSH_PWHeatTre_Type.message}
                  handleSelectChange={handleSelectChange}
                  label="Type"
                  array={WPSH_PWHeatTre_Type}
                ></General_Single_Input_select>
              </Col>
            </Row>
            <Row className="mb-1">
              <Col md={12}>
                <Single_Input
                  name="WPSH_PWHeatTre_Notes"
                  type="text"
                  maxLength={90}
                  status={error.WPSH_PWHeatTre_Notes.status}
                  value={headerObj.WPSH_PWHeatTre_Notes}
                  handleChange={handleChange}
                  label="PWHT Notes"
                  message={error.WPSH_PWHeatTre_Notes.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllCharactersextra(
                      e.target.value
                    );
                    setError((prevState) => ({
                      ...prevState,
                      WPSH_PWHeatTre_Notes: error,
                    }));
                  }}
                ></Single_Input>
              </Col>
            </Row>
            <Row className="mb-1">
              <Col md={3}>
                <Single_Input
                  name="WPSH_PWHeatTre_Post_Heat_temp"
                  type="text"
                  maxLength={10}
                  status={error.WPSH_PWHeatTre_Post_Heat_temp.status}
                  value={headerObj.WPSH_PWHeatTre_Post_Heat_temp}
                  handleChange={handleChange}
                  label="Post Heating Temp (°C)"
                  message={error.WPSH_PWHeatTre_Post_Heat_temp.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPSH_PWHeatTre_Post_Heat_temp: error,
                    }));
                  }}
                ></Single_Input>
              </Col>
              <Col md={3}>
                <Single_Input
                  name="WPSH_PWHeatTre_Post_Heat_time"
                  type="text"
                  maxLength={4}
                  status={error.WPSH_PWHeatTre_Post_Heat_time.status}
                  value={headerObj.WPSH_PWHeatTre_Post_Heat_time}
                  handleChange={handleChange}
                  label="Post Heat Time (Minutes)"
                  message={error.WPSH_PWHeatTre_Post_Heat_time.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPSH_PWHeatTre_Post_Heat_time: error,
                    }));
                  }}
                ></Single_Input>
              </Col>
              <Col md={3}>
                <General_Single_Input_select
                  name="WPSH_PWHeatTre_Wrap_insulation_material"
                  // readOnly={isReadonly}
                  value={headerObj.WPSH_PWHeatTre_Wrap_insulation_material}
                  status={error.WPSH_PWHeatTre_Wrap_insulation_material.status}
                  message={
                    error.WPSH_PWHeatTre_Wrap_insulation_material.message
                  }
                  handleSelectChange={handleSelectChange}
                  label="Wrap In Insulation Material"
                  array={yesno}
                ></General_Single_Input_select>
              </Col>
            </Row>
            <Row className={grooveclass}>
              <Col md={3}>
                <Single_Input
                  name="WPSH_PWHeatTre_ISR_Temp"
                  type="text"
                  maxLength={15}
                  status={error.WPSH_PWHeatTre_ISR_Temp.status}
                  value={headerObj.WPSH_PWHeatTre_ISR_Temp}
                  handleChange={handleChange}
                  label=" ISR Temperature (°C)"
                  message={error.WPSH_PWHeatTre_ISR_Temp.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPSH_PWHeatTre_ISR_Temp: error,
                    }));
                  }}
                ></Single_Input>
              </Col>
              <Col md={3}>
                <Single_Input
                  name="WPSH_PWHeatTre_ISR_Time"
                  type="text"
                  maxLength={48}
                  status={error.WPSH_PWHeatTre_ISR_Time.status}
                  value={headerObj.WPSH_PWHeatTre_ISR_Time}
                  handleChange={handleChange}
                  label=" ISR Time (Minutes) "
                  message={error.WPSH_PWHeatTre_ISR_Time.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPSH_PWHeatTre_ISR_Time: error,
                    }));
                  }}
                ></Single_Input>
              </Col>
              <Col md={12}>
                <Single_Input
                  name="WPSH_PWHeatTre_Weld_Notes"
                  type="text"
                  maxLength={90}
                  status={error.WPSH_PWHeatTre_Weld_Notes.status}
                  value={headerObj.WPSH_PWHeatTre_Weld_Notes}
                  handleChange={handleChange}
                  label="ISR Notes"
                  message={error.WPSH_PWHeatTre_Weld_Notes.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllCharactersextra(
                      e.target.value
                    );
                    setError((prevState) => ({
                      ...prevState,
                      WPSH_PWHeatTre_Weld_Notes: error,
                    }));
                  }}
                ></Single_Input>
              </Col>
            </Row>
          </Row>
        </Row>

        {/*----------------------------- Field For Groove -------------------------------------------------------------------------------------*/}

        <Row className={grooveclass} style={{ padding: "20px 60px" }}>
          <Row className="mb-2" style={{ position: "relative", left: "15px" }}>
            <span
              className="WPS-blue-text"
              style={{ position: "relative", right: "11px" }}
            >
              Other Variables
            </span>
            <Row className="mb-1">
              {/* <Col md={6}>
                <Single_Input
                  name="WPSH_Tech_Weld_deposit_chemistry"
                  type="text"
                  maxLength={60}
                  status={error.WPSH_Tech_Weld_deposit_chemistry.status}
                  value={headerObj.WPSH_Tech_Weld_deposit_chemistry}
                  handleChange={handleChange}
                  label="Weld Deposit Chemistry"
                  message={error.WPSH_Tech_Weld_deposit_chemistry.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPSH_Tech_Weld_deposit_chemistry: error,
                    }));
                  }}
                ></Single_Input>
              </Col> */}
              <Col md={6}>
                <General_Single_Input_select
                  name="WPSH_Tech_BackGougingMethod"
                  // readOnly={isReadonly}
                  value={headerObj.WPSH_Tech_BackGougingMethod}
                  status={error.WPSH_Tech_BackGougingMethod.status}
                  message={error.WPSH_Tech_BackGougingMethod.message}
                  handleSelectChange={handleSelectChange}
                  label="Back Gouging Method"
                  array={WPSH_Tech_BackGougingMethod}
                ></General_Single_Input_select>
              </Col>
            </Row>
            <Row className="mb-1">
              <Col md={6}>
                <Single_Input
                  name="WPSH_Tech_GroovePreparation"
                  type="text"
                  maxLength={80}
                  status={error.WPSH_Tech_GroovePreparation.status}
                  value={headerObj.WPSH_Tech_GroovePreparation}
                  handleChange={handleChange}
                  label="Groove Preparation"
                  message={error.WPSH_Tech_GroovePreparation.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPSH_Tech_GroovePreparation: error,
                    }));
                  }}
                ></Single_Input>
              </Col>
              <Col md={6}>
                <General_Single_Input_select
                  name="WPSH_Tech_Inital_InterpassCleaning"
                  // readOnly={isReadonly}
                  value={headerObj.WPSH_Tech_Inital_InterpassCleaning}
                  status={error.WPSH_Tech_Inital_InterpassCleaning.status}
                  message={error.WPSH_Tech_Inital_InterpassCleaning.message}
                  handleSelectChange={handleSelectChange}
                  label="Initial/ Interpass Cleaning"
                  array={wire_brush}
                ></General_Single_Input_select>
              </Col>
            </Row>
            <Row className="mb-1">
              <Col md={6}>
                <General_Single_Input_select
                  name="WPSH_Tech_Peening"
                  // readOnly={isReadonly}
                  value={headerObj.WPSH_Tech_Peening}
                  status={error.WPSH_Tech_Peening.status}
                  message={error.WPSH_Tech_Peening.message}
                  handleSelectChange={handleSelectChange}
                  label="Peening"
                  array={yesno}
                ></General_Single_Input_select>
              </Col>

              <Col md={6}>
                <General_Single_Input_select
                  name="WPSH_Tech_SupplementaryMFControl"
                  // readOnly={isReadonly}
                  value={headerObj.WPSH_Tech_SupplementaryMFControl}
                  status={error.WPSH_Tech_SupplementaryMFControl.status}
                  message={error.WPSH_Tech_SupplementaryMFControl.message}
                  handleSelectChange={handleSelectChange}
                  label="Supplementary MF Control"
                  array={yesno}
                ></General_Single_Input_select>
              </Col>
            </Row>
            <Row className="mb-1">
              <Col md={6}>
                <General_Single_Input_select
                  name="WPSH_Tech_SurfacePreparation"
                  // readOnly={isReadonly}
                  value={headerObj.WPSH_Tech_SurfacePreparation}
                  status={error.WPSH_Tech_SurfacePreparation.status}
                  message={error.WPSH_Tech_SurfacePreparation.message}
                  handleSelectChange={handleSelectChange}
                  label="Surface Preparation"
                  array={wire_brush}
                ></General_Single_Input_select>
              </Col>
              <Col md={6}>
                <General_Single_Input_select
                  name="WPSH_Tech_UseOfThermalProcess"
                  // readOnly={isReadonly}
                  value={headerObj.WPSH_Tech_UseOfThermalProcess}
                  status={error.WPSH_Tech_UseOfThermalProcess.status}
                  message={error.WPSH_Tech_UseOfThermalProcess.message}
                  handleSelectChange={handleSelectChange}
                  label="Use of Thermal Process"
                  array={yesno}
                ></General_Single_Input_select>
              </Col>
            </Row>
            <Row className="mb-1">
              <Col md={6}>
                <General_Single_Input_select
                  name="WPSH_Tech_Oscillation"
                  // readOnly={isReadonly}
                  value={headerObj.WPSH_Tech_Oscillation}
                  status={error.WPSH_Tech_Oscillation.status}
                  message={error.WPSH_Tech_Oscillation.message}
                  handleSelectChange={handleSelectChange}
                  label="Oscillation"
                  array={yesno}
                ></General_Single_Input_select>
              </Col>
              <Col md={6}>
                <Single_Input
                  name="WPSH_Tech_Change_Electrode_dia"
                  type="text"
                  maxLength={25}
                  status={error.WPSH_Tech_Change_Electrode_dia.status}
                  value={headerObj.WPSH_Tech_Change_Electrode_dia}
                  handleChange={handleChange}
                  label="Change in Electrode Diameter"
                  message={error.WPSH_Tech_Change_Electrode_dia.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPSH_Tech_Change_Electrode_dia: error,
                    }));
                  }}
                ></Single_Input>
              </Col>
            </Row>
            <Row className="mb-1">
              <Col md={6}>
                <General_Single_Input_select
                  name="WPSH_Tech_ClosedtooutChamber"
                  // readOnly={isReadonly}
                  value={headerObj.WPSH_Tech_ClosedtooutChamber}
                  status={error.WPSH_Tech_ClosedtooutChamber.status}
                  message={error.WPSH_Tech_ClosedtooutChamber.message}
                  handleSelectChange={handleSelectChange}
                  label="Closed to out Chamber"
                  array={yesno}
                ></General_Single_Input_select>
              </Col>
            </Row>
          </Row>
        </Row>

        {/*----------------------------- Field For Overlay -------------------------------------------------------------------------------------*/}

        <Row className={overlayclass} style={{ padding: "20px 60px" }}>
          <Row className="mb-2" style={{ position: "relative", left: "15px" }}>
            <span className="WPS-blue-text">Other Variables</span>
            <Row className="mb-1">
              <Col md={6}>
                <Single_Input
                  name="WPSH_Tech_Weld_deposit_chemistry"
                  type="text"
                  maxLength={30}
                  status={error.WPSH_Tech_Weld_deposit_chemistry.status}
                  value={headerObj.WPSH_Tech_Weld_deposit_chemistry}
                  handleChange={handleChange}
                  label="Weld Deposit Chemistry"
                  message={error.WPSH_Tech_Weld_deposit_chemistry.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPSH_Tech_Weld_deposit_chemistry: error,
                    }));
                  }}
                ></Single_Input>
              </Col>
              <Col md={6}>
                <General_Single_Input_select
                  name="WPSH_Tech_Inital_InterpassCleaning"
                  // readOnly={isReadonly}
                  value={headerObj.WPSH_Tech_Inital_InterpassCleaning}
                  status={error.WPSH_Tech_Inital_InterpassCleaning.status}
                  message={error.WPSH_Tech_Inital_InterpassCleaning.message}
                  handleSelectChange={handleSelectChange}
                  label="Initial/ Interpass Cleaning"
                  array={wire_brush}
                ></General_Single_Input_select>
              </Col>
            </Row>
            <Row className="mb-1">
              <Col md={6}>
                <General_Single_Input_select
                  name="WPSH_Tech_SupplementaryMFControl"
                  // readOnly={isReadonly}
                  value={headerObj.WPSH_Tech_SupplementaryMFControl}
                  status={error.WPSH_Tech_SupplementaryMFControl.status}
                  message={error.WPSH_Tech_SupplementaryMFControl.message}
                  handleSelectChange={handleSelectChange}
                  label="Supplementary MF Control"
                  array={yesno}
                ></General_Single_Input_select>
              </Col>
              <Col md={6}>
                <General_Single_Input_select
                  name="WPSH_Tech_SurfacePreparation"
                  // readOnly={isReadonly}
                  value={headerObj.WPSH_Tech_SurfacePreparation}
                  status={error.WPSH_Tech_SurfacePreparation.status}
                  message={error.WPSH_Tech_SurfacePreparation.message}
                  handleSelectChange={handleSelectChange}
                  label="Surface Preparation"
                  array={wire_brush}
                ></General_Single_Input_select>
              </Col>
            </Row>
            <Row className="mb-1">
              <Col md={6}>
                <General_Single_Input_select
                  name="WPSH_Tech_Oscillation"
                  // readOnly={isReadonly}
                  value={headerObj.WPSH_Tech_Oscillation}
                  status={error.WPSH_Tech_Oscillation.status}
                  message={error.WPSH_Tech_Oscillation.message}
                  handleSelectChange={handleSelectChange}
                  label="Oscillation"
                  array={yesno}
                ></General_Single_Input_select>
              </Col>
              <Col md={6}>
                <General_Single_Input_select
                  name="WPSH_Tech_Peening"
                  // readOnly={isReadonly}
                  value={headerObj.WPSH_Tech_Peening}
                  status={error.WPSH_Tech_Peening.status}
                  message={error.WPSH_Tech_Peening.message}
                  handleSelectChange={handleSelectChange}
                  label="Peening"
                  array={yesno}
                ></General_Single_Input_select>
              </Col>
            </Row>
            <Row className="mb-1">
              <Col className="mb-1" md={3}>
                <Single_Input
                  name="WPSH_Tech_Oscillation_Width"
                  type="text"
                  maxLength={20}
                  status={error.WPSH_Tech_Oscillation_Width.status}
                  value={headerObj.WPSH_Tech_Oscillation_Width}
                  handleChange={handleChange}
                  label="Oscillation Width(mm)"
                  message={error.WPSH_Tech_Oscillation_Width.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPSH_Tech_Oscillation_Width: error,
                    }));
                  }}
                ></Single_Input>
              </Col>
              <Col className="mb-1" md={3}>
                <Single_Input
                  typeclass={overlayclass}
                  name="WPSH_Tech_Oscillation_Freq"
                  type="text"
                  maxLength={20}
                  status={error.WPSH_Tech_Oscillation_Freq.status}
                  value={headerObj.WPSH_Tech_Oscillation_Freq}
                  handleChange={handleChange}
                  label="Oscillation Frequency in CPM"
                  message={error.WPSH_Tech_Oscillation_Freq.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPSH_Tech_Oscillation_Freq: error,
                    }));
                  }}
                ></Single_Input>
              </Col>

              <Col className="mb-1" md={3}>
                <Single_Input
                  name="WPSH_Tech_Filler_Metal_Del"
                  type="text"
                  maxLength={20}
                  status={error.WPSH_Tech_Filler_Metal_Del.status}
                  value={headerObj.WPSH_Tech_Filler_Metal_Del}
                  handleChange={handleChange}
                  label="Filler Metal Delivery(GTAW)"
                  message={error.WPSH_Tech_Filler_Metal_Del.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPSH_Tech_Filler_Metal_Del: error,
                    }));
                  }}
                ></Single_Input>
              </Col>
            </Row>
          </Row>
        </Row>

        <Row style={{padding: "20px 60px" }} className="WPS-Page-row-padding">
          <Row className="mb-2" style={{ position: "relative", left: "5px" }}>
            <span className="WPS-blue-text">
              Notes (Only first 6 lines in below Notes will appear in WI Slip)
            </span>
            <Col md={12}>
              <Single_Input_textaraNotes
                name="WPSH_Notes"
                type="text"
                style={{ height: "250px", resize: "none" }}
                maxLength={1500}
                className="textarea-notes"
                status={error.WPSH_Notes.status}
                // value={headerObj.WPSH_Notes}
                value={headerObj.WPSH_Notes ? headerObj.WPSH_Notes : valueNotes}
                handleChange={handleChangeNotes}
                // onKeyDown={handleKeyDown}
                label="Notes"
                message={error.WPSH_Notes.message}
                // onInput={(e) => {
                //   let error = isAlphanumericAllCharactersextra(e.target.value);
                //   setError((prevState) => ({
                //     ...prevState,
                //     WPSH_Notes: error,
                //   }));
                // }}
              ></Single_Input_textaraNotes>
            </Col>
          </Row>
        </Row>
        {/* -------------------------prepared by --------------------------------*/}

        <Row style={{ padding: "20px 60px" }}>
          <Row className="mb-2" style={{ position: "relative", left: "20px" }}>
            <Row className="mb-4">
              <Col>
                <span className="WPS-blue-text">
                  Prepared By - Welding Engineer
                </span>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Form.Group className="preby-input-select">
                  <div className="search-select">
                    <SearchSelectprename
                      required
                      data={users}
                      valueField={"um_name"}
                      // disabled={Band === "P" || Band === "T"}
                      value={headerObj.WPSH_PreparedBy_Name || ""}
                      labelField={"um_name"}
                      placeholder=""
                      onChange={(prename) => {
                        if (action === Action.ActionEdit) setIsEdited(true);

                        setheaderObj((prev) => ({
                          ...prev,
                          WPSH_PreparedBy_Name: prename.value,
                        }));

                        let a = users.filter((b) => {
                          return b.um_name === prename.value;
                        });
                        console.log("aaaarrr", a);
                        fetchbyuserprofile(a[0].wld_profile);
                        setheaderObj((prev) => ({
                          ...prev,
                          WPSH_PreparedBy_Name: a[0].um_name,
                          wps_signaturePrepared: a[0].wld_profile,
                          WPSH_PreparedBy: a[0].SK,
                        }));
                      }}
                    ></SearchSelectprename>
                  </div>
                  <label>Prepared By</label>
                  <div className="required-text-select">
                    {error.WPSH_PreparedBy_Name.status && (
                      <Form.Text className="text-danger">
                        {error.WPSH_PreparedBy_Name.message}
                      </Form.Text>
                    )}
                  </div>
                </Form.Group>
              </Col>

              <Col md={3} style={{ position: "relative", right: "80px" }}>
                <Date_InputUser
                  divClass="T-input w-100"
                  label="Date"
                  className="groove_datecolor"
                  name="WPSH_PreparedBy_Date"
                  sx={{ fontSize: "6px", fontFamily: "Work Sans", zIndex: "0" }}
                  // todayDate={todayDate}
                  // maxDate={maxDate}
                  obj={headerObj}
                  setobj={setheaderObj}
                  setError={setError}
                  status={error.WPSH_PreparedBy_Date.status}
                  message={error.WPSH_PreparedBy_Date.message}
                />
              </Col>

              <Row
                className="mb-4"
                style={{ position: "relative", left: "10px" }}
              >
                <Col>
                  <span>Signature Image</span>
                </Col>
              </Row>

              <Row
                style={{ position: "relative", left: "10px", bottom: "10px" }}
              >
                <Col md={6}>
                  <div className="signature-box">
                    <div className="imgbox">
                      <img className="imgsizebig1" src={previewuser} />
                    </div>
                  </div>
                </Col>
                <Col style={{ position: "relative", left: "28px" }}>
                  <Single_Input
                    name="WPSH_PreparedBy_Remark"
                    type="text"
                    style={{ height: "95px" }}
                    maxLength={50}
                    status={error.WPSH_PreparedBy_Remark.status}
                    value={headerObj.WPSH_PreparedBy_Remark}
                    handleChange={handleChange}
                    label="Remark"
                    message={error.WPSH_PreparedBy_Remark.message}
                    onInput={(e) => {
                      let error = isAlphanumericAllCharacters(e.target.value);
                      setError((prevState) => ({
                        ...prevState,
                        WPSH_PreparedBy_Remark: error,
                      }));
                    }}
                  ></Single_Input>
                </Col>
              </Row>
            </Row>
          </Row>
        </Row>
        {/* -------------------------approved by --------------------------------*/}
        <Row
          style={{ padding: "0px 60px", position: "relative", bottom: "10px" }}
        >
          <Row
            className="mb-2"
            style={{ position: "relative", left: "20px", bottom: "10px" }}
          >
            <Row className="mb-4">
              <Col>
                <span className="WPS-blue-text">
                  Approved By - Welding Engineer
                </span>
              </Col>
              {Band === "P" || Band === "T" ? (
                <Col
                  style={{ position: "relative", top: "36px", left: "22px" }}
                >
                  <span className="WPS-blue-textf">Action</span>
                </Col>
              ) : (
                ""
              )}
            </Row>
            <Row>
              <Col md={4}>
                <Form.Group className="preby-input-select">
                  <div className="search-select">
                    <SearchSelectprename
                      required
                      data={users}
                      valueField={"um_name"}
                      // readOnly={Band !== "P" || Band !== "T"}
                      // disabled={(Band !=="P" || Band !== "T") ? true : false }
                      value={headerObj.WPSH_ApprovedBy_Name}
                      labelField={"um_name"}
                      placeholder=""
                      onChange={(prename) => {
                        if (action === Action.ActionEdit) setIsEdited(true);

                        setheaderObj((prev) => ({
                          ...prev,
                          WPSH_ApprovedBy_Name: prename.value,
                        }));

                        console.log("ItemList on", prename);
                        let a = users.filter((b) => {
                          return b.um_name === prename.value;
                        });
                        console.log("aaaarrr", a);
                        setheaderObj((prev) => ({
                          ...prev,
                          WPSH_ApprovedBy_Name: a[0].um_name,
                          wps_signatureApproved: a[0].wld_profile,
                          WPSH_ApprovedBy: a[0].SK,
                        }));

                        fetchapproveUserProfile(a[0].wld_profile);
                      }}
                    ></SearchSelectprename>
                  </div>
                  <label>Approved By</label>
                  <div className="required-text-select">
                    {error.WPSH_ApprovedBy_Name.status && (
                      <Form.Text className="text-danger">
                        {error.WPSH_ApprovedBy_Name.message}
                      </Form.Text>
                    )}
                  </div>
                </Form.Group>
              </Col>

              <Col md={3} style={{ position: "relative", right: "80px" }}>
                <Date_InputUser
                  divClass="T-input w-100"
                  label="Date"
                  className="groove_datecolor"
                  name="WPSH_ApprovedBy_Date"
                  sx={{ fontSize: "6px", fontFamily: "Work Sans", zIndex: "0" }}
                  todayDate={todayDate}
                  // maxDate={maxDate}
                  obj={headerObj}
                  setobj={setheaderObj}
                  setError={setError}
                  status={error.WPSH_ApprovedBy_Date.status}
                  message={error.WPSH_ApprovedBy_Date.message}
                  // datevalue={datevalue}
                />
              </Col>
              {Band === "P" || Band === "T" ? (
                <Col
                  style={{ position: "relative", top: "25px", right: "68px" }}
                >
                  <div className="d-flex mb-4">
                    <div>
                      <input
                        type="radio"
                        name="WPSH_Approval_Status"
                        value="Approved"
                        // checked={this.state.site === result.SITE_NAME}
                        // onChange={this.onSiteChanged}
                        checked={
                          headerObj.WPSH_Approval_Status ===
                          Status.StatusApproved
                        }
                        onChange={(e) => handleChangeRadio(e)}
                      />
                      <span className="ms-2">Approve</span>
                    </div>
                    <div className="ms-5">
                      <input
                        type="radio"
                        name="WPSH_Approval_Status"
                        value="Rejected"
                        // checked={this.state.site === result.SITE_NAME}
                        // onChange={this.onSiteChanged}
                        checked={
                          headerObj.WPSH_Approval_Status ===
                          Status.StatusRejected
                        }
                        onChange={(e) => handleChangeRadio(e)}
                      />
                      <span className="ms-2">Reject</span>
                    </div>
                  </div>
                </Col>
              ) : (
                ""
              )}

              <Row
                className="mb-4"
                style={{ position: "relative", left: "10px" }}
              >
                <Col>
                  <span>Signature Image</span>
                </Col>
              </Row>

              <Row
                style={{ position: "relative", left: "10px", bottom: "10px" }}
              >
                <Col md={6}>
                  <div className="signature-box">
                    <div className="imgbox">
                      <img className="imgsizebig1" src={previewapproved} />
                    </div>
                  </div>
                </Col>
                {Band === "P" || Band === "T" ? (
                  <Col style={{ position: "relative", left: "28px" }}>
                    <div class="T-input w-100">
                      <textarea
                        style={{ height: "92px" }}
                        type="textarea"
                        id="WPSH_ApprovedBy_Remark"
                        name="WPSH_ApprovedBy_Remark"
                        maxLength={200}
                        //
                        placeholder=" "
                        className={
                          error.WPSH_ApprovedBy_Remark.status
                            ? " T-input placeHolderInput inputText text-field-with-error"
                            : "T-input placeHolderInput inputText"
                        }
                        // value={ProjectObj.wld_ProjectNumber}
                        // onChange={handleChange}
                        value={headerObj.WPSH_ApprovedBy_Remark}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error = isAlphanumericAllCharacters(
                            e.target.value
                          );
                          setError((prevState) => ({
                            ...prevState,
                            WPSH_ApprovedBy_Remark: error,
                          }));
                        }}
                      />
                      <label for="WPSH_ApprovedBy_Remark">Remark</label>
                    </div>
                    {headerObj.WPSH_Approval_Status ===
                    Status.StatusApproved ? (
                      <div className="required-text1">
                        {error.WPSH_ApprovedBy_Remark.status && (
                          <Form.Text className="text-danger">
                            {error.WPSH_ApprovedBy_Remark.message}
                          </Form.Text>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                ) : (
                  <Col>
                    <div class="T-input w-100">
                      <textarea
                        style={{ height: "95px" }}
                        type="textarea"
                        id="WPSH_ApprovedBy_Remark"
                        name="WPSH_ApprovedBy_Remark"
                        readOnly
                        maxLength={200}
                        //
                        placeholder=" "
                        className={
                          error.WPSH_ApprovedBy_Remark.status
                            ? " T-input placeHolderInput inputText text-field-with-error"
                            : "T-input placeHolderInput inputText"
                        }
                        // value={ProjectObj.wld_ProjectNumber}
                        // onChange={handleChange}
                        value={headerObj.WPSH_ApprovedBy_Remark}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error = isAlphanumericAllCharacters(
                            e.target.value
                          );
                          setError((prevState) => ({
                            ...prevState,
                            WPSH_ApprovedBy_Remark: error,
                          }));
                        }}
                      />
                      <label for="WPSH_ApprovedBy_Remark">Remark</label>
                    </div>
                    {headerObj.WPSH_Approval_Status ===
                    Status.StatusApproved ? (
                      <div className="required-text1">
                        {error.WPSH_ApprovedBy_Remark.status && (
                          <Form.Text className="text-danger">
                            {error.WPSH_ApprovedBy_Remark.message}
                          </Form.Text>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                )}
              </Row>
            </Row>
          </Row>
        </Row>
      </Row>
    );
  }
);

export default WeldingProcedureSpec;
