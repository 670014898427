// ---------------------------------------------------------------------------------------------------------------//

//Date - 28/3/2024.
//Author - Sagar Gawade.
//Session - PQR Transaction(Groove, Overlay) - Page_1.
//Contains - ADD / EDIT / GET API'd of PQR Groove and Overlay Session.
//Workflow - 1) There is no approval process,user can select Welding Engg Name and Witness By Clicks on save button to save the data.
//         - 2) Initiator Can Edit Record in both Freezed and Temp Saved Status.
//         - 3) In 1st Page API is called in such a way that 1st it will add some record create structure at backend
//              for database schema,then it updates the further data in batches(AddUpdatePQRHeader,AddNewPQR,UpdatePQRHeader,AddNewDetail)
//         - 4) Record can be Tempsaved (Project Number , Atleast one Process Mandtory).
// Important Status :
//   StatusApproved: "Approved",
//   StatusRejected: "Rejected",
//   StatusTempSaved: "Temporary Saved",
//   StatusFreezed: "Freezed" -pdf can be downloaded only if data is freezed,
// ---------------------------------------------------------------------------------------------------------------//
import { Row, Col, Form, Table } from "react-bootstrap";
import {
  useImperativeHandle,
  forwardRef,
  useState,
  useEffect,
  useRef,
} from "react";
import {
  isAlphanumericAllSpecialCharacters,
  handleOnInput,
} from "../../functions/validations";
import {
  company_name,
  division,
  MessageTypes,
  Plant_Location,
  Company_Name,
  PQR_GroovePage1,
  PQR_Detail_GroovePage1,
  PQR_OverlayPage1,
  PQR_GrooveCheckbox,
  PQR_OverlayCheckbox,
  PQR_Detail_OverlayPage1,
  PQR_field_Val,
  PQR_field_ValOverlay,
  FM_Product_Form
} from "./PQRObjects/PQR_entity";
import { getbyprojectno } from "../../Services/pqr-header-service";
import { AddNewDetail } from "../../Services/pqr-details-service";
import { useAsyncValue, useLocation } from "react-router-dom";
import { getPNOActive } from "../../Services/pcategory-service";
import { getActiveProcess } from "../../Services/weldingprocess-services";
import {
  checkduplicateProcess,
  checkPQRProcessAdded,
  AddUpdatePQRHeader,
  UpdatePQRHeaderComplete,
} from "./PQRObjects/PQR_Validations";
import { emptyError } from "./PQRObjects/PQR_common";
import { Action, Status } from "./PQRObjects/PQR_entity";
import {
  General_Single_Input_select,
  Single_Input,
  WPS_Process_Grid_Input_2,
  WPS_Process_Grid_Input_3,
  PQR_FillerMetal_Input,
  PQR_FillerMetal_Select,
  PQR_Process_Grid_Select,
} from "./PQRObjects/PQR_Process_Grid";

import {
  getActiveWelder,
  getPresignedURL,
} from "../../Services/Welder-services";
import { PQRProcess } from "../utility/CommonComponents/PQRProcess";
import { Date_Input, Date_InputUser } from "../utility/CommonComponents/Date_Component";

import { BaseMetalTable_PQR } from "../utility/CommonComponents/BaseMetalTable_PQR";

import axios from "axios";
import { getActiveSfa } from "../../Services/sfa-service";
import { ImageArray2 } from "../utility/CommonComponents/CommonImages";
import { getActiveFno } from "../../Services/fno-services";
import { getActiveAno } from "../../Services/ano-services";
import {DeletePQR} from "../../Services/pqr-details-service"

const PQR_Page1 = forwardRef(
  (
    {
      headerObj,
      setheaderObj,
      detailObj,
      setdetailObj,
      errorstyle,
      blyestyle,
      WPStype,
      isDisabled,
      isReadonly,
      toast,
      prevdetailobj,
      setprevdetailObj
    },
    ref
  ) => {
    let folderPQR = "PQR_GrooveOverlay";


    const location = useLocation();

    const [ProcessActive, setProcessActive] = useState([]);
    const [PNoActive, setPNoActive] = useState([]);
    const [SfaActive, setSfaActive] = useState([]);
    const [AnoActive, setAnoActive] = useState([]);
    const [FnoActive, setFnoActive] = useState([]);
    const[count, setcount] = useState(1)
    const [IsEdited, setIsEdited] = useState(false);

    const [action, setaction] = useState(location.state?.action);

    const [isoverlay, setisoverlay]= useState(WPStype === "Groove" ? false : true);

    const [profile, setProfile] = useState("");
    const [profile1, setProfile1] = useState("");

    const [previewUrl, setPreviewUrl] = useState([]);

    const [previewUrl1, setPreviewUrl1] = useState();
    const [previewUrl2, setPreviewUrl2] = useState();

    const [imgObj, setimgObj] = useState([]);
    const [imgDataObj, setimgDataObj] = useState(
      headerObj?.wld_pdf_image ? headerObj?.wld_pdf_image : []
    );
    const [isChecked, setIsChecked] = useState(true);
    const [fieldPWHT, setFieldPWHT] = useState(false);

    
    const [ImageView1, setImageView1] = useState(false);
    const [ImageView2, setImageView2] = useState(false);

  
    const [error, setError] = useState({
      PQR_RecordNo: {},
      PQR_ReviNo: {},
      PQR_WPS_RecordNo: {},
      PQR_WPS_ReviNo: {},
      PQR_Date: {},
      PQR_CompanyName: {},

      PQR_PWHeatTre_Temperature: {},
      PQR_PWHeatTre_Time: {},
      PQR_PWHeatTre_Type: {},
      PQR_PWHeatTre_Heating_Rate: {},
      PQR_PWHeatTre_Heating_Method: {},

      PQR_PWHeatTre_Cooling_Rate: {},
      PQR_PWHeatTre_Cooling_Method: {},

      PQR_PWHeatTre_Notes: {},
      PQR_PWHeatTre_Maintenance: {},

      PQR_Wps_RecordNo: {},
      PQR_ReferenceCode: {},
      PQR_Inspection: {},
      PQR_DOB: {},
      PQR_Qualificationcode: {},
      PQR_Last_UpdatedDate: {},
      PQR_Last_UpdatedBy: {},
      PQR_Welded_on_coupon: {},
      PQR_Division: {},
      PQR_PlantLocation: {},
      PQR_WeldingStandard: {},
      PQR_specialNote: {},
      PQR_Status: {},

      PQR_BM_BM_ProductForm: {},
      PQR_BM_BM_Spec: {},
      PQR_BM_BM_Pno: {},
      PQR_BM_BM_Gno: {},
      PQR_BM_BM_Size: {},
      PQR_BM_BM_Sch: {},
      PQR_BM_BM_Thk: {},
      PQR_BM_BM_Dia: {},
      // PQR_BM_BM_AndTested: {},
      PQR_BM_BM_Condition1: {},
      PQR_BM_BM_Condition2: {},
      PQR_BM_BM_Notes: {},

      PQR_BM_WTBM_ProductForm: {},
      PQR_BM_WTBM_Spec: {},
      PQR_BM_WTBM_Pno: {},
      PQR_BM_WTBM_Gno: {},
      PQR_BM_WTBM_Size: {},
      PQR_BM_WTBM_Sch: {},
      PQR_BM_WTBM_Thk: {},
      PQR_BM_WTBM_Dia: {},

      PQR_JD_JointDesign: {},
      PQR_JD_Backing: {},
      PQR_JD_Retainers: {},
      PQR_JD_GrooveAngle: {},
      PQR_JD_RootOpening: {},
      PQR_JD_RootFace: {},
      PQR_JD_Notes: {},

      PQR_JD_TubePitch: {},
      PQR_JD_TubeProj: {},
      PQR_JD_Pattern: {},

      PQRD_FM_SFA: {},
      PQRD_FM_Classification: {},
      PQRD_FM_FNo: {},
      PQRD_FM_Ano: {},
      PQRD_FM_Nominal: {},
      PQRD_FM_TradeName: {},

      PQRD_FM_ProductForm: {},
      PQRD_FM_FillerMetalSize: {},
      PQRD_FM_DepositedThickness: {},
      PQRD_FM_MaxPassThickness: {},
      PQRD_FM_WeldDepositChemistry: {},
      PQRD_FM_FluxAWS: {},
      PQRD_FM_FluxNominalComposition: {},
      PQRD_FM_FluxTradeName: {},
      PQRD_FM_FluxType: {},
      PQRD_FM_FluxRecrushed: {},
      PQRD_FM_Supplement: {},
      PQRD_FM_FillerMetalOthers: {},

      PQRD_Position_PositionofGroove: {},
      PQRD_Position_WeldProgression: {},

      PQRD_Preheat_MinimumPreheatTemp: {},
      PQRD_Preheat_MaximumInterpassTemp: {},

      PQRD_Gas_ShieldingType: {},
      PQRD_Gas_ShieldingFlowRate: {},
      PQRD_Gas_BackingType: {},
      PQRD_Gas_BackingFlowRate: {},
      PQRD_Gas_TrailingType: {},
      PQRD_Gas_TrailingFlowRate: {},

      PQRD_Electrical_FillerMetalSize: {},
      PQRD_Electrical_Amperes: {},
      PQRD_Electrical_Volts: {},
      PQRD_Electrical_TravelSpeed: {},
      PQRD_Electrical_minBeadLength: {},
      PQRD_Electrical_ArcTransferMode: {},
      PQRD_Electrical_MaxHeatInput: {},
      PQRD_Electrical_TungstenType: {},
      PQRD_Electrical_CurrentPolarity: {},
      PQRD_Electrical_DCPulsatingCurrent: {},
      PQRD_Electrical_WireFeedType: {},
      PQRD_Electrical_WireFeedSpeed: {},
      PQRD_Technique_StringOrWeave: {},
      PQRD_Technique_Orifice_GasCupSize: {},
      PQRD_Technique_CTWD: {},
      PQRD_Technique_Oscillation: {},
      PQRD_Technique_Multi_SingleElectrode: {},
      PQRD_Technique_ElectrodeAngle: {},
      PQRD_Technique_SinglePassPerSide: {},
      PQRD_Technique_Multiple_SingleLayer: {},
      PQRD_Technique_Peening: {},
      PQRD_Technique_InitialInterpassCleaning: {},
      PQRD_Technique_BackGaugingMethod: {},
      PQRD_Technique_Technique_Other: {},
      PQRD_Technique_GroovePreparation: {},
      //only for overlay below
      PQRD_Technique_ElectrodesSamePuddle: {},
      PQRD_Technique_SupplimentalDevice: {},
      PQRD_Technique_Overlaping: {},
      PQR_WP_Process_Process: {},
      PQR_WP_Process_Type: {},
      PQR_WP_Type_Process: {},
      PQR_WP_Type_Type: {},
      WPSH_Project_No: {},
      WPSH_WPS_No: {},
      WPSH_WPS_Revi_No: {},
      WPS_QualifiedTo: {},
      WPSH_SupportingPQR: {},
      WPSH_WPS_Date: {},
      WPSH_RefDoc: {},
      WPSH_Scope: {},
      WPSH_JointDesc: {},
      WPSH_BM_Type_Type: {},
      WPSH_BM_WeldingTo_Type: {},
      WPSH_BM_Type_GNo: {},
      WPSH_BM_Type_PNo: {},
      WPSH_BM_WeldingTo_PNo: {},
      WPSH_BM_WeldingTo_GNo: {},
      WPSH_BM_Backing_Backing: {},
      WPSH_BM_Backing_GNo: {},
      WPSH_BM_Backing_PNo: {},
      WPSH_BM_Retainer: {},
      WPSH_WPS_WeldLayer: {},
      WPSH_BM_Backing_Material: {},
      WPSH_ConsuInsert_SFA: {},
      WPSH_ConsuInsert_Classification: {},
      WPSH_ConsuInsert_FNo: {},
      WPSH_ConsuInsert_ANo: {},
      WPSH_ConsuInsert_ChemicalAnalysis_TradeName: {},
      WPSH_Flux_SFA: {},
      WPSH_Flux_Classification: {},
      WPSH_Flux_FNo: {},
      WPSH_Flux_ANo: {},
      WPSH_Flux_ChemicalAnalysis_TradeName: {},
      WPSH_SupFiller_SFA: {},
      WPSH_SupFiller_Classification: {},
      WPSH_SupFiller_FNo: {},
      WPSH_SupFiller_ANo: {},
      WPSH_SupFiller_ChemicalAnalysis_TradeName: {},
      WPSH_BM_Notes: {},
      WPSH_TRQ_GroovewithoutImpact_Min: {},
      WPSH_TRQ_GroovewithoutImpact_Max: {},
      WPSH_TRQ_GroovewithImpact_Min: {},
      WPSH_TRQ_GroovewithImpact_Max: {},
      WPSH_TRQ_FilletwithoutImpact_Min: {},
      WPSH_TRQ_FilletwithoutImpact_Max: {},
      WPSH_TRQ_FilletwithImpact_Min: {},
      WPSH_TRQ_FilletwithImpact_Max: {},
      WPSH_FM_FluxType: {},
      WPSH_FM_AlloyFlux: {},
      WPSH_FM_AlloyElement: {},
      WPSH_FM_Add_Del_Filler: {},
      WPSH_FM_Add_Del_Flux: {},
      WPSH_FM_Add_Del_orchange: {},
      WPSH_FM_FluxItemBatch: {},
      WPSH_FM_FluxFromRecrush: {},
      WPSH_FM_FluxItemCode: {},
      WPSH_FM_FilerMetal: {},
      WPSD_FM_Classification: {},
      WPSH_BM_Type_HT_Con: {},
      WPSH_BM_WeldingTo_HT_Con: {},
      WPSD_FM_ChemicalAnalysis_TradeName: {},
      WPSD_FM_TRQ_Min: {},
      WPSD_FM_TRQ_Max: {},
      WPSH_ConsuInsert: {},
      WPSD_FM_Make: {},
      WPSH_Flux: [],
      WPSH_SupFiller: [],
      WPSH_MinOvlFinThick: {},

      PQR_Process: {},
      WPSH_TRQ_BaseMetalCRO_Min: {},
      WPSH_TRQ_BaseMetalCRO_Max: {},
      WPSH_TRQ_BaseMetalHRO_Min: {},
      WPSH_TRQ_BaseMetalHRO_Max: {},
    });

    const handleChange = (e) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      const { name, value } = e.target;
      setheaderObj((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };

    const handleChange3field = (row, col, key,  e) => {
      if (action === Action.ActionEdit) setIsEdited(true);

      let onChangeVal = [...detailObj];
      onChangeVal[row][key][col] =e.target.value;
      setdetailObj(onChangeVal);
    };

    const handleChangedetailsarray = (e, i) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      const { name, value } = e.target;
      const onChangeVal = [...detailObj];
      onChangeVal[i][name] = value;
      setdetailObj(onChangeVal);
      setError((prevState) => ({
        ...prevState,
        [name]: { status: false, message: "" },
      }));
    };


    const uploadImage = async (item) => {
      const result = await getPresignedURL(folderPQR, item.value.name);
      const posturl = result.url;
      await axios.put(posturl, item.value);
    };

    const validate = async (detaildata) => {
      var isError = false;
      let entries;
      let checkentries
      let errortype;
      
      if (WPStype === "Groove") {
        entries = Object.entries(PQR_GroovePage1);

        errortype = PQR_Detail_GroovePage1;

        if(fieldPWHT === false){
          checkentries = Object.entries(PQR_GrooveCheckbox);
        }
        
      }
      if (WPStype === "Overlay") {
        entries = Object.entries(PQR_OverlayPage1);
        errortype = PQR_Detail_OverlayPage1;
        if(fieldPWHT === false){
          entries = Object.entries(PQR_OverlayCheckbox);
        }
      }

      entries.map(([key, val]) => {
        if (headerObj[key] === "") {

          isError = true;
          error[key] = {
            status: true,
            message: `This field is required`,
          };
        } else {
          error[key] = {
            status: false,
            message: "",
          };
        }
        return isError;


      });
      
      if(fieldPWHT === false){
      
      // console.log(" PQR Groove Error1",error,isError)
    }




      if (
        (action === Action.ActionCopy || action === Action.ActionAdd) &&
        !isError
      ) {
        if (
          await checkDuplicate(
            WPStype,
            headerObj.Wld_createdon_year,
            headerObj.PQR_RecordNo
          )
        ) {
          isError = true;
          error.PQR_RecordNo.status = true;
          error.PQR_RecordNo.message = "PQR Record No. already exists";
        } else {
          error.PQR_RecordNo.status = false;
          error.PQR_RecordNo.message = "";
        }
      }
      
      var norows = checkPQRProcessAdded(detailObj);

      if (norows) {
        detailObj[0].errormessage = "Atleast one Process and Type is required";
        isError = true;
        detailObj[0].error = true;

        if (detailObj[0].PQR_Process !== "" && detailObj[0].PQR_Type !== "") {
          detailObj[0].error = false;
        }
      }
      
      var duplicateprocess = checkduplicateProcess(detailObj);
      if (duplicateprocess) isError = true;

      setError((prevState) => ({
        ...prevState,
        ...error,
      }));


      return isError;


    };

    const validate2 = async (detaildata) => {
           let  errortype 
      
      var validprocess = detailObj.filter((item) => item.PQR_Process !== "");

      let entries5 = Object.entries(detaildata);

        if (WPStype === "Groove") {
          errortype = PQR_Detail_GroovePage1;       
        }
        if (WPStype === "Overlay") {
          errortype = PQR_Detail_OverlayPage1;
        }

        var errortypeVal = WPStype === "Groove" ? PQR_field_Val : PQR_field_ValOverlay;

      let localError = { ...error }; // Use a local variable to track errors
      // var regExp = /^[A-Za-z0-9\s,+:;.@#$&()*!%/-]+$/;
      var regExp = /^[A-Za-z0-9\s- ,+:;.@#^?$&<>()*!%°/-_=}{\]\[|\\'"]+$/; 
      let isError = false;


      errortype.forEach((cols) => {
        entries5.forEach(([key, val]) => {
       
          var value = "";
          var msg = "";
        
          if (cols.Type === "S") {
          
            value = detailObj[key][cols.Column];
            msg = `1) All Fields are Mandatory.\n2) Add "NA" or "-" for no value field.\n3) Special Characters not allowed`;
            if (!value.match(regExp)) {
              // console.log("Error found in value");
              isError = true;
              localError[cols.Column] = {
                status: true,
                message: msg,
              };
            }
          } else if (cols.Type === "A") {
            value =
              detailObj[key][cols.Column][0] ||
              detailObj[key][cols.Column][1] ||
              detailObj[key][cols.Column][2];
          
            msg = `1) All Fields are Mandatory.\n2) Add "NA" or "-" for no value field.\n3) Special Characters not allowed`;
          }
    
          if (
            value === undefined ||
            value === "" ||
            value === null ||
            (localError[cols.Column] && localError[cols.Column].status === true)
          ) {
            isError = true;
            localError[cols.Column] = {
              status: true,
              message: msg,
            };
          } else {
            localError[cols.Column] = {
              status: false,
              message: "",
            };
          }
        });
      });

      errortypeVal.forEach((cols) => {
        entries5.forEach(([key, val]) => {
    
          let isError = false; // Initialize isError outside the loop
    
          let value = "";
          let msg = "";
    
          if (cols.Type === "A") {
            // console.log("here_value_pg2", detailObj[key][cols.Column]);
    
            value =
              detailObj[key][cols.Column][0] ||
              detailObj[key][cols.Column][1] ||
              detailObj[key][cols.Column][2];
            msg = `1) All Fields are Mandatory.\n2) Add "NA" or "-" for no value field.\n3) Special Characters not allowed`;
          }
          // console.log("Final_here_value_pg2", value);/
    
          if (
            value === undefined ||
            value === "" ||
            value === null ||
            (localError[cols.Column] && localError[cols.Column].status === true)
          ) {
            isError = true;
            localError[cols.Column] = {
              status: true,
              message: msg,
            };
          } else {
            localError[cols.Column] = {
              status: false,
              message: "",
            };
          }
    
          // Iterate through each key (assuming there's a loop around this code block)
          Object.keys(validprocess).forEach((key) => {
            
            
            for (let i = 0; i < 3; i++) {
              
              if (!detailObj[key][cols.Column][i]?.match(regExp)) {
                // console.log("Error found in element", i);
                isError = true;
                break; // Exit the loop once an error is found
              }
            }
    
            // Set status based on isError flag
            localError[cols.Column] = {
              status: isError,
              message: isError ? msg : "",
            };
    
            // Reset isError for the next key
          });
        });
      });


    
      setError(localError);

      // Check if any error status is true in the localError object
      const hasError = Object.values(localError).some((err) => err.status === true);
      if (!hasError) {
        isError = false;
      }
      
      // console.log("Local Error", localError, isError);

      return isError;
    };



    const checkDuplicate = async (type, proj, revno) => {
      let reqParam = { groovetype: type, pkid: proj, skid: revno };

      let result = await getbyprojectno(reqParam);

      return !(result[0] === undefined);
    };

    const validateTemp = async () => {
      let isError = false;
      emptyError(error, setError);

      if (headerObj.PQR_RecordNo.trim() === "") {
        headerObj.PQR_RecordNo = "";
        isError = true;
        error.PQR_RecordNo = {
          status: true,
          message: "This field is required",
        };
      }

      if (
        (action === Action.ActionCopy || action === Action.ActionAdd) &&
        !isError
      ) {
        if (
          await checkDuplicate(
            WPStype,
            headerObj.Wld_createdon_year,
            headerObj.PQR_RecordNo
          )
        ) {
          isError = true;
          error.PQR_RecordNo.status = true;
          error.PQR_RecordNo.message = "PQR Record No. already exists";
        } else {
          error.PQR_RecordNo.status = false;
          error.PQR_RecordNo.message = "";
        }
      }

      var norows = checkPQRProcessAdded(detailObj);

      if (norows) {
        detailObj[0].errormessage = "Atleast one Process and Type is required";
        isError = true;
        detailObj[0].error = true;

        if (detailObj[0].PQR_Process !== "" && detailObj[0].PQR_Type !== "") {
          detailObj[0].error = false;
        }
      }

      var duplicateprocess = checkduplicateProcess(detailObj);
      if (duplicateprocess) isError = true;

      setError((prevState) => ({
        ...prevState,
        ...error,
      }));

      return isError;
    };


    const Processstatus = async (data) => {
    
      let detail_SK = [];
      data.forEach((item) => {
        detail_SK.push(item.PQR_Process + "#" + item.PQR_Type);
      });
      setheaderObj({ ...headerObj, detail_SK });
    };

    useEffect(() => {
      let PQRDetail = detailObj.filter(
        (i) => i.PQR_Process !== "NA" && i.PQR_Process !== ""
      );
      if (PQRDetail.length > 0) {
        Processstatus(PQRDetail);
      }
      // console.log("detailObj",detailObj)
    }, [detailObj]);
// =============================

      console.log("detailObjqqq",detailObj)

      // const getLatestChanges = () => {
      //   const latestChangesMap = {};
      //   // Iterate through detailobj and store the most recent change for each process
      //   detailObj.forEach(item => {
      //     const skParts = item.SK.split('#'); // Split sk string by '#'
      //     const process = skParts[2];         // Third part is the process
      //     const change = skParts[3];          // Fourth part is the change
      //     // Always overwrite with the latest change for the process
      //     latestChangesMap[process] = change;
      //   });
      //   console.log("latestChangesMap",latestChangesMap)
      //   // Get the latest change for process1 and process2 if available
      //   return {
      //     process1: latestChangesMap['process1'] || 'No changes for process1',
      //     process2: latestChangesMap['process2'] || 'No changes for process2'
      //   };
      // };
      // const latestChanges = getLatestChanges();

//       const latestChangesMap = {};

//     const getLatestChanges = () => {
      
//       console.log("chngDetailllll",detailObj)
//       // const latestChangesMap = {};
//       // Iterate through changes and store the most recent change for each process
//       detailObj.forEach(change => {
//         const [process, changeValue] = change.PQR_new.split('#');
//         console.log("sdssss",process,changeValue)
//         const changeNumber = parseInt(changeValue.replace('Change', ''), 10);
//         if (!latestChangesMap[process] || changeNumber > latestChangesMap[process].value) {
//           latestChangesMap[process] = {
//             value: changeNumber,
//             original:process + "#" + changeValue // Store the original change value for output
//           };
//           // latestChangesMap[process] = 
//           //   process + "#" + changeValue
          
//         }
//       });
      
//       console.log("latestChangesMap",latestChangesMap)
//       return latestChangesMap;
//     };
        
//     const latestChanges = getLatestChanges();
// console.log("latestChanges",latestChanges)

// let changedd = detailObj.filter(
//   (i) =>{

//     console.log("nmnnnnn", i.PQR_new ,latestChangesMap)
//     return  i.PQR_new === latestChangesMap
//   }

// );
// console.log("changedd",changedd)
    const handleAdd = async (approvalstatus) => {
      debugger

      // console.log("statussss",approvalstatus)
      let result = false;
      // headerObj.PQR_CompanyName = company_name[0.value;
      headerObj.PQR_Division = division[0].value;
     
      headerObj.WPSH_Status = approvalstatus

      var WPSH_PQR_Process = "";
      var WPSH_PQR_new = "";
      var WPSH_PQR_Type = "";
      var WPSH_PQR_Classification = ""
      var WPSH_PQR_TradeName = ""

      let PQRDetail = detailObj.filter(
        (i) => i.PQR_Process !== "NA" && i.PQR_Process !== ""
      );
     

      PQRDetail.map((item) => {
        item.PQR_RecordNo = headerObj.PQR_RecordNo;
        item.wld_WPSType = headerObj.wld_WPSType;
        item.PQR_WelderName = headerObj.PQR_WelderName;
        item.PQR_BM_WTBM_Pno = headerObj.PQR_BM_WTBM_Pno;
        item.PQR_BMT_Plate_thickness_RangeQ_Overlay =
          headerObj.PQR_BMT_Plate_thickness_RangeQ_Overlay;
        item.PQR_BMT_Pipe_Diameter_RangeQ_Overlay =
          headerObj.PQR_BMT_Pipe_Diameter_RangeQ_Overlay;

        item.PK = headerObj.wld_WPSType + "#" + headerObj.PQR_RecordNo;
        WPSH_PQR_Process += item.PQR_Process + ",";
        WPSH_PQR_Type += item.PQR_Type;
        WPSH_PQR_new += item.PQR_new;

        // WPSH_PQR_Type += item.PQR_Layer;

        WPSH_PQR_Classification += item.PQRD_FM_Classification + "+"
        WPSH_PQR_TradeName += item.PQRD_FM_TradeName + ","

        item.SK = item.PQR_Process + "#" + item.PQR_Type
        //  + "#" + headerObj.WPSH_PQR_new;
      });
      // console.log("Test",PQRDetail)

      let PG1Add = {
        Wld_createdon_year: headerObj.Wld_createdon_year,
        wld_WPSType: headerObj.wld_WPSType,
        PQR_RecordNo: headerObj.PQR_RecordNo,

        WPSH_PQR_Process: WPSH_PQR_Process,
        WPSH_PQR_Type: WPSH_PQR_Type,
        WPSH_PQR_Classification:WPSH_PQR_Classification,
        WPSH_PQR_TradeName: WPSH_PQR_TradeName,
        WPSH_Status: headerObj.WPSH_Status,
        Pdf_images: headerObj.Pdf_images,
        detail_SK: headerObj.detail_SK,
        CheckboxPostWHT: headerObj?.CheckboxPostWHT,
        PWHT : headerObj.PWHT

      };
      let PG1Update = {
        Wld_createdon_year: headerObj.Wld_createdon_year,
        wld_WPSType: headerObj.wld_WPSType,
        PQR_RecordNo: headerObj.PQR_RecordNo,
        PQR_ReviNo: headerObj.PQR_ReviNo,
        PQR_WPS_RecordNo: headerObj.PQR_WPS_RecordNo,
        PQR_WPS_ReviNo: headerObj.PQR_WPS_ReviNo,
        PQR_DOB: headerObj.PQR_DOB,
        PQR_CompanyName: headerObj.PQR_CompanyName,
        PQR_WeldingStandard: headerObj.PQR_WeldingStandard,
        PQR_ReferenceCode: headerObj.PQR_ReferenceCode,
      };
  
      let PG1Images = {
        Wld_createdon_year: headerObj.Wld_createdon_year,
        wld_WPSType: headerObj.wld_WPSType,
        PQR_RecordNo: headerObj.PQR_RecordNo,
        Pdf_images: headerObj.Pdf_images,
        PQR_Image_Notes: headerObj.PQR_Address,
      };
  
      let PG1BM = {
        Wld_createdon_year: headerObj.Wld_createdon_year,
        wld_WPSType: headerObj.wld_WPSType,
        PQR_RecordNo: headerObj.PQR_RecordNo,
        PQR_BM_BM_ProductForm: headerObj.PQR_BM_BM_ProductForm,
        PQR_BM_BM_Spec: headerObj.PQR_BM_BM_Spec,
        PQR_BM_BM_Pno: headerObj.PQR_BM_BM_Pno,
        PQR_BM_BM_Gno: headerObj.PQR_BM_BM_Gno,
        PQR_BM_BM_Size: headerObj.PQR_BM_BM_Size,
        PQR_BM_BM_Sch: headerObj.PQR_BM_BM_Sch,
        PQR_BM_BM_Thk: headerObj.PQR_BM_BM_Thk,
        PQR_BM_BM_Dia: headerObj.PQR_BM_BM_Dia ,
        PQR_BM_WTBM_ProductForm: headerObj.PQR_BM_WTBM_ProductForm,
        PQR_BM_WTBM_Spec: headerObj.PQR_BM_WTBM_Spec,
        PQR_BM_WTBM_Pno: headerObj.PQR_BM_WTBM_Pno,
        PQR_BM_WTBM_Gno: headerObj.PQR_BM_WTBM_Gno,
        PQR_BM_WTBM_Size: headerObj.PQR_BM_WTBM_Size,
        PQR_BM_WTBM_Sch: headerObj.PQR_BM_WTBM_Sch,
        PQR_BM_WTBM_Thk: headerObj.PQR_BM_WTBM_Thk,
        PQR_BM_WTBM_Dia: headerObj.PQR_BM_WTBM_Dia,
        // PQR_BM_BM_AndTested: headerObj.PQR_BM_BM_AndTested,
        PQR_BM_BM_Condition1: headerObj.PQR_BM_BM_Condition1,
        PQR_BM_BM_Condition2: headerObj.PQR_BM_BM_Condition2,
        PQR_BM_BM_Notes: headerObj.PQR_BM_BM_Notes,
        CheckboxPostWHT: headerObj.CheckboxPostWHT,
        PWHT : headerObj.PWHT

      };
  
      let PG1JD = {
        Wld_createdon_year: headerObj.Wld_createdon_year,
        wld_WPSType: headerObj.wld_WPSType,
        PQR_RecordNo: headerObj.PQR_RecordNo,
        PQR_JD_JointDesign: headerObj.PQR_JD_JointDesign,
        PQR_JD_Backing: headerObj.PQR_JD_Backing,
        PQR_JD_Retainers: headerObj.PQR_JD_Retainers,
        PQR_JD_GrooveAngle: headerObj.PQR_JD_GrooveAngle,
        PQR_JD_RootOpening: headerObj.PQR_JD_RootOpening,
        PQR_JD_RootFace: headerObj.PQR_JD_RootFace,
        PQR_JD_Notes: headerObj.PQR_JD_Notes,
      };
  


      let PG1PWHT = {
        Wld_createdon_year: headerObj.Wld_createdon_year,
        wld_WPSType: headerObj.wld_WPSType,
        PQR_RecordNo: headerObj.PQR_RecordNo,
        PQR_PWHeatTre_Temperature: headerObj.PQR_PWHeatTre_Temperature,
        PQR_PWHeatTre_Time: headerObj.PQR_PWHeatTre_Time,
        PQR_PWHeatTre_Type: headerObj.PQR_PWHeatTre_Type,
        PQR_PWHeatTre_Heating_Rate: headerObj.PQR_PWHeatTre_Heating_Rate,
        PQR_PWHeatTre_Heating_Method: headerObj.PQR_PWHeatTre_Heating_Method,
        PQR_PWHeatTre_Cooling_Rate: headerObj.PQR_PWHeatTre_Cooling_Rate,
        PQR_PWHeatTre_Cooling_Method: headerObj.PQR_PWHeatTre_Cooling_Method,
        PQR_PWHeatTre_Notes: headerObj.PQR_PWHeatTre_Notes,
        PQR_PWHeatTre_Maintenance: headerObj.PQR_PWHeatTre_Maintenance,
        WPSH_Status: headerObj.WPSH_Status,
        WPSH_Approval_Status: headerObj.WPSH_Status,
        CheckboxPostWHT: headerObj.CheckboxPostWHT,
        PWHT : headerObj.PWHT

      };
      const postAdd = [PG1Add];
      const postUpdate = [PG1Update, PG1Images, PG1BM, PG1JD, PG1PWHT];
      await AddUpdatePQRHeader(postAdd, postUpdate, action)
        .catch((err) => {

        })
        .then(async () => {

          try {
            if(profile !== ""){

            if (headerObj?.Pdf_images[0]?.pqr_image1[0] !== "") {
            
              const result = await getPresignedURL(
                folderPQR,
                headerObj.Pdf_images[0].pqr_image1[0]
              );
             
              const posturl = result.url;
              setPreviewUrl1(posturl);
              await axios.put(posturl, profile);
              setImageView1(true)

            }
          }
          if(profile1 !== ""){

            if (headerObj?.Pdf_images[1].pqr_image2[0] !== "") {
              
              const result = await getPresignedURL(
                folderPQR,
                headerObj?.Pdf_images[1].pqr_image2[0]
              );
              const posturl = result.url;
              setPreviewUrl2(posturl);
              await axios.put(posturl, profile1);
              setImageView2(true)

            }
          }

          } catch (error) {
            if (error.request) {
            } else {
              console.log("Error", `Error: ${error.message}`);
            }
          }
          result = true;
    
          if (imgObj.length > 0) {
            for (let item of imgObj) {

              if (item.value.name) await uploadImage(item);
            }
          }
         
          // setaction(Action.ActionEdit);        


          debugger

   
          prevdetailobj.map(async (old) => {
         console.log("Found array", old)
          let res= PQRDetail.find((newdetail) => 
            {
              console.log("found newdetail", newdetail.PQR_Process , newdetail.PQR_Type ,old.Process ,  old.Type)
             return newdetail.PQR_Process + "#" + newdetail.PQR_Type === old.Process +"#" +  old.Type
            }
          );         
          console.log("Freeeeee", res)

        
          if(res == undefined && old.Process != ""){
            
            console.log("undeffiiii")

            let gridDetail = {
              PK: headerObj.wld_WPSType + "#" + headerObj.PQR_RecordNo,
              SK: old.Process + "#" + old.Type,
              WPSH_PQR_Process: old.Process,
              WPSH_PQR_Type: old.Type,
            };
            await DeletePQR(gridDetail);
          }
      })
    

       
          PQRDetail.map( async (detailrow) =>{
            await AddNewDetail(detailrow)
            .catch((err) => {})
            .then((r) => {
              setaction(Action.ActionEdit);
              result = true;
            });
           
          })
           
        
        });

        let oldval = []
        detailObj.forEach(process =>{
         let prc=  {Process :process.PQR_Process, Type: process.PQR_Type}
         oldval.push(prc)
         console.log("oldval",prc)
        })
        //  oldDetailobj(detailObj)
        setprevdetailObj(oldval);
      return result;
    };

    const fetchProcess = async () => {
      console.log("fetch Process",count)
      const result = await getActiveProcess();
      const process = [{ wld_code: "NA" }, ...result];
      setProcessActive(process);
    };

    const fetchPNo = async () => {
      const result = await getPNOActive();
      const pno = [{ wld_code: "NA" }, ...result];
      setPNoActive(pno);
    };

    const fetchfno = async () => {
      const result = await getActiveFno();
      setFnoActive(result);
    };

    const fetchAno = async () => {
      const result = await getActiveAno();
      setAnoActive(result);
    };

    const fetchSfa = async () => {
      const result = await getActiveSfa();
      setSfaActive(result);
    };




    useEffect(() => {
      debugger
      console.log("test in use Effect",count)
      fetchProcess();
      fetchPNo();
      fetchSfa();
      fetchfno();
      fetchAno();
     
    }, []);


    useEffect(() => {
      if (imgDataObj.length > 0) {
        setheaderObj((prevState) => ({
          ...prevState,
          ["wld_pdf_image"]: imgDataObj,
        }));
      }
    }, [imgDataObj]);

    const fetchImages = async (item) => {
      try {
        let unique = headerObj.WPSH_Project_No + "_" + headerObj.WPSH_WPS_No;
        if (item.value !== "" || item.value !== undefined) {
          const result = await getPresignedURL(folderPQR, item.value);
          const geturl = result.url;

          let a = { name: item.name, value: geturl };
          setPreviewUrl((prev) => [...prev, a]);
          
        }
      } catch (error) {
        if (error.request) {
         // console.log(error.request);
        } else {
          //console.log(`Error: ${error.message}`);
        }
      }
    };

    const fetchImages1 = async (item) => {
      
          if (item) {
                    
            const result = await getPresignedURL(
              folderPQR,
              item
            );
            const posturl = result.url;
            setPreviewUrl1(posturl);
        
          }
      
        }
      
        const fetchImages2 = async (item) => {
          
              if (item) {
                        
              
                const result = await getPresignedURL(
                  folderPQR,
                  item
                );
                const posturl = result.url;
                setPreviewUrl2(posturl);
              }
          
            }

    useEffect(() => {
      if (headerObj.wld_pdf_image) {
        for (let item of headerObj.wld_pdf_image) {
          fetchImages(item);
        }
      }
    }, []);


    useEffect(() => {
      
      if (action === Action.ActionEdit && headerObj?.Pdf_images[0]?.pqr_image1[0] !== "")
        {
        fetchImages1(headerObj?.Pdf_images[0]?.pqr_image1[0])
        }

        if (action === Action.ActionEdit && headerObj?.Pdf_images[1]?.pqr_image2[0] !== "")
          {
          fetchImages2(headerObj?.Pdf_images[1]?.pqr_image2[0])
          }
       
      
    }, []);


    
    useEffect(() => {
      
      if (ImageView1 === true && headerObj?.Pdf_images[0]?.pqr_image1[0] !== "")
      {
        fetchImages1(headerObj?.Pdf_images[0]?.pqr_image1[0])
      }
    }, [ImageView1]);

    useEffect(() => {
      if (ImageView2 === true && headerObj?.Pdf_images[1]?.pqr_image2[0] !== "")
      {
        fetchImages2(headerObj?.Pdf_images[1]?.pqr_image2[0])
      }
    }, [ImageView2]);

    const fileInputRef = useRef(null);

    const getImgURL = (name) => {
      let temp = previewUrl.filter((item) => {
        return item.name === name;
      });
      return temp[0]?.value;
    };

    const handleSubmit = async () => {
      var result = false;
      
      var detaildata = detailObj.filter((item) => item.PQR_Process !== "");
      
      if (!(await validate(detaildata)) && !(await validate2(detaildata))) {
        result = await handleAdd(Status.StatusTempSaved);
        if (result) {
          return MessageTypes.Success;
        } else {
          return MessageTypes.Fail;
        }
      } else {
        return MessageTypes.Required;
      }

    };

    const handleSubmitTemp = async () => {
      var result = false;
      var val = await validateTemp();
      if (action === Action.ActionEdit) {
        if (IsEdited) {
          setIsEdited(false);

          if (!val) {
            if (headerObj.WPSH_Status === Status.StatusFreezed) {
              result = handleAdd(Status.StatusTempSaved);
            } else {
              result = handleAdd(Status.StatusTempSaved);
            }

            if (result) {
              return MessageTypes.Temp;
            } else {
              return MessageTypes.Required;
            }
          }
        }
      }
      if (!val) {
        result = handleAdd(Status.StatusTempSaved);

        if (result) {
          return MessageTypes.Temp;
        } else {
          return MessageTypes.Required;
        }
      }
    };

    useImperativeHandle(ref, () => ({
      funcOnNext: async () => {
        var res = false;
        res = await handleSubmit();
        return res;
      },

      funcOnNextTemp: async () => {
        var res = false;
        res = await handleSubmitTemp();
        return res;
      },
    }));

   

    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1;
    let dd = today.getDate();
    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    const todayDate = yyyy + "-" + mm + "-" + dd;


    const handleFile1 = (file,index, e) => {
      
      const maxSize = 1 * 1024 * 1024;
      if (file.size > maxSize) {
        alert("File size exceeds the allowed limit. Add File Less Than 1 Mb");
        file = null;
        return;
      }

      let uploadextension = file.name.split(".");

      
      if (
        uploadextension[1] !== "jpeg" &&
        uploadextension[1] !== "png" &&
        uploadextension[1] !== "jpg"
      ) {

        alert("Please Upload Jpeg,png or jpg images");
        file = null;
        return;
      }

      if(index == 0 ){
      setProfile(file);
      setPreviewUrl1(URL.createObjectURL(file));
      headerObj.Pdf_images[0].pqr_image1[0] = file.name;
      }
      
      else if(index == 1){
      setProfile1(file);
      setPreviewUrl2(URL.createObjectURL(file));
      headerObj.Pdf_images[1].pqr_image2[0] = file.name;
      }
    };
 

    const handleCheckboxChange = (e) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      setIsChecked(e.target.checked)
      setFieldPWHT(!e.target.checked)
      setheaderObj((header) => ({
        ...header,
        CheckboxPostWHT: e.target.checked
      }));    
    }

    return (
      <Row className="font">
        <Row className="PQR-Page-row-padding" style={{padding: "20px 60px" }} >
          <Row className="mb-2">
            <Col md={3}>
              <Form.Group>
                <Single_Input
                  name="PQR_RecordNo"
                  type="text"
                  maxLength={8}
                  readOnly={isReadonly}
                  status={error.PQR_RecordNo.status}
                  value={headerObj?.PQR_RecordNo}
                  disabled={action === Action.ActionEdit ? true : false}
                  handleChange={handleChange}
                  label="PQR Record No."
                  message={error.PQR_RecordNo.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(
                      e.target.value
                    );
                    setError((prevState) => ({
                      ...prevState,
                      PQR_RecordNo: error,
                    }));
                  }}
                ></Single_Input>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Single_Input
                  name="PQR_ReviNo"
                  type="text"
                  maxLength={2}
                  readOnly={isReadonly}
                  status={error.PQR_ReviNo.status}
                  value={headerObj?.PQR_ReviNo}
                  handleChange={handleChange}
                  label="Revision"
                  // label1="(Optional)"
                  message={error.PQR_ReviNo.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      PQR_ReviNo: error,
                    }));
                  }}
                ></Single_Input>
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group>
                <Single_Input
                  name="PQR_WPS_RecordNo"
                  type="text"
                  maxLength={20}
                  status={error.PQR_WPS_RecordNo.status}
                  disabled={action === Action.ActionEdit ? true : false}
                  value={headerObj?.PQR_WPS_RecordNo}
                  handleChange={handleChange}
                  readOnly={isReadonly}
                  label="WPS Record No."
                  label1=""
                  message={error.PQR_WPS_RecordNo.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      PQR_WPS_RecordNo: error,
                    }));
                  }}
                ></Single_Input>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Single_Input
                  name="PQR_WPS_ReviNo"
                  type="text"
                  maxLength={2}
                  readOnly={isReadonly}
                  status={error.PQR_WPS_ReviNo.status}
                  value={headerObj?.PQR_WPS_ReviNo}
                  handleChange={handleChange}
                  label="WPS Revision No."
                  label1=""
                  message={error.PQR_WPS_ReviNo.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      PQR_WPS_ReviNo: error,
                    }));
                  }}
                ></Single_Input>
              </Form.Group>
            </Col>

            <Col md={3}>
              <Date_InputUser
                divClass="T-input w-100"
                label="Date"
                className="groove_datecolor"
                name="PQR_DOB"
                sx={{ fontSize: "6px", fontFamily: "Work Sans", zIndex: "0" }}
                todayDate={todayDate}
                obj={headerObj}
                setobj={setheaderObj}
                setError={setError}
                status={error.PQR_DOB.status}
                message={error.PQR_DOB.message}
              />
            </Col>

         
              <Col md={3}>
              <Form.Group>
                <General_Single_Input_select
                  name="PQR_CompanyName"
                  type="text"
                  readOnly={isReadonly}
                  status={error.PQR_CompanyName.status}
                  value={headerObj?.PQR_CompanyName}
                  handleSelectChange={handleChange}
                  label="Company Name "
                  message={error.PQR_CompanyName.message}
                  array={Company_Name}
                ></General_Single_Input_select>
              </Form.Group>
            </Col>

            <Col md={3}>
            {!isoverlay ? (
            <Form.Group>
            <Single_Input
                  name="PQR_ReferenceCode"
                  type="text"
                  maxLength={40}
                  readOnly={isReadonly}
                  status={error.PQR_ReferenceCode.status}
                  value={headerObj?.PQR_ReferenceCode}
                  handleChange={handleChange}
                  label="Reference Code"
                  label1=""
                  message={error.PQR_ReferenceCode.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      PQR_ReferenceCode: error,
                    }));
                  }}
                ></Single_Input>
              </Form.Group>) :
              ( <Form.Group>
                <General_Single_Input_select
                  name="PQR_WeldingStandard"
                  type="text"
                  readOnly={isReadonly}
                  status={error.PQR_WeldingStandard.status}
                  value={headerObj?.PQR_WeldingStandard}
                  handleSelectChange={handleChange}
                  label="Welding Standard"
                  message={error.PQR_WeldingStandard.message}
                  array={Plant_Location}
                ></General_Single_Input_select>
              </Form.Group> )}
            </Col>
          </Row>
        </Row>

        <Row className="PQR-Page-row-padding bg-white" style={{padding: "20px 60px" }} >
          <Row className="mb-1">
            <Col md={12}>
              <p className="WPS-blue-text " style={{position:"relative",top:"10px"}}>Base Metals </p>
              {isoverlay ? (
                <BaseMetalTable_PQR
                  headerObj={headerObj}
                  setheaderObj={setheaderObj}
                  error={error}
                  isReadonly={isReadonly}
                  handleChange={handleChange}
                  setError={setError}
                  PNoActive={PNoActive}
                  blyestyle={blyestyle}
                  errorstyle={errorstyle}
                  isDisabled={isDisabled}
                ></BaseMetalTable_PQR>
              ) : (
                <BaseMetalTable_PQR
                  headerObj={headerObj}
                  setheaderObj={setheaderObj}
                  error={error}
                  isReadonly={isReadonly}
                  handleChange={handleChange}
                  setError={setError}
                  PNoActive={PNoActive}
                  blyestyle={blyestyle}
                  errorstyle={errorstyle}
                  isDisabled={isDisabled}
                ></BaseMetalTable_PQR>
              )}
            </Col>
          </Row>

          <Row className="mb-1">
            {/* <Col md={12}>
              <Form.Group>
                <Single_Input
                  name="PQR_BM_BM_AndTested"
                  type="text"
                  maxLength={85}
                  readOnly={isReadonly}
                  status={error.PQR_BM_BM_AndTested.status}
                  value={headerObj?.PQR_BM_BM_AndTested}
                  handleChange={handleChange}
                  label="And Tested"
                  message={error.PQR_BM_BM_AndTested.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(
                      e.target.value
                    );
                    setError((prevState) => ({
                      ...prevState,
                      PQR_BM_BM_AndTested: error,
                    }));
                  }}
                ></Single_Input>
              </Form.Group>
            </Col> */}
            {!isoverlay ? (
              <Col md={6}>
                <Form.Group>
                  <Single_Input
                    name="PQR_BM_BM_Condition1"
                    type="text"
                    maxLength={25}
                    readOnly={isReadonly}
                    status={error.PQR_BM_BM_Condition1.status}
                    value={headerObj?.PQR_BM_BM_Condition1}
                    handleChange={handleChange}
                    label="Base Material Condition 1"
                    message={error.PQR_BM_BM_Condition1.message}
                    onInput={(e) => {
                      let error = isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        PQR_BM_BM_Condition1: error,
                      }));
                    }}
                  ></Single_Input>
                </Form.Group>
              </Col>
            ) : (
              ""
            )}
            {!isoverlay ? (
              <Col md={6}>
                <Form.Group>
                  <Single_Input
                    name="PQR_BM_BM_Condition2"
                    type="text"
                    maxLength={25}
                    readOnly={isReadonly}
                    status={error.PQR_BM_BM_Condition2.status}
                    value={headerObj?.PQR_BM_BM_Condition2}
                    handleChange={handleChange}
                    label="Base Material Condition 2"
                    message={error.PQR_BM_BM_Condition2.message}
                    onInput={(e) => {
                      let error = isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        PQR_BM_BM_Condition2: error,
                      }));
                    }}
                  ></Single_Input>
                </Form.Group>
              </Col>
            ) : (
              ""
            )}

            <Col md={12}>
              <Form.Group>
                <Single_Input
                  name="PQR_BM_BM_Notes"
                  type="text"
                  maxLength={85}
                  readOnly={isReadonly}
                  status={error.PQR_BM_BM_Notes.status}
                  value={headerObj?.PQR_BM_BM_Notes}
                  handleChange={handleChange}
                  label="Notes"
                  message={error.PQR_BM_BM_Notes.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(
                      e.target.value
                    );
                    setError((prevState) => ({
                      ...prevState,
                      PQR_BM_BM_Notes: error,
                    }));
                  }}
                ></Single_Input>
              </Form.Group>
            </Col>
          </Row>
        </Row>

        <Row style={{padding: "15px 60px" }} className="PQR-Page-row-padding">
          <Row style={{ padding: "0px 13px" }}>
            <Row>
              <div className="checkboxproject">
                <input
                  type="checkbox"
                  placeholder=" "
                  id="chkcode"
                  name="CheckboxPostWHT"
                  onClick={() => {
                    if(isChecked === true || headerObj?.CheckboxPostWHT === "true"){
                      setheaderObj((prevState) => ({
                        ...prevState,
                        PQR_PWHeatTre_Temperature: "Not Applicable",
                        PQR_PWHeatTre_Time: "Not Applicable",
                        PQR_PWHeatTre_Type: "Not Applicable",
                        PQR_PWHeatTre_Heating_Rate: "Not Applicable",
                        PQR_PWHeatTre_Heating_Method: "Not Applicable",
                        PQR_PWHeatTre_Cooling_Rate: "Not Applicable",
                        PQR_PWHeatTre_Cooling_Method: "Not Applicable",
                        PQR_PWHeatTre_Notes: "Not Applicable",
                        PQR_PWHeatTre_Maintenance: "Not Applicable",
                        PWHT:"No",
                              }));

                              setError((prevState) => ({
                                ...prevState,
                                PQR_PWHeatTre_Temperature:{status:false,message:""},
                                PQR_PWHeatTre_Time:{status:false,message:""},
                                PQR_PWHeatTre_Type:{status:false,message:""},
                                PQR_PWHeatTre_Heating_Rate:{status:false,message:""},
                                PQR_PWHeatTre_Heating_Method:{status:false,message:""},
                                PQR_PWHeatTre_Cooling_Rate:{status:false,message:""},
                                PQR_PWHeatTre_Cooling_Method:{status:false,message:""},
                                PQR_PWHeatTre_Notes:{status:false,message:""},
                                PQR_PWHeatTre_Maintenance:{status:false,message:""},
                              }));
                    }
                    
                    if(isChecked === false || headerObj?.CheckboxPostWHT === "false"){
                      setheaderObj((prevState) => ({
                        ...prevState,
                        PQR_PWHeatTre_Temperature: "",
                        PQR_PWHeatTre_Time: "",
                        PQR_PWHeatTre_Type: "",
                        PQR_PWHeatTre_Heating_Rate: "",
                        PQR_PWHeatTre_Heating_Method: "",
                        PQR_PWHeatTre_Cooling_Rate: "",
                        PQR_PWHeatTre_Cooling_Method: "",
                        PQR_PWHeatTre_Notes: "",
                        PQR_PWHeatTre_Maintenance: "",
                        PWHT:"Yes",

                              }));
                    }
                  }}
                  onChange={handleCheckboxChange}
                  value={headerObj?.CheckboxPostWHT === "false" ? false : isChecked  }
                  checked={headerObj?.CheckboxPostWHT === "false" ? false : isChecked  }
                />{" "}
                <span className="WPS-blue-text" style={{position:"relative",bottom:"2px"}}>
                  Post Weld Heat Treatment (QW-407)
                </span>
              </div>
            </Row>
            <Row className="mb-1" style={{ position: "relative", top: "18px" }}>
              <Col className="mb-1" md={3}>
                <Single_Input
                  disabled={headerObj?.CheckboxPostWHT === "false" ? true : fieldPWHT }
                  name="PQR_PWHeatTre_Temperature"
                  type="text"
                  maxLength={20}
                  status={error.PQR_PWHeatTre_Temperature.status}
                  value={headerObj?.PQR_PWHeatTre_Temperature}
                  handleChange={handleChange}
                  label="Temperarture(°C)"
                  message={error.PQR_PWHeatTre_Temperature.message}
                  onInput={(e) => {
                    let error =
                      isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                    setError((prevState) => ({
                      ...prevState,
                      PQR_PWHeatTre_Temperature: error,
                    }));
                  }}
                ></Single_Input>
              </Col>

              <Col className="mb-1" md={3}>
                <Single_Input
                  disabled={headerObj?.CheckboxPostWHT === "false" ? true : fieldPWHT }
                  name="PQR_PWHeatTre_Time"
                  type="text"
                  maxLength={28}
                  status={error.PQR_PWHeatTre_Time.status}
                  value={headerObj?.PQR_PWHeatTre_Time}
                  handleChange={handleChange}
                  label="Time (Minutes)"
                  message={error.PQR_PWHeatTre_Time.message}
                  onInput={(e) => {
                    let error =
                      isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                    setError((prevState) => ({
                      ...prevState,
                      PQR_PWHeatTre_Time: error,
                    }));
                  }}
                ></Single_Input>
              </Col>
              <Col className="mb-1" md={3}>
                <Single_Input
                  disabled={headerObj?.CheckboxPostWHT === "false" ? true : fieldPWHT }
                  name="PQR_PWHeatTre_Type"
                  type="text"
                  maxLength={18}
                  status={error.PQR_PWHeatTre_Type.status}
                  value={headerObj?.PQR_PWHeatTre_Type}
                  handleChange={handleChange}
                  label="Type"
                  message={error.PQR_PWHeatTre_Type.message}
                  onInput={(e) => {
                    let error =
                      isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                    setError((prevState) => ({
                      ...prevState,
                      PQR_PWHeatTre_Type: error,
                    }));
                  }}
                ></Single_Input>
              </Col>
            </Row>
            <Row className="mb-1">
              <Col className="mb-1" md={3}>
                <div class="T-input w-100">
                  <input
                    type="text"
                    id="PQR_PWHeatTre_Heating_Rate"
                    name="PQR_PWHeatTre_Heating_Rate"
                    maxLength={20}
                    disabled={headerObj?.CheckboxPostWHT === "false" ? true : fieldPWHT }
                   
                    placeholder=" "
                    className={
                      error.PQR_PWHeatTre_Heating_Rate.status
                        ? " T-input placeHolderInput inputText text-field-with-error"
                        : "T-input placeHolderInput inputText"
                    }
                    value={headerObj?.PQR_PWHeatTre_Heating_Rate}
                    onChange={handleChange}
                    onInput={(e) => {
                      let error =
                        isAlphanumericAllSpecialCharacters(
                          e.target.value
                        );
                      setError((prevState) => ({
                        ...prevState,
                        PQR_PWHeatTre_Heating_Rate: error,
                      }));
                    }}
                  />
                  <label for="PQR_PWHeatTre_Heating_Rate">
                    Heating Rate (C/Hr)
                  </label>
                </div>
                <div className="required-text1">
                  {error.PQR_PWHeatTre_Heating_Rate.status && (
                    <Form.Text className="text-danger">
                      {error.PQR_PWHeatTre_Heating_Rate.message}
                    </Form.Text>
                  )}
                </div>
              </Col>

              <Col className="mb-1" md={3}>
                <div class="T-input w-100">
                  <input
                    type="text"
                    id="c"
                    name="PQR_PWHeatTre_Heating_Method"
                    disabled={headerObj?.CheckboxPostWHT === "false" ? true : fieldPWHT }
                    maxLength={20}
                    placeholder=" "
                    className={
                      error.PQR_PWHeatTre_Heating_Method.status
                        ? " T-input placeHolderInput inputText text-field-with-error"
                        : "T-input placeHolderInput inputText"
                    }
                    value={headerObj?.PQR_PWHeatTre_Heating_Method}
                    onChange={handleChange}
                    onInput={(e) => {
                      let error =
                        isAlphanumericAllSpecialCharacters(
                          e.target.value
                        );
                      setError((prevState) => ({
                        ...prevState,
                        PQR_PWHeatTre_Heating_Method: error,
                      }));
                    }}
                  />
                  <label for="PQR_PWHeatTre_Heating_Method">Loading Temperature °C</label>
                </div>
                <div className="required-text1">
                  {error.PQR_PWHeatTre_Heating_Method.status && (
                    <Form.Text className="text-danger">
                      {error.PQR_PWHeatTre_Heating_Method.message}
                    </Form.Text>
                  )}
                </div>
              </Col>
              <Col className="mb-1" md={3}>
                <Single_Input
                  // disabled={copydata}
                  disabled={headerObj?.CheckboxPostWHT === "false" ? true : fieldPWHT }
                  name="PQR_PWHeatTre_Cooling_Rate"
                  type="text"
                  maxLength={20}
                  status={error.PQR_PWHeatTre_Cooling_Rate.status}
                  value={headerObj?.PQR_PWHeatTre_Cooling_Rate}
                  handleChange={handleChange}
                  label=" Cooling Rate (C/Hr)"
                  message={error.PQR_PWHeatTre_Cooling_Rate.message}
                  onInput={(e) => {
                    let error =
                      isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                    setError((prevState) => ({
                      ...prevState,
                      PQR_PWHeatTre_Cooling_Rate: error,
                    }));
                  }}
                ></Single_Input>
              </Col>
              <Col className="mb-1" md={3}>
                <Single_Input
                  
                  disabled={headerObj?.CheckboxPostWHT === "false" ? true : fieldPWHT }
                  name="PQR_PWHeatTre_Cooling_Method"
                  type="text"
                  maxLength={20}
                  status={error.PQR_PWHeatTre_Cooling_Method.status}
                  value={headerObj?.PQR_PWHeatTre_Cooling_Method}
                  handleChange={handleChange}
                  label="Unloading Temperature °C"
                  message={error.PQR_PWHeatTre_Cooling_Method.message}
                  onInput={(e) => {
                    let error =
                      isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                    setError((prevState) => ({
                      ...prevState,
                      PQR_PWHeatTre_Cooling_Method: error,
                    }));
                  }}
                ></Single_Input>
              </Col>
            </Row>
            <Row className="mb-1">
              <Col className="mb-1" md={12}>
                <Single_Input
                 
                  disabled={headerObj?.CheckboxPostWHT === "false" ? true : fieldPWHT }
                  name="PQR_PWHeatTre_Notes"
                  type="text"
                  maxLength={87}
                  status={error.PQR_PWHeatTre_Notes.status}
                  message={error.PQR_PWHeatTre_Notes.message}
                  value={headerObj?.PQR_PWHeatTre_Notes}
                  handleChange={handleChange}
                  label="Notes"
                  onInput={(e) => {
                    let error =
                    isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                    setError((prevState) => ({
                      ...prevState,
                      PQR_PWHeatTre_Notes: error,
                    }));
                  }}
                ></Single_Input>
              </Col>
            </Row>

            <Row className="mb-1">
              <Col md={12}>
                <Form.Group>
                  <Single_Input
                  disabled={headerObj?.CheckboxPostWHT === "false" ? true : fieldPWHT }
                  name="PQR_PWHeatTre_Maintenance"
                    type="text"
                    maxLength={30}
                    // style={{width:"825px"}}
                    readOnly={isReadonly}
                    status={error.PQR_PWHeatTre_Maintenance.status}
                    value={headerObj?.PQR_PWHeatTre_Maintenance}
                    handleChange={handleChange}
                    label="Preheat maintenance after completion of welding prior to PWHT ( QW-406.2)"
                    message={error.PQR_PWHeatTre_Maintenance.message}
                    onInput={(e) => {
                      let error =
                        isAlphanumericAllSpecialCharacters(
                          e.target.value
                        );
                      setError((prevState) => ({
                        ...prevState,
                        PQR_PWHeatTre_Maintenance: error,
                      }));
                    }}
                  ></Single_Input>
                </Form.Group>
              </Col>
            </Row>
          </Row>
        </Row>

        {isoverlay ?
         (
          <Row style={{ padding: "20px 60px", position:"relative",bottom:"30px" }} className="PQR-Page-row-padding">
            <Row className="mb-1">
              <Col md={12}>
                <p className="WPS-blue-text" style={{position:"relative",top:"10px"}}>Joints (QW - 402)</p>
              </Col>

              <Col md={3}>
                <Form.Group>
                  <Single_Input
                    name="PQR_JD_JointDesign"
                    type="text"
                    maxLength={20}
                    readOnly={isReadonly}
                    status={error.PQR_JD_JointDesign.status}
                    value={headerObj?.PQR_JD_JointDesign}
                    handleChange={handleChange}
                    label="Joint Design"
                    message={error.PQR_JD_JointDesign.message}
                    onInput={(e) => {
                      let error = isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        PQR_JD_JointDesign: error,
                      }));
                    }}
                  ></Single_Input>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group>
                  <Single_Input
                    name="PQR_JD_Notes"
                    type="text"
                    maxLength={25}
                    readOnly={isReadonly}
                    status={error.PQR_JD_Notes.status}
                    value={headerObj?.PQR_JD_Notes}
                    handleChange={handleChange}
                    label="Notes"
                    message={error.PQR_JD_Notes.message}
                    onInput={(e) => {
                      let error = isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        PQR_JD_Notes: error,
                      }));
                    }}
                  ></Single_Input>
                </Form.Group>
              </Col>
            </Row>
          </Row>
        ) : (
          <Row style={{padding: "20px 60px", position:"relative",bottom:"30px" }}  className="PQR-Page-row-padding">
            <Row className="mb-1">
              <Col md={12}>
                <p className="WPS-blue-text" style={{position:"relative",top:"10px"}}>Joints (QW - 402)</p>
              </Col>

              <Col md={3}>
                <Form.Group>
                  <Single_Input
                    name="PQR_JD_JointDesign"
                    type="text"
                    maxLength={20}
                    readOnly={isReadonly}
                    status={error.PQR_JD_JointDesign.status}
                    value={headerObj?.PQR_JD_JointDesign}
                    handleChange={handleChange}
                    label="Joint Design"
                    message={error.PQR_JD_JointDesign.message}
                    onInput={(e) => {
                      let error = isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        PQR_JD_JointDesign: error,
                      }));
                    }}
                  ></Single_Input>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group>
                  <Single_Input
                    name="PQR_JD_Backing"
                    type="text"
                    maxLength={20}
                    readOnly={isReadonly}
                    status={error.PQR_JD_Backing.status}
                    value={headerObj?.PQR_JD_Backing}
                    handleChange={handleChange}
                    label="Backing"
                    message={error.PQR_JD_Backing.message}
                    onInput={(e) => {
                      let error = isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        PQR_JD_Backing: error,
                      }));
                    }}
                  ></Single_Input>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group>
                  <Single_Input
                    name="PQR_JD_Retainers"
                    type="text"
                    maxLength={20}
                    readOnly={isReadonly}
                    status={error.PQR_JD_Retainers.status}
                    value={headerObj?.PQR_JD_Retainers}
                    handleChange={handleChange}
                    label="Retainers"
                    message={error.PQR_JD_Retainers.message}
                    onInput={(e) => {
                      let error = isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        PQR_JD_Retainers: error,
                      }));
                    }}
                  ></Single_Input>
                </Form.Group>
              </Col>

              <Col md={3}>
                <Form.Group>
                  <Single_Input
                    name="PQR_JD_GrooveAngle"
                    type="text"
                    maxLength={20}
                    readOnly={isReadonly}
                    status={error.PQR_JD_GrooveAngle.status}
                    value={headerObj?.PQR_JD_GrooveAngle}
                    handleChange={handleChange}
                    label="Groove Angle (deg) "
                    message={error.PQR_JD_GrooveAngle.message}
                    onInput={(e) => {
                      let error = isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        PQR_JD_GrooveAngle: error,
                      }));
                    }}
                  ></Single_Input>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-1">
              <Col md={3}>
                <Form.Group>
                  <Single_Input
                    name="PQR_JD_RootOpening"
                    type="text"
                    maxLength={20}
                    readOnly={isReadonly}
                    status={error.PQR_JD_RootOpening.status}
                    value={headerObj?.PQR_JD_RootOpening}
                    handleChange={handleChange}
                    label="Root Opening (mm)"
                    message={error.PQR_JD_RootOpening.message}
                    onInput={(e) => {
                      let error = isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        PQR_JD_RootOpening: error,
                      }));
                    }}
                  ></Single_Input>
                </Form.Group>
              </Col>

              <Col md={3}>
                <Form.Group>
                  <Single_Input
                    name="PQR_JD_RootFace"
                    type="text"
                    readOnly={isReadonly}
                    maxLength={20}
                    status={error.PQR_JD_RootFace.status}
                    value={headerObj?.PQR_JD_RootFace}
                    handleChange={handleChange}
                    label="Root Face (mm)"
                    message={error.PQR_JD_RootFace.message}
                    onInput={(e) => {
                      let error = isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        PQR_JD_RootFace: error,
                      }));
                    }}
                  ></Single_Input>
                </Form.Group>
              </Col>

              <Col md={12}>
                <Form.Group>
                  <Single_Input
                    name="PQR_JD_Notes"
                    type="text"
                    maxLength={20}
                    readOnly={isReadonly}
                    status={error.PQR_JD_Notes.status}
                    value={headerObj?.PQR_JD_Notes}
                    handleChange={handleChange}
                    label="Notes"
                    message={error.PQR_JD_Notes.message}
                    onInput={(e) => {
                      let error = isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        PQR_JD_Notes: error,
                      }));
                    }}
                  ></Single_Input>
                </Form.Group>
              </Col>
            </Row>
          </Row>
        )}

        <Row style={{padding: "20px 60px" , position:"relative", bottom:"55px" }}  className="PQR-Page-row-padding">
          <ImageArray2
            headerObj={headerObj}
            setheaderObj={setheaderObj}
            getImgURL={getImgURL}
            imgObj={imgObj}
            setimgObj={setimgObj}
            type="file"
            accept="image/jpeg, image/png, image/jpg"
            fileInputRef={fileInputRef}
            handleFile={handleFile1}
            previewUrl1={previewUrl1}
            previewUrl2={previewUrl2}
            name1="fileInput1"
            name2="fileInput2"
          ></ImageArray2>
        </Row>

        <Row style={{ background: "white", padding: "20px 60px" ,  position:"relative", bottom:"45px"  }}>
          <Row className="mb-1">
            <Col md={12}>
              <p className="WPS-blue-text ">Welding Process </p>
              <PQRProcess
                headerObj={headerObj}
                detailObj={detailObj}
                setdetailObj={setdetailObj}
                ProcessActive={ProcessActive}
                isReadonly={isReadonly}
                toast={toast}
              
              />

              <Row className="mb-4" style={{padding: "0px 8px"}}>
                <div className="scrollpqr">
                  <p className="WPS-blue-text ">Filler Metals (QW-404) </p>

                  <Col style={{position:"relative", bottom: "8px"}}>
                    <Table
                      bordered
                      responsive="sm"
                      className="text-center same-col-widths align-middle"
                    >
                      <tbody>
                        <PQR_FillerMetal_Select
                        detailObj={detailObj}
                          wps_detail={detailObj}
                          ColumnHeader="SFA Specification"
                          Columndimension=""
                          status={error.PQRD_FM_SFA.status}
                          message={error.PQRD_FM_SFA.message}
                          type="text"
                          name="PQRD_FM_SFA"
                          handleChangedetailsarray={handleChangedetailsarray}
                          handleOnInput={handleOnInput}
                          TypeofCheck={isAlphanumericAllSpecialCharacters}
                          setError={setError}
                          setdetailObj={setdetailObj}
                          ActiveData={SfaActive}

                        ></PQR_FillerMetal_Select>

                        <PQR_FillerMetal_Input
                          wps_detail={detailObj}
                          ColumnHeader="Classification"
                          Columndimension=""
                          status={error.PQRD_FM_Classification.status}
                          message={error.PQRD_FM_Classification.message}
                          type="text"
                          width={272}
                          maxLength={15}
                          name="PQRD_FM_Classification"
                          handleChangedetailsarray={handleChangedetailsarray}
                          handleOnInput={handleOnInput}
                          TypeofCheck={isAlphanumericAllSpecialCharacters}
                          setError={setError}
                        ></PQR_FillerMetal_Input>

                        <PQR_FillerMetal_Select
                          detailObj={detailObj}
                          wps_detail={detailObj}
                          ColumnHeader="Filler Metal F-Number"
                          Columndimension=""
                          status={error.PQRD_FM_FNo.status}
                          message={error.PQRD_FM_FNo.message}
                          type="text"
                          name="PQRD_FM_FNo"
                          setError={setError}
                          handleChangedetailsarray={handleChangedetailsarray}
                          handleOnInput={handleOnInput}
                          TypeofCheck={isAlphanumericAllSpecialCharacters}
                          setdetailObj={setdetailObj}
                          ActiveData={FnoActive}
                        ></PQR_FillerMetal_Select>


                        <PQR_FillerMetal_Select
                          detailObj={detailObj}
                          wps_detail={detailObj}
                          ColumnHeader="Filler Metal A-Number"
                          Columndimension=""
                          status={error.PQRD_FM_Ano.status}
                          message={error.PQRD_FM_Ano.message}
                          type="text"
                          name="PQRD_FM_Ano"
                          setError={setError}
                          handleOnInput={handleOnInput}
                          TypeofCheck={isAlphanumericAllSpecialCharacters}
                          handleChangedetailsarray={handleChangedetailsarray}
                          setdetailObj={setdetailObj}
                          ActiveData={AnoActive}
                        ></PQR_FillerMetal_Select>

                        <PQR_FillerMetal_Input
                          wps_detail={detailObj}
                          ColumnHeader="Filler Metal Nominal Composition"
                          Columndimension=""
                          status={error.PQRD_FM_Nominal.status}
                          message={error.PQRD_FM_Nominal.message}
                          type="text"
                          maxLength={24}
                          width={272}
                          name="PQRD_FM_Nominal"
                          setError={setError}
                          handleOnInput={handleOnInput}
                          TypeofCheck={isAlphanumericAllSpecialCharacters}
                          handleChangedetailsarray={handleChangedetailsarray}
                        ></PQR_FillerMetal_Input>

                        <PQR_FillerMetal_Input
                          wps_detail={detailObj}
                          ColumnHeader="Filler Metal Trade Name"
                          Columndimension=""
                          width={272}
                          status={error.PQRD_FM_TradeName.status}
                          message={error.PQRD_FM_TradeName.message}
                          type="text"
                          maxLength={24}
                          name="PQRD_FM_TradeName"
                          setError={setError}
                          handleOnInput={handleOnInput}
                          TypeofCheck={isAlphanumericAllSpecialCharacters}
                          handleChangedetailsarray={handleChangedetailsarray}
                        ></PQR_FillerMetal_Input>
                         
                         <PQR_Process_Grid_Select
                          wps_detail={detailObj}
                          ColumnHeader="Filler Metal Product Form"
                          status={error.PQRD_FM_ProductForm.status}
                          message={error.PQRD_FM_ProductForm.message}
                          name="PQRD_FM_ProductForm"
                          array={FM_Product_Form}
                          handleChangedetailsarray={handleChangedetailsarray}
                        ></PQR_Process_Grid_Select>
                      
                        <WPS_Process_Grid_Input_3
                          wps_detail={detailObj}
                          ColumnHeader="Filler Metal Size"
                          Columndimension="mm"
                          status={error.PQRD_FM_FillerMetalSize.status}
                          message={error.PQRD_FM_FillerMetalSize.message}
                          type="text"
                          maxLength={8}
                          name="PQRD_FM_FillerMetalSize"
                          handleChange={handleChange3field}
                          onInput={(e) => {
                            let error = isAlphanumericAllSpecialCharacters(
                              e.target.value
                            );
                            setError((prevState) => ({
                              ...prevState,
                              PQRD_FM_FillerMetalSize: error,
                            }));
                          }}
                        ></WPS_Process_Grid_Input_3>

                        <PQR_FillerMetal_Input
                          wps_detail={detailObj}
                          ColumnHeader="Deposited Thickness"
                          Columndimension="mm"
                          status={error.PQRD_FM_DepositedThickness.status}
                          message={error.PQRD_FM_DepositedThickness.message}
                          type="text"
                          maxLength={7}
                          width={272}
                          name="PQRD_FM_DepositedThickness"
                          setError={setError}
                          handleOnInput={handleOnInput}
                          TypeofCheck={isAlphanumericAllSpecialCharacters}
                          handleChangedetailsarray={handleChangedetailsarray}
                        ></PQR_FillerMetal_Input>

                        <PQR_FillerMetal_Input
                          wps_detail={detailObj}
                          ColumnHeader="Maximum Pass Thickness"
                          Columndimension="mm"
                          status={error.PQRD_FM_MaxPassThickness.status}
                          message={error.PQRD_FM_MaxPassThickness.message}
                          type="text"
                          maxLength={7}
                          width={272}
                          name="PQRD_FM_MaxPassThickness"
                          setError={setError}
                          handleOnInput={handleOnInput}
                          TypeofCheck={isAlphanumericAllSpecialCharacters}
                          handleChangedetailsarray={handleChangedetailsarray}
                        ></PQR_FillerMetal_Input>
                         {isoverlay ? 
                        <PQR_FillerMetal_Input
                          wps_detail={detailObj}
                          ColumnHeader="Weld Deposit Chemistry"
                          Columndimension=""
                          status={error.PQRD_FM_WeldDepositChemistry.status}
                          message={error.PQRD_FM_WeldDepositChemistry.message}
                          type="text"
                          maxLength={24}
                          width={272}
                          name="PQRD_FM_WeldDepositChemistry"
                          setError={setError}
                          handleOnInput={handleOnInput}
                          TypeofCheck={isAlphanumericAllSpecialCharacters}
                          handleChangedetailsarray={handleChangedetailsarray}
                        ></PQR_FillerMetal_Input>
                        : ""}

                        <PQR_FillerMetal_Input
                          wps_detail={detailObj}
                          ColumnHeader="Flux AWS Specification & Classification"
                          Columndimension=""
                          status={error.PQRD_FM_FluxAWS.status}
                          message={error.PQRD_FM_FluxAWS.message}
                          type="text"
                          maxLength={24}
                          width={272}
                          name="PQRD_FM_FluxAWS"
                          setError={setError}
                          handleOnInput={handleOnInput}
                          TypeofCheck={isAlphanumericAllSpecialCharacters}
                          handleChangedetailsarray={handleChangedetailsarray}
                        ></PQR_FillerMetal_Input>

                        {isoverlay ?
                        <PQR_FillerMetal_Input
                          wps_detail={detailObj}
                          ColumnHeader="Flux Nominal Composition"
                          Columndimension=""
                          status={error.PQRD_FM_FluxNominalComposition.status}
                          message={error.PQRD_FM_FluxNominalComposition.message}
                          type="text"
                          maxLength={24}
                          width={272}
                          name="PQRD_FM_FluxNominalComposition"
                          setError={setError}
                          handleOnInput={handleOnInput}
                          TypeofCheck={isAlphanumericAllSpecialCharacters}
                          handleChangedetailsarray={handleChangedetailsarray}
                        ></PQR_FillerMetal_Input>
                           : ""}

                        <PQR_FillerMetal_Input
                          wps_detail={detailObj}
                          ColumnHeader="Flux Trade Name"
                          Columndimension=""
                          status={error.PQRD_FM_FluxTradeName.status}
                          message={error.PQRD_FM_FluxTradeName.message}
                          type="text"
                          width={272}
                          maxLength={24}
                          name="PQRD_FM_FluxTradeName"
                          setError={setError}
                          handleOnInput={handleOnInput}
                          TypeofCheck={isAlphanumericAllSpecialCharacters}
                          handleChangedetailsarray={handleChangedetailsarray}
                        ></PQR_FillerMetal_Input>

                        <PQR_FillerMetal_Input
                          wps_detail={detailObj}
                          ColumnHeader="Flux Type"
                          Columndimension=""
                          width={272}
                          status={error.PQRD_FM_FluxType.status}
                          message={error.PQRD_FM_FluxType.message}
                          type="text"
                          maxLength={24}
                          name="PQRD_FM_FluxType"
                          setError={setError}
                          handleOnInput={handleOnInput}
                          TypeofCheck={isAlphanumericAllSpecialCharacters}
                          handleChangedetailsarray={handleChangedetailsarray}
                        ></PQR_FillerMetal_Input>

                        <PQR_FillerMetal_Input
                          wps_detail={detailObj}
                          ColumnHeader="Flux From Recrushed Slag"
                          Columndimension=""
                          status={error.PQRD_FM_FluxRecrushed.status}
                          message={error.PQRD_FM_FluxRecrushed.message}
                          type="text"
                          maxLength={24}
                          width={272}
                          name="PQRD_FM_FluxRecrushed"
                          setError={setError}
                          handleOnInput={handleOnInput}
                          TypeofCheck={isAlphanumericAllSpecialCharacters}
                          handleChangedetailsarray={handleChangedetailsarray}
                        ></PQR_FillerMetal_Input>

                        <PQR_FillerMetal_Input
                          wps_detail={detailObj}
                          ColumnHeader="Supplemental Filler Metal & Insert"
                          Columndimension=""
                          status={error.PQRD_FM_Supplement.status}
                          message={error.PQRD_FM_Supplement.message}
                          type="text"
                          maxLength={24}
                          width={272}
                          name="PQRD_FM_Supplement"
                          setError={setError}
                          handleOnInput={handleOnInput}
                          TypeofCheck={isAlphanumericAllSpecialCharacters}
                          handleChangedetailsarray={handleChangedetailsarray}
                        ></PQR_FillerMetal_Input>

                        <PQR_FillerMetal_Input
                          wps_detail={detailObj}
                          ColumnHeader="Filler Metal Others"
                          Columndimension=""
                          status={error.PQRD_FM_FillerMetalOthers.status}
                          message={error.PQRD_FM_FillerMetalOthers.message}
                          type="text"
                          maxLength={24}
                          width={272}
                          name="PQRD_FM_FillerMetalOthers"
                          setError={setError}
                          handleOnInput={handleOnInput}
                          TypeofCheck={isAlphanumericAllSpecialCharacters}
                          handleChangedetailsarray={handleChangedetailsarray}
                        ></PQR_FillerMetal_Input>
                      </tbody>
                    </Table>
                  </Col>
                </div>

                <div className="scrollpqr">
                  <p className="WPS-blue-text ">Position (QW-405) </p>

                  <Col style={{position:"relative", bottom: "8px"}}>
                    <Table
                      bordered
                      responsive="sm"
                      className="text-center same-col-widths align-middle"
                    >
                      <tbody>
                        <WPS_Process_Grid_Input_3
                          wps_detail={detailObj}
                          ColumnHeader="Position of Groove"
                          Columndimension=""
                          status={error.PQRD_Position_PositionofGroove.status}
                          message={error.PQRD_Position_PositionofGroove.message}
                          type="text"
                          maxLength={6}
                          name="PQRD_Position_PositionofGroove"
                          handleChange={handleChange3field}
                          onInput={(e) => {
                            let error = isAlphanumericAllSpecialCharacters(
                              e.target.value
                            );
                            setError((prevState) => ({
                              ...prevState,
                              PQRD_Position_PositionofGroove: error,
                            }));
                          }}
                        ></WPS_Process_Grid_Input_3>

                        <WPS_Process_Grid_Input_3
                          wps_detail={detailObj}
                          ColumnHeader="Weld Progression"
                          Columndimension=""
                          status={error.PQRD_Position_WeldProgression.status}
                          message={error.PQRD_Position_WeldProgression.message}
                          
                          type="text"
                          maxLength={6}
                          name="PQRD_Position_WeldProgression"
                          handleChange={handleChange3field}
                          onInput={(e) => {
                            let error = isAlphanumericAllSpecialCharacters(
                              e.target.value
                            );
                            setError((prevState) => ({
                              ...prevState,
                              PQRD_Position_WeldProgression: error,
                            }));
                          }}
                        ></WPS_Process_Grid_Input_3>
                      </tbody>
                    </Table>
                  </Col>
                </div>
                <span className="WPS-blue-text" style={{padding: "0px 5px"}}>Preheat (QW-406)</span>

                <Col style={{padding: "0px 5px" , position:"relative", bottom: "8px"}} >
                  <Table
                    bordered
                    responsive="sm"
                    className="text-center same-col-widths align-middle"
                  >
                    <tbody>
                      <PQR_FillerMetal_Input
                        wps_detail={detailObj}
                        ColumnHeader="Preheat Temperature"
                        Columndimension="°C"
                        status={error.PQRD_Preheat_MinimumPreheatTemp.status}
                        message={error.PQRD_Preheat_MinimumPreheatTemp.message}
                        type="text"
                        maxLength={25}
                        width={182}
                        name="PQRD_Preheat_MinimumPreheatTemp"
                        handleOnInput={handleOnInput}
                        TypeofCheck={isAlphanumericAllSpecialCharacters}
                        setError={setError}
                        handleChangedetailsarray={handleChangedetailsarray}
                      ></PQR_FillerMetal_Input>

                      <PQR_FillerMetal_Input
                        wps_detail={detailObj}
                        ColumnHeader="Maximum Interpass Temperature"
                        Columndimension="°C"
                        status={error.PQRD_Preheat_MaximumInterpassTemp.status}
                        message={
                          error.PQRD_Preheat_MaximumInterpassTemp.message
                        }
                        type="text"
                        maxLength={25}
                        width={182}
                        name="PQRD_Preheat_MaximumInterpassTemp"
                        handleOnInput={handleOnInput}
                        TypeofCheck={isAlphanumericAllSpecialCharacters}
                        setError={setError}
                        handleChangedetailsarray={handleChangedetailsarray}
                      ></PQR_FillerMetal_Input>
                    </tbody>
                  </Table>
                </Col>

                <span className="WPS-blue-text" style={{padding: "0px 5px"}}>Gas (QW-408)</span>
                <Col style={{padding: "0px 5px" , position:"relative", bottom: "8px"}}>
                  <Table
                    bordered
                    responsive="sm"
                    className="text-center same-col-widths align-middle"
                  >
                    <tbody>
                      <WPS_Process_Grid_Input_2
                        wps_detail={detailObj}
                        mainheading="Shielding"
                        ColumnHeader="Type"
                        ColumnHeader1="Flow Rate"
                        Columndimension="I/min"
                        status={
                          error.PQRD_Gas_ShieldingType.status ||
                          error.PQRD_Gas_ShieldingFlowRate.status
                        }
                        message={
                          error.PQRD_Gas_ShieldingType.message ||
                          error.PQRD_Gas_ShieldingFlowRate.message
                        }
                        type="text"
                        name="PQRD_Gas_ShieldingType"
                        name1="PQRD_Gas_ShieldingFlowRate"
                        maxLength1={18}
                        maxLength2={6}
                        width1={182}
                        thstyle2={{width: "260px"}}
                        onInput={(e) => {
                          let error = isAlphanumericAllSpecialCharacters(e.target.value);
                          setError((prevState) => ({
                            ...prevState,
                            PQRD_Gas_ShieldingType: error,
                          }));
                        }}
                        onInput1={(e) => {
                          let error = isAlphanumericAllSpecialCharacters(e.target.value);
                          setError((prevState) => ({
                            ...prevState,
                            PQRD_Gas_ShieldingFlowRate: error,
                          }));
                        }}
                        handleChangedetailsarray={handleChangedetailsarray}
                      ></WPS_Process_Grid_Input_2>

                    {isoverlay ? "" : <>
                      <WPS_Process_Grid_Input_2
                        wps_detail={detailObj}
                        mainheading="Backing"
                        ColumnHeader="Type"
                        ColumnHeader1="Flow Rate"
                        Columndimension="I/min"
                        thstyle2={{width: "260px"}}
                        status={
                          error.PQRD_Gas_BackingType.status ||
                          error.PQRD_Gas_BackingFlowRate.status
                        }
                        message={
                          error.PQRD_Gas_BackingType.message ||
                          error.PQRD_Gas_BackingFlowRate.message
                        }
                        type="text"
                        width1={182}
                        name="PQRD_Gas_BackingType"
                        name1="PQRD_Gas_BackingFlowRate"
                        maxLength1={18}
                        maxLength2={6}
                        onInput={(e) => {
                          let error = isAlphanumericAllSpecialCharacters(e.target.value);
                          setError((prevState) => ({
                            ...prevState,
                            PQRD_Gas_BackingType: error,
                          }));
                        }}
                        onInput1={(e) => {
                          let error = isAlphanumericAllSpecialCharacters(e.target.value);
                          setError((prevState) => ({
                            ...prevState,
                            PQRD_Gas_BackingFlowRate: error,
                          }));
                        }}
                        handleChangedetailsarray={handleChangedetailsarray}
                      ></WPS_Process_Grid_Input_2>

                      <WPS_Process_Grid_Input_2
                        wps_detail={detailObj}
                        mainheading="Trailing"
                        ColumnHeader="Type"
                        ColumnHeader1="Flow Rate"
                        Columndimension="I/min"
                        width1={182}
                        thstyle2={{width: "260px"}}
                        status={
                          error.PQRD_Gas_TrailingType.status ||
                          error.PQRD_Gas_TrailingFlowRate.status
                        }
                        message={
                          error.PQRD_Gas_TrailingType.message ||
                          error.PQRD_Gas_TrailingFlowRate.message
                        }
                        type="text"
                        name="PQRD_Gas_TrailingType"
                        name1="PQRD_Gas_TrailingFlowRate"
                        maxLength1={18}
                        maxLength2={6}
                        onInput={(e) => {
                          let error = isAlphanumericAllSpecialCharacters(e.target.value);
                          setError((prevState) => ({
                            ...prevState,
                            PQRD_Gas_TrailingType: error,
                          }));
                        }}
                        onInput1={(e) => {
                          let error = isAlphanumericAllSpecialCharacters(e.target.value);
                          setError((prevState) => ({
                            ...prevState,
                            PQRD_Gas_TrailingFlowRate: error,
                          }));
                        }}
                        handleChangedetailsarray={handleChangedetailsarray}
                      ></WPS_Process_Grid_Input_2>
                      </>}
                    </tbody>
                  </Table>
                </Col>

                <div className="scrollpqr">
                  <p className="WPS-blue-text " style={{padding: "0px 5px"}}>Electrical (QW-409) </p>
                  <Col style={{padding: "0px 5px", position:"relative", bottom: "8px"}}>
                    <Table
                      bordered
                      responsive="sm"
                      className="text-center same-col-widths align-middle"
                    >
                      <tbody>
                        <WPS_Process_Grid_Input_3
                        detailObj={detailObj}
                          wps_detail={detailObj}
                          ColumnHeader="Filler Metal Size"
                          Columndimension="mm"
                          status={error.PQRD_Electrical_FillerMetalSize.status}
                          message={
                            error.PQRD_Electrical_FillerMetalSize.message
                          }
                          type="text"
                          maxLength={6}
                          name="PQRD_Electrical_FillerMetalSize"
                          handleChange={handleChange3field}
                          onInput={(e) => {
                            let error = isAlphanumericAllSpecialCharacters(
                              e.target.value
                            );
                            setError((prevState) => ({
                              ...prevState,
                              PQRD_Electrical_FillerMetalSize: error,
                            }));
                          }}
                        ></WPS_Process_Grid_Input_3>

                        <WPS_Process_Grid_Input_3
                          wps_detail={detailObj}
                          ColumnHeader="Amperes"
                          Columndimension="Amps"
                          status={error.PQRD_Electrical_Amperes.status}
                          message={error.PQRD_Electrical_Amperes.message}
                          type="text"
                          maxLength={6}
                          name="PQRD_Electrical_Amperes"
                          handleChange={handleChange3field}
                          onInput={(e) => {
                            let error = isAlphanumericAllSpecialCharacters(
                              e.target.value
                            );
                            setError((prevState) => ({
                              ...prevState,
                              PQRD_Electrical_Amperes: error,
                            }));
                          }}
                        ></WPS_Process_Grid_Input_3>

                        <WPS_Process_Grid_Input_3
                          wps_detail={detailObj}
                          ColumnHeader="Voltage"
                          Columndimension="Volts"
                          status={error.PQRD_Electrical_Volts.status}
                          message={error.PQRD_Electrical_Volts.message}
                          type="text"
                          maxLength={6}
                          name="PQRD_Electrical_Volts"
                          handleChange={handleChange3field}
                          onInput={(e) => {
                            let error = isAlphanumericAllSpecialCharacters(
                              e.target.value
                            );
                            setError((prevState) => ({
                              ...prevState,
                              PQRD_Electrical_Volts: error,
                            }));
                          }}
                        ></WPS_Process_Grid_Input_3>

                        <WPS_Process_Grid_Input_3
                          wps_detail={detailObj}
                          ColumnHeader="Travel Speed"
                          Columndimension="mm/min"
                          status={error.PQRD_Electrical_TravelSpeed.status}
                          message={error.PQRD_Electrical_TravelSpeed.message}
                          type="text"
                          maxLength={6}
                          name="PQRD_Electrical_TravelSpeed"
                          handleChange={handleChange3field}
                          onInput={(e) => {
                            let error = isAlphanumericAllSpecialCharacters(
                              e.target.value
                            );
                            setError((prevState) => ({
                              ...prevState,
                              PQRD_Electrical_TravelSpeed: error,
                            }));
                          }}
                        ></WPS_Process_Grid_Input_3>
                        <WPS_Process_Grid_Input_3
                          wps_detail={detailObj}
                          ColumnHeader="Minimum bead Length (SMAW)"
                          Columndimension="mm"
                          status={error.PQRD_Electrical_minBeadLength.status}
                          message={error.PQRD_Electrical_minBeadLength.message}
                          type="text"
                          maxLength={6}
                          name="PQRD_Electrical_minBeadLength"
                          handleChange={handleChange3field}
                          onInput={(e) => {
                            let error = isAlphanumericAllSpecialCharacters(
                              e.target.value
                            );
                            setError((prevState) => ({
                              ...prevState,
                              PQRD_Electrical_minBeadLength: error,
                            }));
                          }}
                        ></WPS_Process_Grid_Input_3>
                        <WPS_Process_Grid_Input_3
                          wps_detail={detailObj}
                          ColumnHeader="Arc Transfer Mode (FCAW/GMAW)"
                          status={error.PQRD_Electrical_ArcTransferMode.status}
                          message={error.PQRD_Electrical_ArcTransferMode.message}
                          type="text"
                          maxLength={6}
                          name="PQRD_Electrical_ArcTransferMode"
                          handleChange={handleChange3field}
                          onInput={(e) => {
                            let error = isAlphanumericAllSpecialCharacters(
                              e.target.value
                            );
                            setError((prevState) => ({
                              ...prevState,
                              PQRD_Electrical_ArcTransferMode: error,
                            }));
                          }}
                        ></WPS_Process_Grid_Input_3>

                        <WPS_Process_Grid_Input_3
                          wps_detail={detailObj}
                          ColumnHeader="Maximum Heat Input"
                          Columndimension="kj/mm"
                          status={error.PQRD_Electrical_MaxHeatInput.status}
                          message={error.PQRD_Electrical_MaxHeatInput.message}
                          type="text"
                          maxLength={6}
                          name="PQRD_Electrical_MaxHeatInput"
                          handleChange={handleChange3field}
                          onInput={(e) => {
                            let error = isAlphanumericAllSpecialCharacters(
                              e.target.value
                            );
                            setError((prevState) => ({
                              ...prevState,
                              PQRD_Electrical_MaxHeatInput: error,
                            }));
                          }}
                        ></WPS_Process_Grid_Input_3>

                        <WPS_Process_Grid_Input_3
                          wps_detail={detailObj}
                          ColumnHeader="Tungsten Type & Size"
                          Columndimension=""
                          status={error.PQRD_Electrical_TungstenType.status}
                          message={error.PQRD_Electrical_TungstenType.message}
                          type="text"
                          maxLength={6}
                          name="PQRD_Electrical_TungstenType"
                          handleChange={handleChange3field}
                          onInput={(e) => {
                            let error = isAlphanumericAllSpecialCharacters(
                              e.target.value
                            );
                            setError((prevState) => ({
                              ...prevState,
                              PQRD_Electrical_TungstenType: error,
                            }));
                          }}
                        ></WPS_Process_Grid_Input_3>

                        <WPS_Process_Grid_Input_3
                          wps_detail={detailObj}
                          ColumnHeader="Current/Polarity"
                          Columndimension=""
                          status={error.PQRD_Electrical_CurrentPolarity.status}
                          message={
                            error.PQRD_Electrical_CurrentPolarity.message
                          }
                          type="text"
                          maxLength={6}
                          name="PQRD_Electrical_CurrentPolarity"
                          handleChange={handleChange3field}
                          onInput={(e) => {
                            let error = isAlphanumericAllSpecialCharacters(
                              e.target.value
                            );
                            setError((prevState) => ({
                              ...prevState,
                              PQRD_Electrical_CurrentPolarity: error,
                            }));
                          }}
                        ></WPS_Process_Grid_Input_3>

                        <WPS_Process_Grid_Input_3
                          wps_detail={detailObj}
                          ColumnHeader="DC Pulsating Current"
                          Columndimension=""
                          status={
                            error.PQRD_Electrical_DCPulsatingCurrent.status
                          }
                          message={
                            error.PQRD_Electrical_DCPulsatingCurrent.message
                          }
                          type="text"
                          maxLength={6}
                          name="PQRD_Electrical_DCPulsatingCurrent"
                          handleChange={handleChange3field}
                          onInput={(e) => {
                            let error = isAlphanumericAllSpecialCharacters(
                              e.target.value
                            );
                            setError((prevState) => ({
                              ...prevState,
                              PQRD_Electrical_DCPulsatingCurrent: error,
                            }));
                          }}
                        ></WPS_Process_Grid_Input_3>
                        {isoverlay ? (
                          <WPS_Process_Grid_Input_3
                            wps_detail={detailObj}
                            ColumnHeader="Wire Feed Type"
                            Columndimension=""
                            status={error.PQRD_Electrical_WireFeedType.status}
                            message={error.PQRD_Electrical_WireFeedType.message}
                            type="text"
                            maxLength={6}
                            name="PQRD_Electrical_WireFeedType"
                            handleChange={handleChange3field}
                            onInput={(e) => {
                              let error = isAlphanumericAllSpecialCharacters(
                                e.target.value
                              );
                              setError((prevState) => ({
                                ...prevState,
                                PQRD_Electrical_WireFeedType: error,
                              }));
                            }}
                          ></WPS_Process_Grid_Input_3>
                        ) : (
                          ""
                        )}

                        {isoverlay ? (
                          <WPS_Process_Grid_Input_3
                            wps_detail={detailObj}
                            ColumnHeader="Wire Feed Speed"
                            Columndimension=""
                            status={error.PQRD_Electrical_WireFeedSpeed.status}
                            message={
                              error.PQRD_Electrical_WireFeedSpeed.message
                            }
                            type="text"
                            maxLength={6}
                            name="PQRD_Electrical_WireFeedSpeed"
                            handleChange={handleChange3field}
                            onInput={(e) => {
                              let error = isAlphanumericAllSpecialCharacters(
                                e.target.value
                              );
                              setError((prevState) => ({
                                ...prevState,
                                PQRD_Electrical_WireFeedSpeed: error,
                              }));
                            }}
                          ></WPS_Process_Grid_Input_3>
                        ) : (
                          ""
                        )}
                      </tbody>
                    </Table>
                  </Col>{" "}
                </div>

                <span>
                  {" "}
                  <p className="WPS-blue-text ">Technique (QW-410) </p>
                </span>

                {isoverlay ? (
                  <Col style={{position:"relative", bottom: "8px"}}>
                    <Table
                      bordered
                      responsive="sm"
                      className="text-center same-col-widths align-middle"
                    >
                      <tbody>
                        <PQR_FillerMetal_Input
                        detailObj={detailObj}
                          wps_detail={detailObj}
                          ColumnHeader="Oscillation"
                          Columndimension=""
                          status={error.PQRD_Technique_Oscillation.status}
                          message={error.PQRD_Technique_Oscillation.message}
                          type="text"
                          maxLength={25}
                          width={200}
                          name="PQRD_Technique_Oscillation"
                          setError={setError}
                          handleOnInput={handleOnInput}
                          TypeofCheck={isAlphanumericAllSpecialCharacters}
                          handleChangedetailsarray={handleChangedetailsarray}
                        ></PQR_FillerMetal_Input>


                        <PQR_FillerMetal_Input
                          wps_detail={detailObj}
                          ColumnHeader="No. of electrodes on same Puddle"
                          Columndimension=""
                          status={
                            error.PQRD_Technique_ElectrodesSamePuddle.status
                          }
                          message={
                            error.PQRD_Technique_ElectrodesSamePuddle.message
                          }
                          type="text"
                          maxLength={25}
                          width={200}
                          name="PQRD_Technique_ElectrodesSamePuddle"
                          setError={setError}
                          handleOnInput={handleOnInput}
                          TypeofCheck={isAlphanumericAllSpecialCharacters}
                          handleChangedetailsarray={handleChangedetailsarray}
                        ></PQR_FillerMetal_Input>

                        <PQR_FillerMetal_Input
                          wps_detail={detailObj}
                          ColumnHeader="Multiple or Single Layer"
                          Columndimension=""
                          status={
                            error.PQRD_Technique_Multiple_SingleLayer.status
                          }
                          message={
                            error.PQRD_Technique_Multiple_SingleLayer.message
                          }
                          type="text"
                          maxLength={25}
                          width={200}
                          name="PQRD_Technique_Multiple_SingleLayer"
                          setError={setError}
                          handleOnInput={handleOnInput}
                          TypeofCheck={isAlphanumericAllSpecialCharacters}
                          handleChangedetailsarray={handleChangedetailsarray}
                        ></PQR_FillerMetal_Input>

                        <PQR_FillerMetal_Input
                          wps_detail={detailObj}
                          ColumnHeader="Supplimental Device"
                          Columndimension=""
                          status={
                            error.PQRD_Technique_SupplimentalDevice.status
                          }
                          message={
                            error.PQRD_Technique_SupplimentalDevice.message
                          }
                          type="text"
                          maxLength={25}
                          width={200}
                          name="PQRD_Technique_SupplimentalDevice"
                          setError={setError}
                          handleOnInput={handleOnInput}
                          TypeofCheck={isAlphanumericAllSpecialCharacters}
                          handleChangedetailsarray={handleChangedetailsarray}
                        ></PQR_FillerMetal_Input>

                        <PQR_FillerMetal_Input
                          wps_detail={detailObj}
                          ColumnHeader="Initial/Interpass Cleaning"
                          Columndimension=""
                          status={
                            error.PQRD_Technique_InitialInterpassCleaning.status
                          }
                          message={
                            error.PQRD_Technique_InitialInterpassCleaning
                              .message
                          }
                          type="text"
                          maxLength={25}
                          width={200}
                          name="PQRD_Technique_InitialInterpassCleaning"
                          setError={setError}
                          handleOnInput={handleOnInput}
                          TypeofCheck={isAlphanumericAllSpecialCharacters}
                          handleChangedetailsarray={handleChangedetailsarray}
                        ></PQR_FillerMetal_Input>

                        <PQR_FillerMetal_Input
                          wps_detail={detailObj}
                          ColumnHeader="Overlaping"
                          Columndimension=""
                          status={error.PQRD_Technique_Overlaping.status}
                          message={error.PQRD_Technique_Overlaping.message}
                          type="text"
                          maxLength={25}
                          width={200}
                          name="PQRD_Technique_Overlaping"
                          setError={setError}
                          handleOnInput={handleOnInput}
                          TypeofCheck={isAlphanumericAllSpecialCharacters}
                          handleChangedetailsarray={handleChangedetailsarray}
                        ></PQR_FillerMetal_Input>

                        <PQR_FillerMetal_Input
                          wps_detail={detailObj}
                          ColumnHeader="C.T.W.D."
                          Columndimension="mm"
                          status={error.PQRD_Technique_CTWD.status}
                          message={error.PQRD_Technique_CTWD.message}
                          type="text"
                          maxLength={25}
                          width={200}
                          name="PQRD_Technique_CTWD"
                          setError={setError}
                          handleOnInput={handleOnInput}
                          TypeofCheck={isAlphanumericAllSpecialCharacters}
                          handleChangedetailsarray={handleChangedetailsarray}
                        ></PQR_FillerMetal_Input>
                      </tbody>
                    </Table>
                  </Col>
                ) : (
                  <Col style={{position:"relative", bottom: "8px"}}>
                    <Table
                      bordered
                      responsive="sm"
                      className="text-center same-col-widths align-middle"
                    >
                      <tbody>
                        <PQR_FillerMetal_Input
                          wps_detail={detailObj}
                          ColumnHeader="String Or Weave"
                          Columndimension=""
                          status={error.PQRD_Technique_StringOrWeave.status}
                          message={error.PQRD_Technique_StringOrWeave.message}
                          type="text"
                          maxLength={25}
                          width={200}
                          name="PQRD_Technique_StringOrWeave"
                          setError={setError}
                          handleOnInput={handleOnInput}
                          TypeofCheck={isAlphanumericAllSpecialCharacters}
                          handleChangedetailsarray={handleChangedetailsarray}
                          onBlur={(e) => {
                            let error = isAlphanumericAllSpecialCharacters(e.target.value);
                            setError((prevState) => ({
                              ...prevState,
                              PQRD_Technique_StringOrWeave: error,
                            }));
                          }}
                        ></PQR_FillerMetal_Input>

                        <PQR_FillerMetal_Input
                          wps_detail={detailObj}
                          ColumnHeader="Orifice Gas/Cup Size"
                          Columndimension=""
                          status={
                            error.PQRD_Technique_Orifice_GasCupSize.status
                          }
                          message={
                            error.PQRD_Technique_Orifice_GasCupSize.message
                          }
                          type="text"
                          maxLength={25}
                          width={200}
                          name="PQRD_Technique_Orifice_GasCupSize"
                          setError={setError}
                          handleOnInput={handleOnInput}
                          TypeofCheck={isAlphanumericAllSpecialCharacters}
                          handleChangedetailsarray={handleChangedetailsarray}
                        ></PQR_FillerMetal_Input>

                        <PQR_FillerMetal_Input
                          wps_detail={detailObj}
                          ColumnHeader="C.T.W.D."
                          Columndimension="mm"
                          status={error.PQRD_Technique_CTWD.status}
                          message={error.PQRD_Technique_CTWD.message}
                          type="text"
                          maxLength={25}
                          width={200}
                          name="PQRD_Technique_CTWD"
                          setError={setError}
                          handleOnInput={handleOnInput}
                          TypeofCheck={isAlphanumericAllSpecialCharacters}
                          handleChangedetailsarray={handleChangedetailsarray}
                        ></PQR_FillerMetal_Input>

                        <PQR_FillerMetal_Input
                          wps_detail={detailObj}
                          ColumnHeader="Oscillation"
                          Columndimension=""
                          status={error.PQRD_Technique_Oscillation.status}
                          message={error.PQRD_Technique_Oscillation.message}
                          type="text"
                          maxLength={25}
                          width={200}
                          name="PQRD_Technique_Oscillation"
                          setError={setError}
                          handleOnInput={handleOnInput}
                          TypeofCheck={isAlphanumericAllSpecialCharacters}
                          handleChangedetailsarray={handleChangedetailsarray}
                        ></PQR_FillerMetal_Input>

                        <PQR_FillerMetal_Input
                          wps_detail={detailObj}
                          ColumnHeader="Multi/Single Electrode"
                          Columndimension=""
                          status={
                            error.PQRD_Technique_Multi_SingleElectrode.status
                          }
                          message={
                            error.PQRD_Technique_Multi_SingleElectrode.message
                          }
                          type="text"
                          maxLength={25}
                          width={200}
                          name="PQRD_Technique_Multi_SingleElectrode"
                          setError={setError}
                          handleOnInput={handleOnInput}
                          TypeofCheck={isAlphanumericAllSpecialCharacters}
                          handleChangedetailsarray={handleChangedetailsarray}
                        ></PQR_FillerMetal_Input>

                        <PQR_FillerMetal_Input
                          wps_detail={detailObj}
                          ColumnHeader="Electrode Angle"
                          Columndimension="deg."
                          status={error.PQRD_Technique_ElectrodeAngle.status}
                          message={error.PQRD_Technique_ElectrodeAngle.message}
                          type="text"
                          maxLength={25}
                          width={200}
                          name="PQRD_Technique_ElectrodeAngle"
                          setError={setError}
                          handleOnInput={handleOnInput}
                          TypeofCheck={isAlphanumericAllSpecialCharacters}
                          handleChangedetailsarray={handleChangedetailsarray}
                        ></PQR_FillerMetal_Input>

                        <PQR_FillerMetal_Input
                          wps_detail={detailObj}
                          ColumnHeader="Multi/Single Pass Per Side"
                          Columndimension=""
                          status={error.PQRD_Technique_SinglePassPerSide.status}
                          message={
                            error.PQRD_Technique_SinglePassPerSide.message
                          }
                          type="text"
                          maxLength={25}
                          width={200}
                          name="PQRD_Technique_SinglePassPerSide"
                          setError={setError}
                          handleOnInput={handleOnInput}
                          TypeofCheck={isAlphanumericAllSpecialCharacters}
                          handleChangedetailsarray={handleChangedetailsarray}
                        ></PQR_FillerMetal_Input>

                        <PQR_FillerMetal_Input
                          wps_detail={detailObj}
                          ColumnHeader="Multiple or Single Layer"
                          Columndimension=""
                          status={
                            error.PQRD_Technique_Multiple_SingleLayer.status
                          }
                          message={
                            error.PQRD_Technique_Multiple_SingleLayer.message
                          }
                          type="text"
                          maxLength={25}
                          width={200}
                          name="PQRD_Technique_Multiple_SingleLayer"
                          setError={setError}
                          handleOnInput={handleOnInput}
                          TypeofCheck={isAlphanumericAllSpecialCharacters}
                          handleChangedetailsarray={handleChangedetailsarray}
                        ></PQR_FillerMetal_Input>

                        <PQR_FillerMetal_Input
                          wps_detail={detailObj}
                          ColumnHeader="Peening"
                          Columndimension=""
                          status={error.PQRD_Technique_Peening.status}
                          message={error.PQRD_Technique_Peening.message}
                          type="text"
                          maxLength={25}
                          width={200}
                          name="PQRD_Technique_Peening"
                          setError={setError}
                          handleOnInput={handleOnInput}
                          TypeofCheck={isAlphanumericAllSpecialCharacters}
                          handleChangedetailsarray={handleChangedetailsarray}
                        ></PQR_FillerMetal_Input>

                        <PQR_FillerMetal_Input
                          wps_detail={detailObj}
                          ColumnHeader="Initial/Interpass Cleaning"
                          Columndimension=""
                          status={
                            error.PQRD_Technique_InitialInterpassCleaning.status
                          }
                          message={
                            error.PQRD_Technique_InitialInterpassCleaning
                              .message
                          }
                          type="text"
                          maxLength={25}
                          width={200}
                          name="PQRD_Technique_InitialInterpassCleaning"
                          setError={setError}
                          handleOnInput={handleOnInput}
                          TypeofCheck={isAlphanumericAllSpecialCharacters}
                          handleChangedetailsarray={handleChangedetailsarray}
                        ></PQR_FillerMetal_Input>

                        <PQR_FillerMetal_Input
                          wps_detail={detailObj}
                          ColumnHeader="Back Gauging Method"
                          Columndimension=""
                          status={error.PQRD_Technique_BackGaugingMethod.status}
                          message={
                            error.PQRD_Technique_BackGaugingMethod.message
                          }
                          type="text"
                          maxLength={25}
                          width={200}
                          name="PQRD_Technique_BackGaugingMethod"
                          setError={setError}
                          handleOnInput={handleOnInput}
                          TypeofCheck={isAlphanumericAllSpecialCharacters}
                          handleChangedetailsarray={handleChangedetailsarray}
                        ></PQR_FillerMetal_Input>
                         <PQR_FillerMetal_Input
                          wps_detail={detailObj}
                          ColumnHeader="Technique Others"
                          Columndimension=""
                          status={
                            error.PQRD_Technique_Technique_Other.status
                          }
                          message={
                            error.PQRD_Technique_Technique_Other
                              .message
                          }
                          type="text"
                          maxLength={25}
                          width={200}
                          name="PQRD_Technique_Technique_Other"
                          setError={setError}
                          handleOnInput={handleOnInput}
                          TypeofCheck={isAlphanumericAllSpecialCharacters}
                          handleChangedetailsarray={handleChangedetailsarray}
                        ></PQR_FillerMetal_Input>
                        <PQR_FillerMetal_Input
                          wps_detail={detailObj}
                          ColumnHeader="Groove Preparation"
                          Columndimension=""
                          status={error.PQRD_Technique_GroovePreparation.status}
                          message={
                            error.PQRD_Technique_GroovePreparation.message
                          }
                          type="text"
                          maxLength={25}
                          width={200}
                          name="PQRD_Technique_GroovePreparation"
                          setError={setError}
                          handleOnInput={handleOnInput}
                          TypeofCheck={isAlphanumericAllSpecialCharacters}
                          handleChangedetailsarray={handleChangedetailsarray}
                        ></PQR_FillerMetal_Input>
                      </tbody>
                    </Table>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Row>
      </Row>
    );
  }
);

export default PQR_Page1;
