import React, {
    useState,
    forwardRef,
    useEffect,
  } from "react";
 
  import { Row, Col, Container, Form, Button } from "react-bootstrap";

  import {ToastContainer, toast } from "react-toastify";
  import "react-toastify/dist/ReactToastify.css";
 
import { getwiwelders } from "../../Services/WI-services";
import { todayDate } from "../utility/CommonComponents/TodayDate";

import { SearchSelectWelder, SearchSelectWIProject } from "../utility/search-select";
import { getAllOnlyProject } from "../../Services/project-services";

import { Date_Input, Date_InputDisabled, tableHeader } from "./Excel_Headers";
import WCR_PDF from "./WCR_PDF";
import { getAllRecordsWeldingProcess } from "../../Services/weldingprocess-services";
  
  const Welder_Continuity_Record = forwardRef((props, ref) => {
    let formName = "WI Slip";
    const [rowData, setRowData] = useState([]);
    const [widata, setwidata] = useState(null);
    const [wpsde, setwpsde] = useState({});
    const [reload, setReload] = useState(false);
    const [getreport, setgetreport] = useState([]);
    const [ProjectDetails, setProjectDetails] = useState([]);
    const [showForm, setShowForm] = useState(false);

    const [pcategories, setPcategories] = useState(null);
    const [filteredPcategories, setFilteredPcategories] = useState(null);


    // const [loader, setloader] = useState(false);

    const [normalstyle, setNormal] = useState({
      control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? "grey" : "grey",
      }),
    });


    let Data = []



    const [datevalue, setdatevalue] = useState(false);

    const [wiobj, setwiobj] = useState({
      WI_Project_No:"",
      WI_Joint_No:"",
      WI_Welder_Id:"",
      WI_Welder_Name:"",
      WI_Process:"",
      WI_ProcessCategory:"",
      WI_From_Date:"",
      WI_To_Date:"",
      WI_Slip_Status:"",
      WPS_Rev_no:"",
      WI_FileFormat:"",
      WI_From_Date:"",
      WI_To_Date:"",
      WI_Last_Weld: false,
      WI_SlipDate:"",
      
      
    });

    // const [isChecked, setIsChecked] = useState(false);
    const [check, setCheck] = useState(false);


    const [error, setError] = useState({
      WI_Project_No:{},
      WI_Joint_No:{},
      WI_Welder_Id:{},
      WI_From_Date:{},
      WI_To_Date:{},
      WI_Slip_Status:{},

    });

    console.log("wiobj",wiobj)

    console.log("errroorrr",error)


    var Slipstatus = [
      { value: "Created", name: "Created" },
      { value: "Cancelled", name: "Cancelled" },
      { value: "Deleted", name: "Deleted" },
    ];

    var fileformat = [
      { value: "Excel", name: "Excel" },
      { value: "PDF", name: "PDF" },
    ];


    const fetchProject = async () => {  
      var result = await getAllOnlyProject();
      setProjectDetails(result);
    };

    console.log("allpro",ProjectDetails)

    const fetchwidata = async () => {
      
      const result = await getwiwelders();
      console.log("reeeeeew",result)
    setwidata(result);
    };


    console.log("ProjectDetails",ProjectDetails)
    console.log("WIData",widata)


    useEffect(() => {
      fetchProject()    
    }, []);
  

    useEffect(() => {
      fetchwidata();
    }, [reload]);

    useEffect(() => {
      if (reload) {
        setReload(false);
      }
    }, [reload]);


    const uniqueProjectNumbers = [...new Set(widata && widata.map((item) => item.WI_Project_No))];
   
    const getJointNosForSelectedProject = () => {
      if (wiobj.WI_Project_No && widata && widata.length > 0) {
        const selectedProjects = widata.filter(item => item.WI_Project_No === wiobj.WI_Project_No);
        const uniqueJointNos = new Set(selectedProjects.map(project => project.WI_Joint_No).filter(jointNo => jointNo));
    
        if (uniqueJointNos.size > 0) {
          return Array.from(uniqueJointNos).map(jointNo => ({
            label: jointNo,
            value: jointNo,
          }));
        }
      }
      return [];
    };
    
    const getWelderForSelectedProject = () => {
      if (wiobj.WI_Project_No && wiobj.WI_Joint_No && widata && widata.length > 0) {
        const selectedWelders = widata
          .filter(item => item.WI_Project_No === wiobj.WI_Project_No && item.WI_Joint_No === wiobj.WI_Joint_No)
          .map(item => item.WI_Welder_Id)
          .filter(welderId => welderId); 
    
        if (selectedWelders.length > 0) {
          const uniqueWelders = [...new Set(selectedWelders)]; 
    
          return uniqueWelders.map(welderId => ({
            label: welderId,
            value: welderId,
          }));
        }
      }
    
      return [];
    }; 

    // const activewelder = widata?.filter(item => item.WI_Slip_Status === "Created");

    // console.log("dsaa",activewelder)

    // const filteredData = activewelder?.filter((entry, index, array) => {
    //   return (
    //     array.findIndex(
    //       (e) =>
    //         e.WI_Welder_Id === entry.WI_Welder_Id &&
    //         e.WI_Welder_Name === entry.WI_Welder_Name
    //     ) === index
    //   );
    // });
    
    // console.log("fffffffffd",filteredData)

    // const filteredDataProcess = activewelder?.filter((entry, index, array) => {
    //   return (
    //     array.findIndex(
    //       (e) =>
    //         e.WI_Process === entry.WI_Process &&
    //         e.WI_ProcessCategory === entry.WI_ProcessCategory
    //     ) === index
    //   );
    // });
    
    // console.log("fffffffffdpp",filteredDataProcess)



    const activewelder = widata?.filter(item => item.WI_Slip_Status === "Created");

    console.log("dsaa", activewelder);
    
    const selectedWelderId = wiobj.WI_Welder_Id; // Replace with the actual selected welder ID
    
    const filteredData = activewelder?.filter((entry, index, array) => {
      return (
        array.findIndex(
          (e) =>
            e.WI_Welder_Id === entry.WI_Welder_Id &&
            e.WI_Welder_Name === entry.WI_Welder_Name
        ) === index
      );
    });
    
    console.log("fffffffffd", filteredData);

    const removeDuplicates = (keyFn, array) => {
      var mySet = new Set();
      return array.filter(function(x) {
          var key = keyFn(x), isNew = !mySet.has(key);
          if (isNew) mySet.add(key);
          return isNew;
      });
    }

    const fetchWeldingProcess = async () => {
      const result = await getAllRecordsWeldingProcess();
      setFilteredPcategories(removeDuplicates(x => x.wld_p_category, result))
      setPcategories(result);
    };

    useEffect(() => {
      fetchWeldingProcess();
    }, []);
    
    const filteredDataProcess = activewelder
      ?.filter((entry) => entry.WI_Welder_Id === selectedWelderId)
      .filter((entry, index, array) => {
        return (
          array.findIndex(
            (e) =>
              e.WI_Process === entry.WI_Process &&
              e.WI_ProcessCategory === entry.WI_ProcessCategory
          ) === index
        );
      });
    
    console.log("filteredDataProcess", filteredDataProcess);
    



    const validate = async (e) => {
      
    var isError = false;

if (wiobj.WI_From_Date !== "" && wiobj.WI_To_Date !== "")
{
      if(wiobj.WI_From_Date > wiobj.WI_To_Date){
        console.log("datee",wiobj.WI_From_Date > wiobj.WI_To_Date)

        isError = true;
        error.WI_From_Date = {
          status: true,
          // message:"Selected date cannot be less than From date"
        };
        error.WI_To_Date = {
          status: true,
          // message:"Selected date cannot be less than From date"
        };
        toast.warning("ToDate Should be Greater than or Equal to FromDate", {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        });
      }
    }
  
    return isError;

    }

      const handleSubmit = async (e) => {
        

        setShowForm(true);
        

  };


  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setwiobj((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };

  const handleChange = () => {
    // setIsChecked((prev) => !prev);

    const newWiobj = {
      ...wiobj,
      WI_Last_Weld: !wiobj.WI_Last_Weld // Toggle the value
    };

    setwiobj(newWiobj);
  };

  // const handleChange = () => {
  //   console.log('Before:', wiobj.WI_Last_Weld);
  //   setIsChecked((prev) => !prev);
  //   setwiobj((prev) => ({
  //     ...prev,
  //     WI_Last_Weld: prev.WI_Last_Weld === 'Checked' ? 'Checked' : '',
  //   }));
  //   console.log('After:', wiobj.WI_Last_Weld);
  // };
  
  const handleCheckboxClick = () => {
    setwiobj(prevState => ({
      ...prevState,
      WI_Last_Weld: !prevState.WI_Last_Weld,
      WI_From_Date: "",

    }));

setdatevalue(false)

    setShowForm(false);
  };


  console.log("reeport",getreport)


  console.log("pdprprprpr",pcategories)

      
    return (
      <>
      <Container fluid>
        <Row className="RowHeight mb-4 ml-2">
          <Col md={12} sm={12} xs={12} className="ListTitle">
          Welder Continuity Record Filters
          </Col>
        </Row>
        <div className="px-2 pb-2">
        <Row className="gx-3" style={{ marginBottom: "2rem" }}>
          {/* <Col md={3}>
            <Form.Group className="ListW-input-select">
              <div className="search-select">
                <SearchSelectWIProject
                  // className="inspdrop"
                  id="WI_Project_No"
                  name="WI_Project_No"
                  className={""}
                  stylegreen={normalstyle}
                  data={uniqueProjectNumbers &&uniqueProjectNumbers.map(projectNo => ({ WI_Project_No: projectNo }))} 
                  stylenormal={normalstyle}
                  valueField={"WI_Project_No"}
                  value={wiobj?.WI_Project_No}
                  labelField={"WI_Project_No"}
                  placeholder=""
                  onChange={(ProjectList) => {
                    setwiobj((prev) => ({
                      ...prev,
                      WI_Project_No: ProjectList.value,
                      WI_Joint_No:"",
                      WI_Welder_Id:"",
                      WI_From_Date:"",
                      WI_To_Date:"",
                      WI_Slip_Status:"",
                      WI_FileFormat:"",
                    }));
                  }}
                 
                ></SearchSelectWIProject>
              </div>
              <label>Welder Code</label>
             
            </Form.Group>
          </Col> */}

                <Col md={3}>
            <Form.Group className="ListW-input-select">
              <div className="search-select">
                <SearchSelectWelder
                  id="WI_Welder_Id"
                  name="WI_Welder_Id"
                  className={""}
                  stylegreen={normalstyle}
                  data={filteredData}
                  stylenormal={normalstyle}
                  valueField={"WI_Welder_Id"}
                  value={wiobj?.WI_Welder_Id}
                  labelField1={"WI_Welder_Id"}
                  labelField2={"WI_Welder_Name"}
                  placeholder=""
                  onChange={(ProjectList) => {
                    let a = filteredData.filter((b) => {
                      return b.WI_Welder_Id === ProjectList.value;
                    });
                    console.log("addddswsd", a);
                    setwiobj((prev) => ({
                      ...prev,
                      WI_Welder_Id: ProjectList.value,
                      WI_Welder_Name: a[0].WI_Welder_Name,
                      WI_Process:""
                    }));
                    setShowForm(false);


                  }}
                 
                ></SearchSelectWelder>
              </div>
              <label>Welder Code</label>
             
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group className="ListW-input-select">
              <div className="search-select">
                <SearchSelectWelder
                  // className="inspdrop"
                  id="WI_ProcessCategory"
                  name="WI_ProcessCategory"
                  className={""}
                  stylegreen={normalstyle}
                  data={filteredPcategories}
                  stylenormal={normalstyle}
                  valueField={"wld_p_category"}
                  value={wiobj?.WI_ProcessCategory}
                  labelField={"wld_p_category"}
                  placeholder=""
                  onChange={(ProjectList) => {
                    let a = pcategories.filter((b) => {
                      return b.wld_p_category === ProjectList.value;
                    });
                    console.log("addddswsd", a);
                  //  if(a.length > 0)
                    // let a_arr = []
                    // a.forEach(elem => a_arr.push(elem?.wld_code))
                    setwiobj((prev) => ({
                      ...prev,
                      WI_Process: "",
                      WI_ProcessCategory: ProjectList.value 
                    }));
                    setShowForm(false);

                  }}
                 
                ></SearchSelectWelder>
              </div>
              <label>Process</label>
             
            </Form.Group>
          </Col>

{wiobj.WI_Last_Weld === false ?
            <Col md={3}>
            <Date_Input 
            divClass="Date-input w-100"
            label="Slip Creation Date From"
            className="groove_datecolor"
            name="WI_From_Date"
            sx={{ fontSize: "6px", fontFamily: "Work Sans", zIndex:"0" }}
            todayDate={todayDate}
            obj={wiobj}
            setobj={setwiobj}
            datevalue={datevalue}
            setdatevalue={setdatevalue}
            todate={wiobj.WI_To_Date}
            setError={setError}
            status={error.WI_From_Date.status}
            message={error.WI_From_Date.message}
            form ={setShowForm}

            />
            </Col>

            : 
            <Col md={3}>
            <Date_InputDisabled 
            divClass="Date-input w-100"
            label="Slip Creation Date From"
            className="groove_datecolor"
            name="WI_From_Date"
            sx={{ fontSize: "6px", fontFamily: "Work Sans", zIndex:"0" }}
            todayDate={todayDate}
            obj={wiobj}
            setobj={setwiobj}
            datevalue={datevalue}
            setdatevalue={setdatevalue}
            todate={wiobj.WI_To_Date}
            setError={setError}
            status={error.WI_From_Date.status}
            message={error.WI_From_Date.message}
            form ={setShowForm}

            />
            </Col>}


            {wiobj.WI_Last_Weld === false ?


            <Col md={3}>
            <Date_Input 
            divClass="Date-input w-100"
            label="Slip Creation Date To"
            className="groove_datecolor"
            name="WI_To_Date"
            sx={{ fontSize: "6px", fontFamily: "Work Sans", zIndex:"0", width: "300px",}}
            todayDate={todayDate}
            obj={wiobj}
            WI_Last_Weld={wiobj.WI_Last_Weld}
            fromdate={wiobj.WI_From_Date}
            setobj={setwiobj}
            setdatevalue={setdatevalue}
            datevalue={datevalue}
            setError={setError}
            status={error.WI_To_Date.status}
            message={error.WI_To_Date.message}
            form ={setShowForm}
            />
            </Col>

: 
<Col md={3}>
<Date_InputDisabled 
 divClass="Date-input w-100"
 label="Slip Creation Date To"
 className="groove_datecolor"
 name="WI_To_Date"
 sx={{ fontSize: "6px", fontFamily: "Work Sans", zIndex:"0", width: "300px",}}
 todayDate={todayDate}
 obj={wiobj}
 WI_Last_Weld={wiobj.WI_Last_Weld}
 fromdate={wiobj.WI_From_Date}
 setobj={setwiobj}
 setdatevalue={setdatevalue}
 datevalue={datevalue}
 setError={setError}
 status={error.WI_To_Date.status}
 message={error.WI_To_Date.message}
 form ={setShowForm}

/>
</Col>}

            <Col md={3}>

            <div>
            <span>
             Last Weld Date
           </span>
           &nbsp;&nbsp;
           <input
      type="checkbox"
      name="WI_Last_Weld"
      className="checkboxproject"
      onClick={()=>{handleCheckboxClick()
        setwiobj(prevState => ({
          ...prevState,
          WI_From_Date: "",
        }));
      }}
      style={{ position: "relative", top: "2px" }}
      checked={wiobj.WI_Last_Weld}
    />          
      </div>
        </Col>

          </Row>
              </div>
              <Row>
            <Col>
              <Button onClick={handleSubmit} className="btn-add-color semiBoldToggleButton rightAlignedButton" >
                Download
              </Button>
            </Col>
          </Row>

          {/* {showForm && (
        <WCR_Report
          show={showForm}
          // action={action}
          rowdata={wiobj}
          onHide={() => setShowForm(false)}
          // fetchUserMaster={fetchGroove}
          setReload={setReload}
          reload={reload}
          // userObj={userObj}
          // allRecs={Groove}
          backdrop="static"
        />
      )} */}
<div style={{position:"relative", top:"10000px"}}>
{showForm && (
        <WCR_PDF
          show={showForm}
          widata={widata}
          // action={action}
          rowdata={wiobj}
          onHide={() => setShowForm(false)}
          // fetchUserMaster={fetchGroove}
          setReload={setReload}
          reload={reload}
          // userObj={userObj}
          // allRecs={Groove}
          backdrop="static"
        />
      )}
      </div>
       </Container>

<ToastContainer
className="toast-position"
autoClose={3000}
hideProgressBar={true}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="colored"
/>
</>
    );
  });
  
  export default Welder_Continuity_Record;
  