import { Row, Col, Table, Form } from "react-bootstrap";
import axios from "axios";
import { useImperativeHandle, forwardRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { DisableFields } from "./WPSObjects/WPS_common";
import defaultimg from "../../icons/noimage.png";
import {
  MessageTypes,
  WPSH_PWHeatTre_Type,
  Action,
  Status,
  Tubesheet_WPSPage1,
  Tubesheet_WPSPage2,
  WPS_Tubesheet_Detail,
  WeldingProcess,
  WPSH_PWHeatTre_Wrap_insulation_material,
} from "./WPSObjects/WPSGroove_entity";
import { WPSHeader } from "./WPSObjects/WPSHeader";
import {
  WPS_Process_Grid_Input_Tubesheet,
  WPS_Process_Grid_Input_2_Tubesheet,
  General_Single_Input_select,
  Single_Input,
  WPS_Process_Grid_Select,
  WPS_Grid_Input_2_Tubesheet,
  Single_Input_textara,
  WPS_Process_Grid_InputProcess_tubesheet,
  WPS_Process_Grid_Select_master_data,
  WPS_Process_Grid_Select_master_data_TTS,
  Single_Input_textaraNotes
} from "./WPSObjects/WPS_Process_Grid";
import {

   isAlphanumericAllCharacters, isAlphanumericAllCharactersextra,isAlphanumericAllSpecialCharacters
} from "../../functions/validations";
import { AddNewCount, AddNewGroove } from "../../Services/wps_groove-services";
import { AddNewDetail } from "../../Services/wps_details-service";
import { AddLogs } from "../../Services/wps-logs-services";
import { getPresignedURL } from "../../Services/Welder-services";
import { IsUserMasterBand, getAllUserMaster } from "../../Services/user-service";
import { SearchSelectprename } from "../utility/search-select";
import { Date_Input, Date_InputUser } from "../utility/CommonComponents/Date_Component";
import { getActiveItemForProject } from "../../Services/item-services";

const WPSTubesheetProcedureSpec = forwardRef(
  (
    {
      headerObj,
      setheaderObj,
      detailObj,
      setdetailObj,
      copydata,
      logObj,
      setKey,
      tabno,
      setlogObj,
      setIsEditedPage2,
    },
    ref
  ) => {
    const location = useLocation();
    const avatar = { defaultimg };
    
    const [ItemActive, setItemActive] = useState([]);
    useEffect(() => {
      fetchitem();
      setReadonly(
        DisableFields(headerObj, Status, Action, action) ? "disabled" : ""
        );
        }, [headerObj]);
        const [bluestyle, setblue] = useState({
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: state.isFocused ? "blue" : "blue",
            }),
            });
    console.log("ItemActiveeeeee", ItemActive);
            
    const [errorstyle, seterrorstyle] = useState({
      control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? "red" : "red",
      }),
    });

    let userprofile = sessionStorage.getItem("UserProfile");
    let Band = sessionStorage.getItem("Band");
    let username = sessionStorage.getItem("UserName");

    let folderwld = "wld_signatures";
    let folderwps = "WPS_Groove";

    const todaynew = new Date();
    const yyyy = todaynew.getFullYear();
    let mm = todaynew.getMonth() + 1;
    let dd = todaynew.getDate();
    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    const todayDate = yyyy + "-" + mm + "-" + dd;
    const maxDate = todaynew;
    const minDate = yyyy + "-" + mm + "-" + dd;

    let formattedDate

    let initiator = Band !== "P" && Band !== "T";
    console.log("initiator", initiator);

    let finalApprover = Band === "P" || Band === "T";
    console.log("finalApprover", finalApprover);

    var disableButton =
      initiator && headerObj.WPSH_Status === Status.StatusPending
        ? true
        : false;
    const [isDisabled, setIsDisabled] = useState(disableButton);

    const [previewuser, setPreviewuser] = useState( headerObj?.wps_signaturePrepared
      ? headerObj?.wps_signaturePrepared
      : avatar.defaultimg);
    const [previewapproved, setPreviewapproved] = useState(headerObj?.wps_signatureApproved
      ? headerObj?.wps_signatureApproved
      : avatar.defaultimg);
    const [users, setUsers] = useState(null);

    const [userApproveSignature, setuserApproveSignature] = useState(null);
    const fetchitem = async () => {
      const result = await getActiveItemForProject(headerObj.WPSH_Project_No);
      const Item = [{ wld_itemcode: "" }, ...result];
      setItemActive(Item);


    };


    var readonly =
      location.state?.action === Action.ActionCopy ||
      (initiator && headerObj.WPSH_Status === Status.StatusPending)
        ? true
        : false;
    const [isReadonly, setIsReadonly] = useState(readonly);

    const [IsEdited, setIsEdited] = useState(false);
    const [action, setaction] = useState(location.state?.action);
    const [Readonly, setReadonly] = useState( DisableFields(headerObj, Status, Action, Action.ActionEdit)
    ? "disabled"
    : "");

    const [previewUrl, setPreviewUrl] = useState([]);
    // const [previewuser, setPreviewuser] = useState(avatar.defaultimg);

    const [previewapproveduser, setPreviewapproveduser] = useState(
      avatar.defaultimg
    );

    const [profile, setProfile] = useState("");
    const [today, setToday] = useState("");

    var wps_detail = detailObj.filter((item) => item.WPSD_Process !== "");

    if (wps_detail.length <= 0) {
      wps_detail = detailObj;
    }



    const [fillerMetalObj, setFillerMetalObj] = useState([]);
    const [batchnumber, setbatchnumber] = useState([]);
    const [ElecObj, setElcObj] = useState([]);
    const [positionoffilletObj, setPositionoffilletObj] = useState([]);
    const [minimumbeadlengthObj, setMinimumbeadlengthObj] = useState([]);
    const [degLayerObj, setdegLayerObj] = useState([]);
    const [positionGObj, setPositionGObj] = useState([]);
    const [consumableitem, setconsumableitem] = useState([]);
    const [weldPObj, setWeldPObj] = useState([]);
    const [currentPObj, setcurrentPObj] = useState([]);
    const [amperesObj, setAmperesObj] = useState([]);
    const [amperesObj1, setAmperesObj1] = useState([]);

    const [voltsObj, setVoltsObj] = useState([]);
    const [travelSObj, setTravelSObj] = useState([]);
    const [maxHeatObj, setMaxHeatObj] = useState([]);
    const [positionOvlObj, setPositionOvlObj] = useState([]);

    const [batchObj, setBatchObj] = useState([]);
    const [currentPulseObj, setCurrentPulseObj] = useState([]);
    const [currentOthersObj, setcurrentOthersObj] = useState([]);
    const [heatObj, setHeatObj] = useState([])
    // const [itemsObj, setItemsObj] = useState([])


    const initializeArray = (wps_detail, key) => {
      const initialArray = Array.from({ length: 2 }, () =>
        Array.from({ length: 2 }, () => "")
      );
      const mappedArray = initialArray.map((row, rowIndex) =>
        row.map(
          (_, colIndex) =>
          wps_detail[rowIndex * 2 + colIndex]?.[key] || ""
        )
      );
      console.log(`mappedArray for ${key}`, mappedArray);
      const finalArray = mappedArray[0];
      console.log(`finalArray for ${key}`, finalArray);
      return finalArray;
    };

    useEffect(() => {
      const fillerMetalArray = initializeArray(wps_detail, 'WPSD_WP_FillerMetalSIze');
      const batchNumberArray = initializeArray(wps_detail, 'WPSD_WP_BatchNumber');
      // const ElecObjArray = initializeArray(wps_detail, 'WPSD_WP_ElectrodeAngleDegree');
      const PositionFArray = initializeArray(wps_detail, 'WPSD_WP_PositionOfFillet');
      const MinimumArray = initializeArray(wps_detail, 'WPSD_WP_MinimumBeadLength');
      const LayerArray = initializeArray(wps_detail, 'WPSD_WP_LayerNumber');
      const PositionGArray = initializeArray(wps_detail, 'WPSD_WP_PositionOfGroove');
      const ConsumableArray = initializeArray(wps_detail, 'WPSD_WP_WeldConsumable');
      const WeldPArray = initializeArray(wps_detail, 'WPSD_WP_WeldProgression');
      const CurrentArray = initializeArray(wps_detail, 'WPSD_WP_CurrentPolarity');
      const AmpereArray = initializeArray(wps_detail, 'WPSD_WP_Amperes');
      const AmpereArray1 = initializeArray(wps_detail, 'WPSD_WP_Amperes1');
      const VoltsArray = initializeArray(wps_detail, 'WPSD_WP_Volts');
      const TraveArray = initializeArray(wps_detail, 'WPSD_WP_TravelSpeed');
      const MaxheatArray = initializeArray(wps_detail, 'WPSD_WP_MaximumHeatInput');
      const PositionOvlArray = initializeArray(wps_detail, 'WPSD_WP_PositionOfOverlay');
      const Currentpuls = initializeArray(wps_detail, 'WPSD_WP_Pulsing_current');
      const Currentpulsother = initializeArray(wps_detail, 'WPSD_WP_Pulsing_current_others');
      const HeatArray = initializeArray(wps_detail, 'WPSD_WP_HeatInput');
      // const ItemArray = initializeArray(wps_detail, 'WPSD_WP_HeatInput');


      setFillerMetalObj(fillerMetalArray);
      setBatchObj(batchNumberArray);
      setCurrentPulseObj(Currentpuls);
      setcurrentOthersObj(Currentpulsother)
      setHeatObj(HeatArray)
      // setItemsObj(ItemArray)

      // setElcObj(ElecObjArray)
      setPositionoffilletObj(PositionFArray)
      setMinimumbeadlengthObj(MinimumArray)
      setdegLayerObj(LayerArray)
      setPositionGObj(PositionGArray)
      setconsumableitem(ConsumableArray)
      setWeldPObj(WeldPArray)
      setcurrentPObj(CurrentArray)
      setAmperesObj(AmpereArray)
      setAmperesObj1(AmpereArray1)
      setVoltsObj(VoltsArray)
      setTravelSObj(TraveArray)
      setMaxHeatObj(MaxheatArray)
      setPositionOvlObj(PositionOvlArray)


    }, [detailObj]);

    // const [degLayerObj, setdegLayerObj] = useState(
    //   Array.from({ length: 3 }, () => Array.from({ length: 3 }, () => ""))
    // );

    // const [fillerMetalObj, setFillerMetalObj] = useState(
    //   Array.from({ length: 3 }, () => Array.from({ length: 3 }, () => ""))
    // );

    // const [itemsObj, setItemsObj] = useState(
    //   Array.from({ length: 3 }, () => Array.from({ length: 3 }, () => ""))
    // );

    // const [batchObj, setBatchObj] = useState(
    //   Array.from({ length: 3 }, () => Array.from({ length: 3 }, () => ""))
    // );

    // const [amperesObj, setAmperesObj] = useState(
    //   Array.from({ length: 3 }, () => Array.from({ length: 3 }, () => ""))
    // );

    // const [currentPulseObj, setCurrentPulseObj] = useState(
    //   Array.from({ length: 3 }, () => Array.from({ length: 3 }, () => ""))
    // );

    // const [currentOthersObj, setcurrentOthersObj] = useState(
    //   Array.from({ length: 3 }, () => Array.from({ length: 3 }, () => ""))
    // );

    // const [voltsObj, setVoltsObj] = useState(
    //   Array.from({ length: 3 }, () => Array.from({ length: 3 }, () => ""))
    // );

    // const [travelSObj, setTravelSObj] = useState(
    //   Array.from({ length: 3 }, () => Array.from({ length: 3 }, () => ""))
    // );

    // const [heatObj, setHeatObj] = useState(
    //   Array.from({ length: 3 }, () => Array.from({ length: 3 }, () => ""))
    // );

    // const [currentPObj, setcurrentPObj] = useState(
    //   Array.from({ length: 3 }, () => Array.from({ length: 3 }, () => ""))
    // );

    const [imgObj, setimgObj] = useState([]);
    const [imgDataObj, setimgDataObj] = useState(
      headerObj.wld_pdf_image ? headerObj.wld_pdf_image : []
    );


    const fetchUserMaster = async () => {
      const result = await getAllUserMaster();
      const active = result.filter((item) => item.um_status === "Active")
      setUsers(active);
    };


    const fetchUserId = async () => {
      
      try {
          // Ensure users and headerObj are defined and have the necessary properties
          if (!headerObj || !headerObj.WPSH_ApprovedBy_Name) {
              console.error("users or headerObj.WPSH_ApprovedBy_Name is not defined");
              return;
          }
  
          // Filter users to find the one matching the approved name
          const Approveduserid = users?.filter((item) => item?.um_name === headerObj?.WPSH_ApprovedBy_Name);
          console.log("abcdaaaaaaa", Approveduserid);
  
          // Check if the user was found
          if (Approveduserid.length === 0) {
              console.error("No user found with the approved name");
              return;
          }
  
          // Update the user approval signature if data is returned
          if (Approveduserid && Approveduserid.length > 0) {
              setuserApproveSignature(Approveduserid[0]);
          } else {
              console.error("No data returned from getbyuserid");
          }
      } catch (error) {
          console.error("An error occurred:", error);
      }
  };

    console.log("jsjsjsjsjss",users)
    useEffect(() => {
      fetchUserMaster();
    }, []);

    useEffect(() => {
      
      fetchUserId();
    }, [users, headerObj?.WPSH_ApprovedBy_Name]);


    const [error, setError] = useState({
      WPSH_WPS_No: {},
      WPSH_WPS_Revi_No: {},
      WPS_QualifiedTo: {},
      WPSH_WPS_Date: {},
      WPSH_SupportingPQR: {},
      WPSD_WP_Type: {},
      WPSD_WP_MinimumPreheatTemp: {},
      WPSD_WP_MaximumInterpassTemp: {},
      WPSD_WP_PostHeating: {},
      WPSD_WP_TungstenType: {},
      WPSD_WP_WeldPosition: {},
      WPSD_WP_WeldProgression: {},
      WPSD_WP_NoofPasses: {},
      WPSD_WP_LayerNumber: {},
      WPSD_WP_FillerMetalSIze: {},
      WPSD_WP_ItemCode: {},
      WPSD_WP_BatchNumber: {},
      WPSD_WP_Amperes: {},
      WPSD_WP_Pulsing_current: {},
      WPSD_WP_Pulsing_current_others: {},
      WPSD_WP_Volts: {},
      WPSD_WP_TravelSpeed: {},
      WPSD_WP_HeatInput: {},
      WPSD_WP_CurrentPolarity: {},
      WPSD_WP_ArcVoltageControl: {},
      WPSD_WP_ShieldingGasType: {},
      WPSD_WP_ShieldingFlowRate: {},
      WPSD_WP_BackingGasType: {},
      WPSD_WP_BackingFlowRate: {},
      WPSD_WP_AuxiGasShieldSystem: {},
      WPSH_Tech_Expansion: {},
      WPSH_Tech_StringWave: {},
      WPSH_Tech_MethodofCleaning: {},
      WPSH_Tech_OrifceCupSize: {},
      WPSH_Tech_SingleMultiElectrode: {},
      WPSH_Tech_Oscillation: {},
      WPSH_Tech_Peening: {},
      WPSH_Tech_ClosedtooutChamber: {},
      WPSH_Tech_UseOfThermalProcess: {},
      // WPSH_Tech_Others: {},
      WPSH_PWHeatTre_Temperature: {},
      WPSH_PWHeatTre_Time: {},
      WPSH_PWHeatTre_Type: {},
      WPSH_PWHeatTre_Wrap_insulation_material:{},
      WPSH_PWHeatTre_Heating_Rate: {},
      WPSH_PWHeatTre_Cooling_Rate: {},
      WPSH_PWHeatTre_LoadingTemp:{},
      WPSH_PWHeatTre_UnloadingTemp:{},
      WPSH_PWHeatTre_PWHTNotes:{},
      WPSH_Notes: {},
      WPSH_PreparedBy_Name: {},
      WPSH_PreparedBy_Date: {},
      WPSH_ApprovedBy_Name: {},
      WPSH_ApprovedBy_Date: {},
      WPSH_PreparedBy_Remark: {},
      WPSH_ApprovedBy_Remark: {},
    });

    var wps_detail = detailObj.filter((item) => item.WPSD_Process !== "");

    if (wps_detail.length <= 0) {
      //console.log("wps_detail.length <= 0", wps_detail.length);
      wps_detail = detailObj;
    }
    //console.log("wps_detail_WPSTubesheetProcedureSpec", wps_detail);

    const validate = async () => {
      let tubePage1 = Object.entries(Tubesheet_WPSPage1);

      let isError = false;

      tubePage1.map(([key, val]) => {
        if (headerObj[key] === "") {
          isError = true;
          error[key] = {
            status: true,
            message: `This field is required`,
          };
        }
      });

      setError((prevState) => ({
        ...prevState,
        ...error,
      }));
      console.log("Validate1", isError);
      return isError;
    };

    // const [consumableitem, setconsumableitem] = useState([]);
    // useEffect(() => {
      
    //   const initializeObj = () => {
    //     const initialArray = Array.from({ length: 3 }, () =>
    //       Array.from({ length: 3 }, () => "")
    //     );
    //     const Keys = Object.keys(detailObj)
    //     console.log("dkasdasa",Keys)
    //     const mappedArray = initialArray.map((row, rowIndex) =>
    //       row.map(
    //         (_, colIndex) =>
    //           wps_detail[rowIndex * 3 + colIndex]?.WPSD_WP_ItemCode || ""
    //       )
    //     );
    //     console.log("mappedArray", mappedArray);
    //     let Final = mappedArray[0];
    //     console.log("finalllll", Final);
    //     setconsumableitem(Final);
    //   };

    //   initializeObj();
    // }, [detailObj]);


    const validate2 = async (detaildata) => {
      
      console.log("validate2_detaildata", detaildata);

      var errortype = WPS_Tubesheet_Detail;
      console.log("errortype", errortype);

      let entries = Object.entries(detaildata);
      console.log("entries", entries);

      let tubePage2 = Object.entries(Tubesheet_WPSPage2);
      console.log("tubePage2", tubePage2);

      let isError = false;

      tubePage2.map(([key, val]) => {
        console.log("kkkkeeey", key);

        if (headerObj[key] === "") {
          // console.log("errorroro", headerObj[key], key);
          isError = true;
          error[key] = {
            status: true,
            message: `This field is required`,
          };
        } else {
          error[key] = {
            status: false,
            message: "",
          };
        }
          setError((prevState) => ({
        ...error,
      }));
        // return isError;
      });


      errortype.map((cols) => {
        
        entries.forEach(([key, val]) => {
          
          console.log("validate2_key", key);
          var value = "";
          var msg = "";
          var status = false;
          console.log("detailObj[key]", detailObj[key]);
          if (cols.Type === "S") {
            value = detailObj[key][cols.Column];
            msg = `This field is required`;
          } else if (cols.Type === "A") {
            value =
              detailObj[key][cols.Column][0] || detailObj[key][cols.Column][1];
            msg = `At least one field is required`;
          }
          console.log("here_value", value);
          if (value === undefined || value === "" || value === null) {
            isError = true;
            status = true;
          }
          setError((prevState) => ({
            ...prevState,
            [cols.Column]: {
              status: status,
              message: msg,
            },
          }));
        });
      });



      // setError((prevState) => ({
      //   ...prevState,
      //   ...error,
      // }));
      console.log("Validate_isError", isError);
      return isError;
    };

    console.log("eeeeerrororor", error);


    const uploadImage = async (item) => {
      let unique = headerObj.WPSH_Project_No + "_" + headerObj.WPSH_WPS_No;
      const result = await getPresignedURL(folderwld, item.value.name);
      const posturl = result.url;
      await axios.put(posturl, item.value);
    };

    const handleChangedetailsarrayselect = (e, i) => {
      
      if (action === Action.ActionEdit) setIsEdited(true);
      setIsEditedPage2(true);
      const { name, value } = e.target;
      const onChangeVal = [...detailObj];
      onChangeVal[i][name] = value;
      setdetailObj(onChangeVal);
      setError((prevState) => ({
        ...prevState,
        [name]: { status: false, message: "" },
      }));
    };

    const handleCount = () => {
      
      let result;

      if (action === Action.ActionEdit) {
        if (headerObj.WPSH_Status === Status.StatusApproved) {
          console.log("edxisting_status", headerObj.WPSH_Status);
          headerObj.WPS_Change_No = location.state?.WPS_Change_No;
          console.log("headerObj.WPS_Change_No", headerObj.WPS_Change_No);
          result = AddNewCount(headerObj);
        }
      }
    };

    const handleAdd = async (approvalstatus) => {
      
      let result = false;
      let previousStatus = headerObj.WPSH_Status;

      console.log("handleAdd_headerObj.WPSH_Status", headerObj.WPSH_Status);
      console.log(
        "handleAdd_headerObj.WPSH_Approval_Status",
        headerObj.WPSH_Approval_Status
      );
      console.log("handleAdd_approvalstatus", approvalstatus);
      
      // if (headerObj.WPSH_Status === Status.StatusApproved) {
      //   handleCount();
      // }

      if (
        headerObj.WPSH_Approval_Status !== Status.StatusTempSaved &&
        headerObj.WPSH_Approval_Status !== undefined
      )
        headerObj.WPSH_Status = headerObj.WPSH_Approval_Status;
      else headerObj.WPSH_Status = approvalstatus;

      console.log("now_in", headerObj);

      if (
        (Band === "P" || Band === "T") &&
        headerObj.WPSH_Approval_Status !== ""
      ) {
        headerObj.wps_signatureApproved =  headerObj.wps_signatureApproved;
        headerObj.WPSH_ApprovedBy_Name =  headerObj.WPSH_ApprovedBy_Name;
        // headerObj.WPSH_ApprovedBy_Date = today;
        // headerObj.WPSH_ApprovedBy = sessionStorage.getItem("User");
      }
      if (Band !== "T" || Band !== "P") {
        if (action === Action.ActionAdd) {
          headerObj.WPSH_PreparedBy_Name = headerObj.WPSH_PreparedBy_Name;
          // headerObj.WPSH_PreparedBy_Date = today;
          headerObj.wps_signaturePrepared = headerObj.wps_signaturePrepared;;
        }
      }

      var WpsDetail = detailObj.filter(
        (i) => i.WPSD_Process !== "NA" && i.WPSD_Process !== ""
      );
      console.log("WpsDetail......", WpsDetail);
      

      console.log("AddNewGroove_headerObj", headerObj);

      let res = await AddNewGroove(headerObj)
        .catch((e) => {
          result = false;
        })
        .then(async () => {
          result = true;
          if (imgObj.length > 0) {
            for (let item of imgObj) {
              if (item.value.name) await uploadImage(item);
            }
          }
          for (const item of WpsDetail) {

           AddNewDetail(item)
            .catch((err) => {
              console.log("error" + err);
            })
            .then((r) => {
              console.log("first  done detail", r);
              setaction(Action.ActionEdit);
              result = true;
            });
          }
          if (
            action === Action.ActionEdit &&
            previousStatus === Status.StatusApproved //only if its Approved add logs
          ) {
            await AddLogs(logObj);
          }
        });
      return Promise.resolve(result);
    };

    const checkIfUsernameExists = async () => {
      let res = await IsUserMasterBand();
      if (res !== undefined) return res.data.message;
      else return false;
    };

    useEffect(() => {
      console.log("IsEdited", IsEdited);
    }, [IsEdited]);

    const handleSubmitTemp = async () => {
      
      var res = false;
      console.log("handleSubmitTemp_IsEdited", IsEdited);

      if (IsEdited) {
        setIsEdited(false);
        setIsEditedPage2(false);
        console.log("2nd page Submit no ");

        res = await handleAdd(Status.StatusTempSaved);

        if (res) {
          console.log("2nd Page Tab", tabno);
          return MessageTypes.Temp;
        } else {
          return MessageTypes.Fail;
        }
      }
      else{
        res = await handleAdd(Status.StatusTempSaved);

        if (res) {
          console.log("2nd Page Tab", tabno);
          return MessageTypes.Temp;
        } else {
          return MessageTypes.Fail;
        }
        
      }

    };

    var detaildata = detailObj.filter((item) => item.WPSD_Process !== "");


    const handleSubmit = async () => {
      
      setIsEdited(false);
      setIsEditedPage2(false);
      console.log("headerObj_handleSubmit", headerObj);
      console.log("headerObj_handleSubmit_WPSH_Status", headerObj.WPSH_Status);
      console.log(
        "headerObj_handleSubmit_WPSH_Approval_Status",
        headerObj.WPSH_Approval_Status
      );

      //var result = false;
      var result = false;
      const isUsernamePresent = await checkIfUsernameExists();
      
      console.log("bfr decide", headerObj.WPSH_Status);

      if (
        !isUsernamePresent &&
        headerObj.WPSH_Status === Status.StatusApproved
      ) {
        console.log("!isUsernamePresent", headerObj.WPSH_Status);

        handleCount();
        headerObj.WPSH_Status = Status.StatusPending;
      } else if (isUsernamePresent) {
        console.log("!isUsernamePresent", headerObj.WPSH_Status);
        headerObj.WPSH_Status = Status.StatusTempSaved;
      }

      // if (initiator) {
      //   headerObj.WPSH_Status = Status.StatusPending;
      // }
      console.log("after decide", headerObj.WPSH_Status);

      if (!(await validate2(detaildata)) && !(await validate(detaildata))) {
        result = handleAdd(Status.StatusPending);
        console.log("result_pending", result);
        
        if (result) {
          return MessageTypes.Success;
        } else {
          return MessageTypes.Fail;
        }
      } else {
        setTimeout(() => {
          if (action === Action.ActionAdd) {
            alert("Please Temp Save Data Before Leaving this Page")
          }
        }, 500);
        return MessageTypes.RequiredAll;
      }
    };

    useImperativeHandle(ref, () => ({
      funcOnNext: async () => {
        var res = false;
        res = await handleSubmit();
        return res;
      },

      funcOnNextTemp: async () => {
        
        var res = false;
        console.log("is temporary", res);
        res = await handleSubmitTemp();
        console.log("is temporary return", res);
        return res;
      },
    }));

    useEffect(() => {
      if (imgDataObj.length > 0) {
        setheaderObj((prevState) => ({
          ...prevState,
          ["wld_pdf_image"]: imgDataObj,
        }));
      }
    }, [imgDataObj]);

    const fetchImages = async (item) => {
      

      try {
        

        let unique = headerObj.WPSH_Project_No + "_" + headerObj.WPSH_WPS_No;
        if (item.value != "" || item.value != undefined) {
          const result = await getPresignedURL(folderwld, item.value);
          const geturl = result.url;
          console.log("geturl", geturl);
          let a = { name: item.name, value: geturl };
          setPreviewUrl((prev) => [...prev, a]);
          //await axios.get(geturl);
        }
      } catch (error) {
        if (error.request) {
          console.log(error.request);
        } else {
          console.log(`Error: ${error.message}`);
        }
      }
    };

    useEffect(() => {
      
      if (headerObj.wld_pdf_image) {
        for (let item of headerObj.wld_pdf_image) {
          fetchImages(item);
        }
      }
    }, []);

    const fetchapproveUserProfile = async (userprofile) => {
      
      try {
        console.log("updateWitness_userprofile", userprofile);
        console.log("updateWitness_folderwld", folderwld);
        if (userprofile) {
          const result = await getPresignedURL(folderwld, userprofile);
          console.log("updateWitness_url", result.url);
          const geturl = result.url;
          setPreviewapproved(geturl);
          console.log("setPreviewapproved_geturl", geturl);
        }
      } catch (error) {
        if (error.request) {
          console.log(error.request);
        } else {
          console.log(`Error: ${error.message}`);
        }
      }
    };

    const fetchbyuserprofile = async (userprofile) => {
      try {
        console.log("updateWeldingEngg_userprofile", userprofile);
        console.log("updateWeldingEngg_folderwld", folderwld);
        if (userprofile) {
          const result = await getPresignedURL(folderwld, userprofile);
          console.log("updateWeldingEngg_url", result.url);

          const geturl = result.url;
          setPreviewuser(geturl);
          console.log("Previewuser_geturl", geturl);
        }
      } catch (error) {
        if (error.request) {
          console.log(error.request);
        } else {
          console.log(`Error: ${error.message}`);
        }
      }
    };

    useEffect(() => {
      
      // fetchapproveUserProfileswld();
      // const currentDate = new Date();
      formattedDate = new Date().toLocaleDateString("en-GB")
      setToday(formattedDate);
      console.log("here", headerObj.WPSH_Prepared_By, headerObj.WPSH_Status);
      setReadonly(
        DisableFields(headerObj, Status, Action, action) ? "disabled" : ""
      );
      console.log("here", Readonly, "ddd");
    }, []);


    useEffect(() => {
      
            if(action === Action.ActionEdit && headerObj?.wps_signatureApproved !== ""){
              console.log("enterrrImggg")
      
              fetchbyuserprofile(headerObj?.wps_signaturePrepared)
            }
      
            if(action === Action.ActionEdit && headerObj?.wps_signatureApproved !== ""){
              console.log("enterrrImggg")
            fetchapproveUserProfile(headerObj?.wps_signatureApproved)
            }
      
          }, []);


    const handleSelectChange = (e) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      setIsEditedPage2(true);
      const { name, value } = e.target;
      setheaderObj((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      setError((prevState) => ({
        ...prevState,
        [name]: { status: false, message: "" },
      }));
    };

    const handleChange = (e) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      setIsEditedPage2(true);
      const { name, value } = e.target;

      setheaderObj((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };


    const [valueNotes, setValueNotes] = useState("");
    const maxNewLines = 13; // Maximum allowed new lines

    const handleChangeNotes = (e) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      setIsEditedPage2(true);
    
      const { name, value } = e.target;
      
      // Split the input text into lines based on existing newlines
      const lines = value.split('\n');
      
      // Limit each line to 100 characters
      const formattedLines = lines.map(line => {
        if (line.length > 100) {
          // Break the line into chunks of 100 characters
          return line.match(/.{1,100}/g).join('\n');
        }
        return line;
      });
    
      // Join the lines back together
      let formattedValue = formattedLines.join('\n'); // Use 'let' here instead of 'const'
      
      // Count the number of new lines
      const newLinesCount = (formattedValue.match(/\n/g) || []).length;
      
      if (newLinesCount <= maxNewLines) {
        setValueNotes(formattedValue);
    
        // Update the header object state with the new value
        setheaderObj((prevState) => ({
          ...prevState,
          [name]: formattedValue,
        }));
      } else {
        e.preventDefault(); // Prevent further input if the new line limit is reached
      }
    };

const handleKeyDown = (event) => {
  if (event.key === "Enter") {
    const newLinesCount = (valueNotes.match(/\n/g) || []).length;
    if (newLinesCount >= maxNewLines) {
      event.preventDefault(); // Prevent adding more than 13 new lines
    }
  }
};

    const handleChangeRadio = async (e) => {

      if (action === Action.ActionEdit) setIsEdited(true);
      if (!(await validate2(detaildata)) && !(await validate(detaildata))) {

      const { name, value } = e.target;

      setheaderObj((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    else{
      alert("Cannot Approve or Reject the Record, Please Fill Required Fields of all 3 Pages.")
    }
    };

    const handleChangeTube = (row, col, key, obj, setfunction, e) => {
      

      if (action === Action.ActionEdit) setIsEdited(true);
      setIsEditedPage2(true);
      let temp = [...obj];
      temp[row][col] = e.target.value;
      setfunction(temp);
      let onChangeVal = [...detailObj];
      onChangeVal[row][key] = temp[row];
      setdetailObj(onChangeVal);
    };

    const handleChangedetailsarray = (e, i) => {
      
      if (action === Action.ActionEdit) setIsEdited(true);
      const { name, value } = e.target;
      const onChangeVal = [...detailObj];
      onChangeVal[i][name] = value;
      setdetailObj(onChangeVal);
    };

    //console.log("tube_Readonly", Readonly);

    return (
      //style="width:70%"
        <Row className="font">
          <WPSHeader headerObj={headerObj}></WPSHeader>
          <Row className="mb-4" style={{ padding: "28px 48px" }}>
            <span className="WPS-blue-text" style={{position:"relative",top:"10px"}}>Welding Procedure</span>
            <Col>
              <Table
                bordered
                responsive="sm"
                // className="text-center same-col-widths align-middle"
                className="text-center tube-col-widths"
              >
                <tbody>
                 
                    <WPS_Process_Grid_InputProcess_tubesheet
                    wps_detail={wps_detail}
                    maxLength={10}
                    disabled={isDisabled}
                    ColumnHeader="Welding Process"
                    type="text"
                    name="WPSD_WP_WeldingProcess"                 
                    handleChangedetailsarray={handleChangedetailsarray}
                  ></WPS_Process_Grid_InputProcess_tubesheet>

                  <WPS_Process_Grid_Input_Tubesheet
                    wps_detail={wps_detail}
                    maxLength={10}
                    //readOnly={isReadonly}
                    disabled={isDisabled}
                    ColumnHeader="Type"
                    status={error.WPSD_WP_Type.status}
                    message={error.WPSD_WP_Type.message}
                    type="text"
                    name="WPSD_WP_Type"
                    onInput={(e) => {
                      let error =  isAlphanumericAllCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPSD_WP_Type: error,
                      }));
                    }}
                    handleChangedetailsarray={handleChangedetailsarray}
                  ></WPS_Process_Grid_Input_Tubesheet>

                  <WPS_Process_Grid_Input_Tubesheet
                    wps_detail={wps_detail}
                    maxLength={10}
                    //readOnly={isReadonly}
                    ColumnHeader="Minimum Preheat Temp"
                    Columndimension="°C"
                    status={error.WPSD_WP_MinimumPreheatTemp.status}
                    message={error.WPSD_WP_MinimumPreheatTemp.message}
                    type="text"
                    name="WPSD_WP_MinimumPreheatTemp"
                    onInput={(e) => {
                      let error =  isAlphanumericAllCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPSD_WP_MinimumPreheatTemp: error,
                      }));
                    }}
                    handleChangedetailsarray={handleChangedetailsarray}
                  ></WPS_Process_Grid_Input_Tubesheet>
                  <WPS_Process_Grid_Input_Tubesheet
                    wps_detail={wps_detail}
                    maxLength={10}
                    //readOnly={isReadonly}
                    ColumnHeader=" Maximum Interpass Temp"
                    Columndimension="°C"
                    status={error.WPSD_WP_MaximumInterpassTemp.status}
                    message={error.WPSD_WP_MaximumInterpassTemp.message}
                    type="text"
                    name="WPSD_WP_MaximumInterpassTemp"
                    onInput={(e) => {
                      let error =  isAlphanumericAllCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPSD_WP_MaximumInterpassTemp: error,
                      }));
                    }}
                    handleChangedetailsarray={handleChangedetailsarray}
                  ></WPS_Process_Grid_Input_Tubesheet>
                  <WPS_Process_Grid_Input_Tubesheet
                    wps_detail={wps_detail}
                    maxLength={28}
                    //readOnly={isReadonly}
                    ColumnHeader="Post Heating/DHT"
                    status={error.WPSD_WP_PostHeating.status}
                    message={error.WPSD_WP_PostHeating.message}
                    type="text"
                    name="WPSD_WP_PostHeating"
                    onInput={(e) => {
                      let error =  isAlphanumericAllCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPSD_WP_PostHeating: error,
                      }));
                    }}
                    handleChangedetailsarray={handleChangedetailsarray}
                  ></WPS_Process_Grid_Input_Tubesheet>
                  <WPS_Process_Grid_Input_Tubesheet
                    wps_detail={wps_detail}
                    maxLength={28}
                    //readOnly={isReadonly}
                    ColumnHeader="Tungsten Type and Size"
                    Columndimension="mm"
                    status={error.WPSD_WP_TungstenType.status}
                    message={error.WPSD_WP_TungstenType.message}
                    type="text"
                    name="WPSD_WP_TungstenType"
                    onInput={(e) => {
                      let error =  isAlphanumericAllCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPSD_WP_TungstenType: error,
                      }));
                    }}
                    handleChangedetailsarray={handleChangedetailsarray}
                  ></WPS_Process_Grid_Input_Tubesheet>
                  <WPS_Process_Grid_Input_Tubesheet
                    wps_detail={wps_detail}
                    maxLength={48}
                    //readOnly={isReadonly}
                    ColumnHeader="Welding Position"
                    status={error.WPSD_WP_WeldPosition.status}
                    message={error.WPSD_WP_WeldPosition.message}
                    type="text"
                    name="WPSD_WP_WeldPosition"
                    onInput={(e) => {
                      let error =  isAlphanumericAllCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPSD_WP_WeldPosition: error,
                      }));
                    }}
                    handleChangedetailsarray={handleChangedetailsarray}
                  ></WPS_Process_Grid_Input_Tubesheet>
                  <WPS_Process_Grid_Input_Tubesheet
                    wps_detail={wps_detail}
                    maxLength={28}
                    //readOnly={isReadonly}
                    ColumnHeader="Progression"
                    status={error.WPSD_WP_WeldProgression.status}
                    message={error.WPSD_WP_WeldProgression.message}
                    type="text"
                    name="WPSD_WP_WeldProgression"
                    onInput={(e) => {
                      let error =  isAlphanumericAllCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPSD_WP_WeldProgression: error,
                      }));
                    }}
                    handleChangedetailsarray={handleChangedetailsarray}
                  ></WPS_Process_Grid_Input_Tubesheet>
                  <WPS_Process_Grid_Input_Tubesheet
                    wps_detail={wps_detail}
                    maxLength={4}
                    //readOnly={isReadonly}
                    ColumnHeader="No of Passes"
                    status={error.WPSD_WP_NoofPasses.status}
                    message={error.WPSD_WP_NoofPasses.message}
                    type="text"
                    name="WPSD_WP_NoofPasses"
                    onInput={(e) => {
                      let error =  isAlphanumericAllCharacters(e.target.value);
                      setError((prevState) => ({
                        ...prevState,
                        WPSD_WP_NoofPasses: error,
                      }));
                    }}
                    handleChangedetailsarray={handleChangedetailsarray}
                  ></WPS_Process_Grid_Input_Tubesheet>

                  <WPS_Grid_Input_2_Tubesheet
                    wps_detail={wps_detail}
                    maxLength={10}
                    //readOnly={isReadonly}
                    ColumnHeader="Layer Number"
                    status={error.WPSD_WP_LayerNumber.status}
                    message={error.WPSD_WP_LayerNumber.message}
                    setfunction={setdegLayerObj}
                    obj={degLayerObj}
                    type="text"
                    name="WPSD_WP_LayerNumber"
                    handleChange={handleChangeTube}
                    onInput={(e) => {
                      let error =  isAlphanumericAllCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPSD_WP_LayerNumber: error,
                      }));
                    }}
                  ></WPS_Grid_Input_2_Tubesheet>

                  <WPS_Grid_Input_2_Tubesheet
                    wps_detail={wps_detail}
                    maxLength={10}
                    ColumnHeader="Filler Metal Size"
                    Columndimension="mm"
                    //readOnly={isReadonly}
                    status={error.WPSD_WP_FillerMetalSIze.status}
                    message={error.WPSD_WP_FillerMetalSIze.message}
                    obj={fillerMetalObj}
                    setfunction={setFillerMetalObj}
                    type="text"
                    name="WPSD_WP_FillerMetalSIze"
                    handleChange={handleChangeTube}
                    onInput={(e) => {
                      let error =  isAlphanumericAllCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPSD_WP_FillerMetalSIze: error,
                      }));
                    }}
                  ></WPS_Grid_Input_2_Tubesheet>

                  <WPS_Process_Grid_Select_master_data_TTS
                  data={ItemActive}
                  bluestyle={bluestyle}
                  errorstyle={errorstyle}
                  valueField="wld_itemcode"
                  labelField="wld_itemcode"
                  name="WPSD_WP_ItemCode"
                  name1="WPSD_WP_WeldDesc"
                  setdetailObj={setdetailObj}
                  detailObj={detailObj}
                  setError={setError}
                  error={error}
                  onInput={(e) => {
                    setError((prevState) => ({
                      ...prevState,
                      WPSD_WP_ItemCode: { status: false, message: "" },
                    }));
                  }}
                  wps_detail={wps_detail}
                  ColumnHeader="Item Code"
                  Columndimension="mm"
                  status={error.WPSD_WP_ItemCode.status}
                  message={error.WPSD_WP_ItemCode.message}
                  handleChangedetailsarray={handleChangedetailsarray}
                ></WPS_Process_Grid_Select_master_data_TTS>

                  <WPS_Grid_Input_2_Tubesheet
                    wps_detail={wps_detail}
                    maxLength={9}
                    //readOnly={isReadonly}
                    ColumnHeader="Batch No"
                    status={error.WPSD_WP_BatchNumber.status}
                    message={error.WPSD_WP_BatchNumber.message}
                    obj={batchObj}
                    setfunction={setBatchObj}
                    type="text"
                    name="WPSD_WP_BatchNumber"
                    handleChange={handleChangeTube}
                    onInput={(e) => {
                      let error =  isAlphanumericAllCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPSD_WP_BatchNumber: error,
                      }));
                    }}
                  ></WPS_Grid_Input_2_Tubesheet>

                  <WPS_Grid_Input_2_Tubesheet
                    wps_detail={wps_detail}
                    maxLength={8}
                    ColumnHeader="Amperes"
                    Columndimension="Amps"
                    //readOnly={isReadonly}
                    status={error.WPSD_WP_Amperes.status}
                    message={error.WPSD_WP_Amperes.message}
                    obj={amperesObj}
                    setfunction={setAmperesObj}
                    type="text"
                    name="WPSD_WP_Amperes"
                    handleChange={handleChangeTube}
                    onInput={(e) => {
                      let error =  isAlphanumericAllCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPSD_WP_Amperes: error,
                      }));
                    }}
                  ></WPS_Grid_Input_2_Tubesheet>

                  <WPS_Grid_Input_2_Tubesheet
                    wps_detail={wps_detail}
                    maxLength={25}
                    ColumnHeader="DC Pulsing Current"
                    Columndimension="Amps"
                    //readOnly={isReadonly}
                    status={error.WPSD_WP_Pulsing_current.status}
                    message={error.WPSD_WP_Pulsing_current.message}
                    obj={currentPulseObj}
                    setfunction={setCurrentPulseObj}
                    type="text"
                    name="WPSD_WP_Pulsing_current"
                    handleChange={handleChangeTube}
                    onInput={(e) => {
                      let error =  isAlphanumericAllCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPSD_WP_Pulsing_current: error,
                      }));
                    }}
                  ></WPS_Grid_Input_2_Tubesheet>

                  <WPS_Grid_Input_2_Tubesheet
                    wps_detail={wps_detail}
                    maxLength={25}
                    ColumnHeader="DC Pulsing Current - Others"
                    Columndimension="Amps"
                    //readOnly={isReadonly}
                    status={error.WPSD_WP_Pulsing_current_others.status}
                    message={error.WPSD_WP_Pulsing_current_others.message}
                    obj={currentOthersObj}
                    setfunction={setcurrentOthersObj}
                    type="text"
                    name="WPSD_WP_Pulsing_current_others"
                    handleChange={handleChangeTube}
                    onInput={(e) => {
                      let error =  isAlphanumericAllCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPSD_WP_Pulsing_current_others: error,
                      }));
                    }}
                  ></WPS_Grid_Input_2_Tubesheet>

              

                  <WPS_Grid_Input_2_Tubesheet
                    wps_detail={wps_detail}
                    maxLength={10}
                    ColumnHeader="Voltage"
                    Columndimension="Volts"
                    //readOnly={isReadonly}
                    status={error.WPSD_WP_Volts.status}
                    message={error.WPSD_WP_Volts.message}
                    obj={voltsObj}
                    setfunction={setVoltsObj}
                    type="text"
                    name="WPSD_WP_Volts"
                    handleChange={handleChangeTube}
                    onInput={(e) => {
                      let error =  isAlphanumericAllCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPSD_WP_Volts: error,
                      }));
                    }}
                  ></WPS_Grid_Input_2_Tubesheet>

                  <WPS_Grid_Input_2_Tubesheet
                    wps_detail={wps_detail}
                    maxLength={10}
                    ColumnHeader="Travel Speed"
                    Columndimension="mm/min"
                    //readOnly={isReadonly}
                    status={error.WPSD_WP_TravelSpeed.status}
                    message={error.WPSD_WP_TravelSpeed.message}
                    obj={travelSObj}
                    setfunction={setTravelSObj}
                    type="text"
                    name="WPSD_WP_TravelSpeed"
                    handleChange={handleChangeTube}
                    onInput={(e) => {
                      let error =  isAlphanumericAllCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPSD_WP_TravelSpeed: error,
                      }));
                    }}
                  ></WPS_Grid_Input_2_Tubesheet>

                  <WPS_Grid_Input_2_Tubesheet
                    wps_detail={wps_detail}
                    ColumnHeader="Maximum Heat Input"
                    Columndimension="KJ/mm"
                    maxLength={10}
                    //readOnly={isReadonly}
                    status={error.WPSD_WP_HeatInput.status}
                    message={error.WPSD_WP_HeatInput.message}
                    obj={heatObj}
                    setfunction={setHeatObj}
                    type="text"
                    name="WPSD_WP_HeatInput"
                    handleChange={handleChangeTube}
                    onInput={(e) => {
                      let error =  isAlphanumericAllCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPSD_WP_HeatInput: error,
                      }));
                    }}
                  ></WPS_Grid_Input_2_Tubesheet>

                  <WPS_Grid_Input_2_Tubesheet
                    wps_detail={wps_detail}
                    maxLength={4}
                    //readOnly={isReadonly}
                    ColumnHeader="Polarity or Current Type "
                    status={error.WPSD_WP_CurrentPolarity.status}
                    message={error.WPSD_WP_CurrentPolarity.message}
                    obj={currentPObj}
                    setfunction={setcurrentPObj}
                    type="text"
                    name="WPSD_WP_CurrentPolarity"
                    handleChange={handleChangeTube}
                    onInput={(e) => {
                      let error =  isAlphanumericAllCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPSD_WP_CurrentPolarity: error,
                      }));
                    }}
                  ></WPS_Grid_Input_2_Tubesheet>

                  <WPS_Process_Grid_Input_Tubesheet
                    wps_detail={wps_detail}
                    maxLength={28}
                    //readOnly={isReadonly}
                    ColumnHeader="Automatic Arc Voltage control"
                    status={error.WPSD_WP_ArcVoltageControl.status}
                    message={error.WPSD_WP_ArcVoltageControl.message}
                    type="text"
                    name="WPSD_WP_ArcVoltageControl"
                    onInput={(e) => {
                      let error =  isAlphanumericAllCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPSD_WP_ArcVoltageControl: error,
                      }));
                    }}
                    handleChangedetailsarray={handleChangedetailsarray}
                  ></WPS_Process_Grid_Input_Tubesheet>

                  <WPS_Process_Grid_Input_2_Tubesheet
                    wps_detail={wps_detail}
                    maxLength={25}
                    //readOnly={isReadonly}
                    mainheading="Shielding"
                    ColumnHeader="Gas Type - % comp"
                    ColumnHeader1="Flow Rate"
                    Columndimension="I/min"
                    status={
                      error.WPSD_WP_ShieldingGasType.status ||
                      error.WPSD_WP_ShieldingFlowRate.status
                    }
                    message={
                      error.WPSD_WP_ShieldingGasType.message ||
                      error.WPSD_WP_ShieldingFlowRate.message
                    }
                    type="text"
                    name="WPSD_WP_ShieldingGasType"
                    name1="WPSD_WP_ShieldingFlowRate"
                    onInput={(e) => {
                      let error =
                      isAlphanumericAllCharactersextra(
                          e.target.value
                        );
                      setError((prevState) => ({
                        ...prevState,
                        WPSD_WP_ShieldingGasType: error,
                      }));
                    }}
                    onInput1={(e) => {
                      let error =
                      isAlphanumericAllCharactersextra(
                          e.target.value
                        );
                      setError((prevState) => ({
                        ...prevState,
                        WPSD_WP_ShieldingFlowRate: error,
                      }));
                    }}
                    handleChangedetailsarray={handleChangedetailsarray}
                  ></WPS_Process_Grid_Input_2_Tubesheet>
                  <WPS_Process_Grid_Input_2_Tubesheet
                    wps_detail={wps_detail}
                    maxLength={25}
                    //readOnly={isReadonly}
                    mainheading="Backing / Trailing"
                    ColumnHeader="Gas Type - % comp"
                    ColumnHeader1="Flow Rate"
                    Columndimension="I/min"
                    status={
                      error.WPSD_WP_BackingGasType.status ||
                      error.WPSD_WP_BackingFlowRate.status
                    }
                    message={
                      error.WPSD_WP_BackingGasType.message ||
                      error.WPSD_WP_BackingFlowRate.message
                    }
                    type="text"
                    name="WPSD_WP_BackingGasType"
                    name1="WPSD_WP_BackingFlowRate"
                    onInput={(e) => {
                      let error =
                      isAlphanumericAllCharactersextra(
                          e.target.value
                        );
                      setError((prevState) => ({
                        ...prevState,
                        WPSD_WP_BackingGasType: error,
                      }));
                    }}
                    onInput1={(e) => {
                      let error =
                      isAlphanumericAllCharactersextra(
                          e.target.value
                        );
                      setError((prevState) => ({
                        ...prevState,
                        WPSD_WP_BackingFlowRate: error,
                      }));
                    }}
                    handleChangedetailsarray={handleChangedetailsarray}
                  ></WPS_Process_Grid_Input_2_Tubesheet>
                  <WPS_Process_Grid_Input_Tubesheet
                    wps_detail={wps_detail}
                    maxLength={38}
                    //readOnly={isReadonly}
                    ColumnHeader="Auxiliary gas shield system"
                    status={error.WPSD_WP_AuxiGasShieldSystem.status}
                    message={error.WPSD_WP_AuxiGasShieldSystem.message}
                    type="text"
                    name="WPSD_WP_AuxiGasShieldSystem"
                    onInput={(e) => {
                      let error =
                         isAlphanumericAllCharactersextra(
                          e.target.value
                        );
                      setError((prevState) => ({
                        ...prevState,
                        WPSD_WP_AuxiGasShieldSystem: error,
                      }));
                    }}
                    handleChangedetailsarray={handleChangedetailsarray}
                  ></WPS_Process_Grid_Input_Tubesheet>
                </tbody>
              </Table>
            </Col>
          </Row>


          <Row style={{ padding: "28px 60px", position:"relative", bottom:"30px"  }} className="WPS-Page-row-padding">
            <span className="WPS-blue-text" style={{position:"relative",right:"10px",top:"10px"}}>
              Post Weld Heat Treatment (QW-407)
            </span>
            <Row className="mb-1">
              <Col className="mb-1" md={3}>
                <Single_Input
                  disabled={copydata}
                  name="WPSH_PWHeatTre_Temperature"
                  type="text"
                  //readOnly={isReadonly}
                  maxLength={25}
                  status={error.WPSH_PWHeatTre_Temperature.status}
                  value={headerObj.WPSH_PWHeatTre_Temperature}
                  handleChange={handleChange}
                  label="Post Weld H/T Temp(°C)"
                  message={error.WPSH_PWHeatTre_Temperature.message}
                  onInput={(e) => {
                    let error =  isAlphanumericAllCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPSH_PWHeatTre_Temperature: error,
                    }));
                  }}
                ></Single_Input>
              </Col>
              <Col className="mb-1" md={3}>
                <div class="T-input w-100">
                  <input
                    type="text"
                    id="WPSH_PWHeatTre_Time"
                    name="WPSH_PWHeatTre_Time"
                    maxLength={20}
                    //readOnly={isReadonly}
                    disabled={copydata}
                    placeholder=" "
                    className={
                      error.WPSH_PWHeatTre_Time.status
                        ? " T-input placeHolderInput inputText text-field-with-error"
                        : "T-input placeHolderInput inputText"
                    }
                    value={headerObj.WPSH_PWHeatTre_Time}
                    onChange={handleChange}
                    onInput={(e) => {
                      let error =  isAlphanumericAllCharacters(
                        e.target.value
                      );

                      setError((prevState) => ({
                        ...prevState,
                        WPSH_PWHeatTre_Time: error,
                      }));
                    }}
                  />
                  <label for="WPSH_PWHeatTre_Time">Time (Minutes)</label>
                </div>
                {headerObj.WPSH_PWHeatTre_Temperature !== "" ? (
                  <div className="required-text1">
                    {error.WPSH_PWHeatTre_Time.status && (
                      <Form.Text className="text-danger">
                        {error.WPSH_PWHeatTre_Time.message}
                      </Form.Text>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </Col>
              <Col className="mb-1" md={3}>
                <General_Single_Input_select
                  name="WPSH_PWHeatTre_Type"
                  //readOnly={isReadonly}
                  disabled={isDisabled}
                  value={headerObj.WPSH_PWHeatTre_Type}
                  status={error.WPSH_PWHeatTre_Type.status}
                  message={error.WPSH_PWHeatTre_Type.message}
                  handleSelectChange={handleSelectChange}
                  label="Type"
                  array={WPSH_PWHeatTre_Type}
                ></General_Single_Input_select>
              </Col>
              <Col className="mb-1" md={3}>
                <div class="T-input w-100">
                  <input
                    type="text"
                    id="WPSH_PWHeatTre_Heating_Rate"
                    name="WPSH_PWHeatTre_Heating_Rate"
                    //readOnly={isReadonly}
                    maxLength={25}
                    disabled={copydata}
                    placeholder=" "
                    className={
                      error.WPSH_PWHeatTre_Heating_Rate.status
                        ? " T-input placeHolderInput inputText text-field-with-error"
                        : "T-input placeHolderInput inputText"
                    }
                    value={headerObj.WPSH_PWHeatTre_Heating_Rate}
                    onChange={handleChange}
                    onInput={(e) => {
                      let error =  isAlphanumericAllCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPSH_PWHeatTre_Heating_Rate: error,
                      }));
                    }}
                  />
                  <label for="WPSH_PWHeatTre_Heating_Rate">
                    Heating Rate (°C/Hr)
                  </label>
                </div>
                {headerObj.WPSH_PWHeatTre_Temperature !== "" ? (
                  <div className="required-text1">
                    {error.WPSH_PWHeatTre_Heating_Rate.status && (
                      <Form.Text className="text-danger">
                        {error.WPSH_PWHeatTre_Heating_Rate.message}
                      </Form.Text>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <Row className="mb-1">
            <Col className="mb-1" md={3}>
                <General_Single_Input_select
                  name="WPSH_PWHeatTre_Wrap_insulation_material"
                  //readOnly={isReadonly}
                  disabled={isDisabled}
                  value={headerObj.WPSH_PWHeatTre_Wrap_insulation_material}
                  status={error.WPSH_PWHeatTre_Wrap_insulation_material.status}
                  message={error.WPSH_PWHeatTre_Wrap_insulation_material.message}
                  handleSelectChange={handleSelectChange}
                  label="Wrap in Insulation Material"
                  array={WPSH_PWHeatTre_Wrap_insulation_material}
                ></General_Single_Input_select>
              </Col>
              <Col className="mb-1" md={3}>
                <Single_Input
                  disabled={copydata}
                  name="WPSH_PWHeatTre_Cooling_Rate"
                  type="text"
                  maxLength={20}
                  status={error.WPSH_PWHeatTre_Cooling_Rate.status}
                  value={headerObj.WPSH_PWHeatTre_Cooling_Rate}
                  handleChange={handleChange}
                  label=" Cooling Rate (°C/Hr)"
                  message={error.WPSH_PWHeatTre_Cooling_Rate.message}
                  onInput={(e) => {
                    let error =  isAlphanumericAllCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPSH_PWHeatTre_Cooling_Rate: error,
                    }));
                  }}
                ></Single_Input>
              </Col>
              <Col className="mb-1" md={3}>
                <Single_Input
                  disabled={copydata}
                  name="WPSH_PWHeatTre_LoadingTemp"
                  type="text"
                  maxLength={25}
                  status={error.WPSH_PWHeatTre_LoadingTemp.status}
                  value={headerObj.WPSH_PWHeatTre_LoadingTemp}
                  handleChange={handleChange}
                  label=" Loading Temp (°C)"
                  message={error.WPSH_PWHeatTre_LoadingTemp.message}
                  onInput={(e) => {
                    let error =  isAlphanumericAllCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPSH_PWHeatTre_LoadingTemp: error,
                    }));
                  }}
                ></Single_Input>
              </Col>
              <Col className="mb-1" md={3}>
                <Single_Input
                  disabled={copydata}
                  name="WPSH_PWHeatTre_UnloadingTemp"
                  type="text"
                  maxLength={20}
                  status={error.WPSH_PWHeatTre_UnloadingTemp.status}
                  value={headerObj.WPSH_PWHeatTre_UnloadingTemp}
                  handleChange={handleChange}
                  label=" Unloading Temp (°C)"
                  message={error.WPSH_PWHeatTre_UnloadingTemp.message}
                  onInput={(e) => {
                    let error =  isAlphanumericAllCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPSH_PWHeatTre_UnloadingTemp: error,
                    }));
                  }}
                ></Single_Input>
              </Col>
            </Row>
            <Row className="mb-1">
              <Col className="mb-1" md={12}>
                <Single_Input
                  disabled={copydata}
                  name="WPSH_PWHeatTre_PWHTNotes"
                  type="text"
                  maxLength={85}
                  status={error.WPSH_PWHeatTre_PWHTNotes.status}
                  value={headerObj.WPSH_PWHeatTre_PWHTNotes}
                  handleChange={handleChange}
                  label=" PWHT Notes"
                  message={error.WPSH_PWHeatTre_PWHTNotes.message}
                  onInput={(e) => {
                    let error =  isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPSH_PWHeatTre_PWHTNotes: error,
                    }));
                  }}
                ></Single_Input>
              </Col>

            </Row>
          </Row>

          <Row style={{ padding: "0px 60px" , position:"relative", bottom:"30px" }}>
            <span className="WPS-blue-text" style={{position:"relative",right:"12px",top:"10px"}}>Other Variables</span>
            <Row className="mb-1">
              <Col md={6}>
                <div class="T-input w-100">
                  <input
                    type="text"
                    id="WPSH_Tech_Expansion"
                    name="WPSH_Tech_Expansion"
                    //readOnly={isReadonly}
                    maxLength={28}
                    placeholder=" "
                    value={headerObj.WPSH_Tech_Expansion}
                    onChange={handleChange}
                    onInput={(e) => {
                      let error =  isAlphanumericAllCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPSH_Tech_Expansion: error,
                      }));
                    }}
                  />
                  <label for="WPSH_Tech_Expansion">Expansion</label>
                </div>
                {error.WPSH_Tech_Expansion.status && (
                  <Form.Text className="text-danger">
                    {error.WPSH_Tech_Expansion.message}
                  </Form.Text>
                )}
              </Col>
              <Col md={6}>
                <div class="T-input w-100">
                  <input
                    type="text"
                    id="WPSH_Tech_StringWave"
                    name="WPSH_Tech_StringWave"
                    //readOnly={isReadonly}
                    maxLength={28}
                    placeholder=" "
                    value={headerObj.WPSH_Tech_StringWave}
                    onChange={handleChange}
                    onInput={(e) => {
                      let error =  isAlphanumericAllCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPSH_Tech_StringWave: error,
                      }));
                    }}
                  />
                  <label for="WPSH_Tech_StringWave">String & Wave</label>
                </div>
                {error.WPSH_Tech_StringWave.status && (
                  <Form.Text className="text-danger">
                    {error.WPSH_Tech_StringWave.message}
                  </Form.Text>
                )}
              </Col>
            </Row>
            <Row className="mb-1">
              <Col md={6}>
                <div class="T-input w-100">
                  <input
                    type="text"
                    id="WPSH_Tech_MethodofCleaning"
                    name="WPSH_Tech_MethodofCleaning"
                    //readOnly={isReadonly}
                    maxLength={28}
                    placeholder=" "
                    value={headerObj.WPSH_Tech_MethodofCleaning}
                    onChange={handleChange}
                    onInput={(e) => {
                      let error =  isAlphanumericAllCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPSH_Tech_MethodofCleaning: error,
                      }));
                    }}
                  />
                  <label for="WPSH_Tech_MethodofCleaning">
                    Method of Cleaning
                  </label>
                </div>
                {error.WPSH_Tech_MethodofCleaning.status && (
                  <Form.Text className="text-danger">
                    {error.WPSH_Tech_MethodofCleaning.message}
                  </Form.Text>
                )}
              </Col>
              <Col md={6}>
                <div class="T-input w-100">
                  <input
                    type="text"
                    id="WPSH_Tech_OrifceCupSize"
                    name="WPSH_Tech_OrifceCupSize"
                    //readOnly={isReadonly}
                    maxLength={28}
                    placeholder=" "
                    value={headerObj.WPSH_Tech_OrifceCupSize}
                    onChange={handleChange}
                    onInput={(e) => {
                      let error =  isAlphanumericAllCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPSH_Tech_OrifceCupSize: error,
                      }));
                    }}
                  />
                  <label for="WPSH_Tech_OrifceCupSize">Orifce Cup Size(mm)</label>
                </div>
                {error.WPSH_Tech_OrifceCupSize.status && (
                  <Form.Text className="text-danger">
                    {error.WPSH_Tech_OrifceCupSize.message}
                  </Form.Text>
                )}
              </Col>
            </Row>
            <Row className="mb-1">
              <Col md={6}>
                <div class="T-input w-100">
                  <input
                    type="text"
                    id="WPSH_Tech_SingleMultiElectrode"
                    name="WPSH_Tech_SingleMultiElectrode"
                    //readOnly={isReadonly}
                    maxLength={28}
                    placeholder=" "
                    value={headerObj.WPSH_Tech_SingleMultiElectrode}
                    onChange={handleChange}
                    onInput={(e) => {
                      let error =  isAlphanumericAllCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPSH_Tech_SingleMultiElectrode: error,
                      }));
                    }}
                  />
                  <label for="WPSH_Tech_SingleMultiElectrode">
                    Single/Multi Electrode
                  </label>
                </div>
                {error.WPSH_Tech_SingleMultiElectrode.status && (
                  <Form.Text className="text-danger">
                    {error.WPSH_Tech_SingleMultiElectrode.message}
                  </Form.Text>
                )}
              </Col>

              <Col md={6}>
                <div class="T-input w-100">
                  <input
                    type="text"
                    id="WPSH_Tech_Oscillation"
                    name="WPSH_Tech_Oscillation"
                    //readOnly={isReadonly}
                    maxLength={28}
                    placeholder=" "
                    value={headerObj.WPSH_Tech_Oscillation}
                    onChange={handleChange}
                    onInput={(e) => {
                      let error =  isAlphanumericAllCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPSH_Tech_Oscillation: error,
                      }));
                    }}
                  />
                  <label for="WPSH_Tech_Oscillation">Oscillation</label>
                </div>
                {error.WPSH_Tech_Oscillation.status && (
                  <Form.Text className="text-danger">
                    {error.WPSH_Tech_Oscillation.message}
                  </Form.Text>
                )}
              </Col>
            </Row>
            <Row className="mb-1">
              <Col md={6}>
                <div class="T-input w-100">
                  <input
                    type="text"
                    id="WPSH_Tech_Peening"
                    name="WPSH_Tech_Peening"
                    //readOnly={isReadonly}
                    maxLength={28}
                    placeholder=" "
                    value={headerObj.WPSH_Tech_Peening}
                    onChange={handleChange}
                    onInput={(e) => {
                      let error =  isAlphanumericAllCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPSH_Tech_Peening: error,
                      }));
                    }}
                  />
                  <label for="WPSH_Tech_Peening">Peening</label>
                </div>
                {error.WPSH_Tech_Peening.status && (
                  <Form.Text className="text-danger">
                    {error.WPSH_Tech_Peening.message}
                  </Form.Text>
                )}
              </Col>
              <Col md={6}>
                <div class="T-input w-100">
                  <input
                    type="text"
                    id="WPSH_Tech_ClosedtooutChamber"
                    name="WPSH_Tech_ClosedtooutChamber"
                    //readOnly={isReadonly}
                    maxLength={28}
                    placeholder=" "
                    value={headerObj.WPSH_Tech_ClosedtooutChamber}
                    onChange={handleChange}
                    onInput={(e) => {
                      let error =  isAlphanumericAllCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPSH_Tech_ClosedtooutChamber: error,
                      }));
                    }}
                  />
                  <label for="WPSH_Tech_ClosedtooutChamber">
                    Closed To Out Chamber
                  </label>
                </div>
                {error.WPSH_Tech_ClosedtooutChamber.status && (
                  <Form.Text className="text-danger">
                    {error.WPSH_Tech_ClosedtooutChamber.message}
                  </Form.Text>
                )}
              </Col>
            </Row>
            <Row className="mb-1">
              <Col md={6}>
                <div class="T-input w-100">
                  <input
                    type="text"
                    id="WPSH_Tech_UseOfThermalProcess"
                    name="WPSH_Tech_UseOfThermalProcess"
                    //readOnly={isReadonly}
                    maxLength={28}
                    placeholder=" "
                    value={headerObj.WPSH_Tech_UseOfThermalProcess}
                    onChange={handleChange}
                    onInput={(e) => {
                      let error =  isAlphanumericAllCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPSH_Tech_UseOfThermalProcess: error,
                      }));
                    }}
                  />
                  <label for="WPSH_Tech_UseOfThermalProcess">
                    Use Of Thermal Process
                  </label>
                </div>
                {error.WPSH_Tech_UseOfThermalProcess.status && (
                  <Form.Text className="text-danger">
                    {error.WPSH_Tech_UseOfThermalProcess.message}
                  </Form.Text>
                )}
              </Col>
             
            </Row>
          </Row>

       

          <Row style={{ padding: "28px 48px", position:"relative", bottom:"40px" }}>
            <span className="WPS-blue-text" style={{position:"relative",top:"10px"}} >Notes (Only first 6 lines in below Notes will appear in WI Slip)</span>
            <Col md={12}>
              <Single_Input_textaraNotes
                disabled={copydata}
                name="WPSH_Notes"
                type="text"
                style={{ height: "250px", resize: "none" }}
                maxLength={1500}
                //readOnly={isReadonly}
                status={error.WPSH_Notes.status}
                value={headerObj.WPSH_Notes ? headerObj.WPSH_Notes : valueNotes}
                handleChange={handleChangeNotes}
                onKeyDown={handleKeyDown}
                label="Notes"
                message={error.WPSH_Notes.message}
                onInput={(e) => {
                  let error =  isAlphanumericAllSpecialCharacters(e.target.value);
                  setError((prevState) => ({
                    ...prevState,
                    WPSH_Notes: error,
                  }));
                }}
              ></Single_Input_textaraNotes>
            </Col>
          </Row>
          {/* -------------------------prepared by --------------------------------*/}

          <Row style={{ padding: "28px 60px" , position:"relative", bottom:"50px"}}> 
            <Row className="mb-4">
              <Col>
                <span className="WPS-blue-text">
                  Prepared By - Welding Engineer
                </span>
              </Col>
            </Row>
            <Row>
             

<Col md={4}>
                  <Form.Group className="preby-input-select">
                    <div className="search-select">
                      <SearchSelectprename
                        required
                        data={users}
                        valueField={"um_name"}
                        // disabled={Band === "P" || Band === "T"}
                        value={headerObj.WPSH_PreparedBy_Name || ""}
                        labelField={"um_name"}
                        placeholder=""
                        onChange={(prename) => {
                          setheaderObj((prev) => ({
                            ...prev,
                            WPSH_PreparedBy_Name: prename.value,
                          }));

                          let a = users.filter((b) => {
                            return b.um_name === prename.value;
                          });
                          console.log("aaaarrr", a);
                          fetchbyuserprofile(a[0].wld_profile);
                          setheaderObj((prev) => ({
                            ...prev,
                            WPSH_PreparedBy_Name: a[0].um_name,
                            wps_signaturePrepared: a[0].wld_profile,
                            WPSH_PreparedBy: a[0].SK,
                          }));

                        }}
                      ></SearchSelectprename>
                    </div>
                    <label>Prepared By</label>
                    <div className="required-text-select">
                      {error.WPSH_PreparedBy_Name.status && (
                        <Form.Text className="text-danger">
                          {error.WPSH_PreparedBy_Name.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>
                
            
                    <Col md={3} style={{position:"relative",right:"80px"}}>
              <Date_InputUser
                divClass="T-input w-100"
                label="Date"
                className="groove_datecolor"
                name="WPSH_PreparedBy_Date"
                sx={{ fontSize: "6px", fontFamily: "Work Sans", zIndex: "0" }}
                todayDate={todayDate}
   
                obj={headerObj}
                setobj={setheaderObj}
                setError={setError}

                status={error.WPSH_PreparedBy_Date.status}
                message={error.WPSH_PreparedBy_Date.message}
              />
            </Col>

              <Row className="mb-4">
                <Col>
                  <span style={{position:"relative",left:"12px"}}>Signature Image</span>
                </Col>
              </Row>

              <Row style={{position:"relative", bottom:"10px"}}>
                <Col md={6} style={{position:"relative",left:"12px"}}>
                  <div className="signature-box">
                    <div className="imgbox">
                      <img className="imgsizebig1" src={previewuser} />
                    </div>
                  </div>
                </Col>
                <Col style={{position:"relative", left:"28px"}}>
                  <Single_Input
                    disabled={copydata}
                    name="WPSH_PreparedBy_Remark"
                    type="text"
                    style={{ height: "95px" }}
                    maxLength={50}
                    //readOnly={isReadonly}
                    status={error.WPSH_PreparedBy_Remark.status}
                    value={headerObj.WPSH_PreparedBy_Remark}
                    handleChange={handleChange}
                    label="Remark"
                    message={error.WPSH_PreparedBy_Remark.message}
                    onInput={(e) => {
                      let error =  isAlphanumericAllCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPSH_PreparedBy_Remark: error,
                      }));
                    }}
                  ></Single_Input>
                </Col>
              </Row>
            </Row>
          </Row>
          {/* -------------------------approved by --------------------------------*/}
          <Row style={{ padding: "28px 60px" , position:"relative", bottom:"80px"}}>

            <Row className="mb-4">
              <Col>
                <span className="WPS-blue-text">
                  Approved By - Welding Engineer
                </span>
              </Col>
              {Band === "P" || Band === "T" ? (
                <Col style={{position:"relative", top:"36px" , left: "22px"}}>
                <span className="WPS-blue-textt">Action</span>
                </Col>
              ) : (
                ""
              )}
            </Row>
            <Row>
            
<Col md={4}>
                  <Form.Group className="preby-input-select">
                    <div className="search-select">
                      <SearchSelectprename
                        required
                        data={users}
                        valueField={"um_name"}
                        // readOnly={Band !== "P" || Band !== "T"}
                        // disabled={(Band !=="P" || Band !== "T") ? true : false }
                        value={headerObj.WPSH_ApprovedBy_Name}
                        labelField={"um_name"}
                        placeholder=""
                        onChange={(prename) => {
                          setheaderObj((prev) => ({
                            ...prev,
                            WPSH_ApprovedBy_Name: prename.value,
                          }));
                          

                          console.log("ItemList on", prename);
                          let a = users.filter((b) => {
                            return b.um_name === prename.value;
                          });
                          console.log("aaaarrr", a);
                          setheaderObj((prev) => ({
                            ...prev,
                            WPSH_ApprovedBy_Name: a[0].um_name,
                            wps_signatureApproved: a[0].wld_profile,
                            WPSH_ApprovedBy: a[0].SK,
                          }));
  
                          fetchapproveUserProfile(a[0].wld_profile
                          );
                        }}
                      ></SearchSelectprename>
                    </div>
                    <label>Approved By</label>
                    <div className="required-text-select">
                      {error.WPSH_ApprovedBy_Name.status && (
                        <Form.Text className="text-danger">
                          {error.WPSH_ApprovedBy_Name.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>

            

<Col md={3} style={{position:"relative",right:"80px"}}>
              <Date_InputUser
                divClass="T-input w-100"
                label="Date"
                className="groove_datecolor"
                name="WPSH_ApprovedBy_Date"
                sx={{ fontSize: "6px", fontFamily: "Work Sans", zIndex: "0" }}
                // todayDate={todayDate}
                // maxDate={maxDate}
                // disabled={Band !== "P" }
                obj={headerObj}
                setobj={setheaderObj}
                setError={setError}
              
                status={error.WPSH_ApprovedBy_Date.status}
                message={error.WPSH_ApprovedBy_Date.message}
                // datevalue={datevalue}
              />
            </Col>
              {Band === "P" || Band === "T" ? (
                <Col style={{position:"relative",top:"25px", right:"68px"}}>
                <div className="d-flex mb-4">
                    <div>
                      <input
                        type="radio"
                        name="WPSH_Approval_Status"
                        value="Approved"
                        checked={
                          headerObj.WPSH_Approval_Status ===
                          Status.StatusApproved
                        }
                        onChange={(e) => handleChangeRadio(e)}
                      />
                      <span className="ms-2">Approve</span>
                    </div>
                    <div className="ms-5">
                      <input
                        type="radio"
                        name="WPSH_Approval_Status"
                        value="Rejected"
                        checked={
                          headerObj.WPSH_Approval_Status ===
                          Status.StatusRejected
                        }
                        onChange={(e) => handleChangeRadio(e)}
                      />
                      <span className="ms-2">Reject</span>
                    </div>
                  </div>
                </Col>
              ) : (
                ""
              )}

              <Row className="mb-4">
                <Col>
                  <span style={{position:"relative",left:"12px"}}>Signature Image</span>
                </Col>
              </Row>

              <Row style={{position:"relative", bottom:"10px"}}>
                <Col md={6} style={{position:"relative",left:"12px"}}>
                  <div className="signature-box">
                    <div className="imgbox">
                      <img className="imgsizebig1" src={previewapproved} />
                    </div>
                  </div>
                </Col>
                {Band === "P" || Band === "T" ? (
                <Col style={{position:"relative", left:"28px"}}>
                <div class="T-input w-100">
                      <textarea
                        style={{ height: "95px" }}
                        type="textarea"
                        id="WPSH_ApprovedBy_Remark"
                        name="WPSH_ApprovedBy_Remark"
                        maxLength={280}
                        placeholder=" "
                        className={
                          error.WPSH_ApprovedBy_Remark.status
                            ? " T-input placeHolderInput inputText text-field-with-error"
                            : "T-input placeHolderInput inputText"
                        }
                        value={headerObj.WPSH_ApprovedBy_Remark}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error =
                             isAlphanumericAllCharacters(
                              e.target.value
                            );
                          setError((prevState) => ({
                            ...prevState,
                            WPSH_ApprovedBy_Remark: error,
                          }));
                        }}
                      />
                      <label for="WPSH_ApprovedBy_Remark">Remark</label>
                    </div>
                    {headerObj.WPSH_Approval_Status ===
                    Status.StatusApproved ? (
                      <div className="required-text1">
                        {error.WPSH_ApprovedBy_Remark.status && (
                          <Form.Text className="text-danger">
                            {error.WPSH_ApprovedBy_Remark.message}
                          </Form.Text>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                ) : (
                  <Col>
                    <div class="T-input w-100">
                      <textarea
                        style={{ height: "85px" }}
                        type="textarea"
                        id="WPSH_ApprovedBy_Remark"
                        name="WPSH_ApprovedBy_Remark"
                        readOnly
                        maxLength={280}
                        placeholder=" "
                        className={
                          error.WPSH_ApprovedBy_Remark.status
                            ? " T-input placeHolderInput inputText text-field-with-error"
                            : "T-input placeHolderInput inputText"
                        }
                        value={headerObj.WPSH_ApprovedBy_Remark}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error =
                             isAlphanumericAllCharacters(
                              e.target.value
                            );
                          setError((prevState) => ({
                            ...prevState,
                            WPSH_ApprovedBy_Remark: error,
                          }));
                        }}
                      />
                      <label for="WPSH_ApprovedBy_Remark">Remark</label>
                    </div>
                    {headerObj.WPSH_Approval_Status ===
                    Status.StatusApproved ? (
                      <div className="required-text1">
                        {error.WPSH_ApprovedBy_Remark.status && (
                          <Form.Text className="text-danger">
                            {error.WPSH_ApprovedBy_Remark.message}
                          </Form.Text>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                )}
              </Row>
            </Row>
          </Row>
        </Row>
    );
  }
);

export default WPSTubesheetProcedureSpec;
