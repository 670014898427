import { Row, Col, Container, Table } from "react-bootstrap";
export const WPSHeader = (({headerObj})=>{
  return(  <Row style={{ padding: "20px 60px", height: "82px"}} className="WPS-Page-row-padding">
    <Row className="mb-4">
      <Col md={2}>
        <div className="d-flex flex-column">
          <small>Project No.</small>
          <strong>{headerObj.WPSH_Project_No}</strong>
        </div>
      </Col>
      <Col md={2}>
        <div className="d-flex flex-column nowrap" style={{ right:"76px"}}>
          <small>WPS No.</small>
          <strong>{headerObj.WPSH_WPS_No}</strong>
        </div>
      </Col>
      <Col md={2}>
        <div className="d-flex flex-column nowrap" style={{right:"67px"}}>
          <small>WPS Revision No.</small>
          <strong>{headerObj.WPSH_WPS_Revi_No}</strong>
        </div>
      </Col>
      <Col md={2}>
        <div className="d-flex flex-column nowrap" style={{right:"110px"}}>
          <small>WPS Date</small>
          <strong>{headerObj.WPSH_WPS_Date}</strong>
        </div>
      </Col>
      <Col md={2}>
        <div className="d-flex flex-column nowrap" style={{right:"193px"}}>
          <small>Supporting PQR</small>
          <strong>{headerObj.WPSH_SupportingPQR}</strong>
        </div>
      </Col>
    </Row>
    
  </Row>)
})