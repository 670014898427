import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useEffect,
} from "react";
import { Row, Col, Container, Button, Form } from "react-bootstrap";
import ConfirmAlertModal from "../Modals/confirmAlertModal";
import { useLocation, useNavigate, Link } from "react-router-dom";
import GolbalSearchWCS from "../utility/GolbalSearchWCS";
import { handleExport1 } from "../utility/GlobalExport";
import { getGridHeaderData } from "../../Services/wcs-services";
import GlobalColumnDefinations from "../utility/AgGridDefination";
import { getHeader_FieldName } from "../utility/AgGridDefination";
import "react-toastify/dist/ReactToastify.css";
import GridMaster from "../utility/GridMaster";
import Toaster from "../utility/Toaster";
import Export_blue from "../../icons/Export_blue.svg";
import ExportIcon from "../../icons/ExportIcon.svg";
import BarLoader from "react-spinners/BarLoader";
import { isAlphanumericWithDash } from "../../functions/validations";
import { SearchSelectProject } from "../utility/search-select";
import { Action, Status } from "./WCSObjects/WCS_entity";
import AddEditWCS from "./AddEditWCS";
import { getAllOnlyProject } from "../../Services/project-services";
import { getWPSNo } from "../../Services/wps_groove-services";
import UploadWCSExcel from "./UploadWCSExcel";
import { toast } from "react-toastify";
import { getGridDetailData } from "../../Services/wcs-services";
import WCSProjectRenderer from "../utility/WCSProjectRenderer";

const WCSDashboard = forwardRef((props, ref) => {
  const location = useLocation();

  const gridRef = useRef();
  const [originalaction, setoriginalaction] = useState(location.state?.action);
  let Band = sessionStorage.getItem("Band");
  const [headerObj, setheaderObj] = useState([]);

  let initiator = Band !== "P" && Band !== "T";
  const [rowData, setRowData] = useState({});
  const [filteredResult, setFilteredResult] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [WCS, setWCS] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [action, setAction] = useState("");
  const [reload, setReload] = useState(false);
  const [filteredResulttemp, setFilteredResulttemp] = useState([]);
  const [filterColLength, setFilterColLength] = useState(0);
  const [excelData, setExcelData] = useState([]);
  const [showDetailForm, setShowDetailForm] = useState(false);
  const [WPSNoList, setWPSNoList] = useState("");
  const [selectProject, setSelectProject] = useState(headerObj.WCS_Project_No);

  var disableButton =
    initiator && headerObj.WPSH_Status === Status.StatusPending ? true : false;
  const [isDisabled, setIsDisabled] = useState(disableButton);
  const [showUploadExcelForm, setshowUploadExcelForm] = useState(false);
  const navigate = useNavigate();

  let userID = sessionStorage.getItem("User");

  const [loading, setLoading] = useState(true);
  const [ProjectDetails, setProjectDetails] = useState([]);

  const [normalstyle, setNormal] = useState({
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: "var(--col-ddd)",
    }),
  });
  const [errorstyle, seterrorstyle] = useState({
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: "var(--godrej-red-alt)",
    }),
  });

  const [error, setError] = useState({
    WCS_Project_No: {},
  });



  const HyperLink = (params) => {
    return (
      <Link
        to="/WCSdetail"
        state={{ data: params.data.SK }}
        className="add-new-link"
      >
        {params.data.SK}
      </Link>
    );
  };

  const childRef = useRef();

  const tmpcolumnDefs = [
    {
      headerName: "Status",
      field: "WCS_Status",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "WPSCellRenderer",
    },
    {
      headerName: "Project No.",
      field: "WCS_Project_No",
      cellRenderer: HyperLink,
      
    },
    {
      headerName: "WCS Change No.",
      field: "WCS_Change_No",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Prepared by",
      field: "WCS_PreparedBy_Name",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "AvatarCellRenderer",
    },
    {
      headerName: "Approved by",
      field: "WCS_ApprovedBy_Name",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "AvatarCellRenderer",
    },
  ];

  const defaultColDef = useMemo(() => {
    return {
      suppressMovable: true,
      sortable: true,
      textAlign: "center",
      filterParams: {
        buttons: ["reset", "apply"],
        closeOnApply: true,
        defaultJoinOperator: "OR",
      },
    };
  }, []);

  const handleHyperlink = (row) => {
    console.log("row_edit", row);
    navigate("/WCSDetail", {
      state: {
        data: row,
        action: "Trainee",
      },
    });
    console.log("rowdata_wcs", rowData);
  };

  const handleView = (row) => {
    console.log("row", row);
    setAction("View");
    setRowData(row);
    setShowDetailForm(true);
  };



  const headerName = (field) => {
    let name = getHeader_FieldName(field, tmpcolumnDefs, "getHeader");
    return name;
  };

  const fieldName = (header) => {
    let name = getHeader_FieldName(header, tmpcolumnDefs, "getField");
    return name;
  };

  const fetchWCS = async () => {
    var result = await getGridHeaderData(
      sessionStorage.getItem("Band"),
      sessionStorage.getItem("User")
    );
    setWCS(result);
    setLoading(false);
    setFilteredResult(result);
  };

  const fetchColumnDefns = async () => {
    const result = GlobalColumnDefinations(tmpcolumnDefs);
    result[1].cellRenderer = HyperLink;
    setColumnDefs(result);
  };


  useEffect(() => {
    fetchWCS();
    fetchColumnDefns();
    fetchProject();
  }, [reload]);

  useImperativeHandle(ref, () => ({
    handleAdd: async () => {
    setAction("Add");
      if (
        headerObj?.WCS_Project_No !== "" &&
        headerObj?.WCS_Project_No !== undefined &&
        headerObj?.WCS_Project_No !== null
      ) {
        setShowForm(true);
      } else {
        setShowForm(false);
        toast.error(
          `Please select project number and click on show result before adding data .`,
          {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          }
        );
      }
      setReload(true);
    },
  }));

  useEffect(() => {
    // fetchWPSNo();
  }, [selectProject]);

  const resetForm = () => {
    document.filterForm.reset();
    setFilteredResult(WCS);
  };

  const handleClose = () => {
    setShow1(false);
  };

  const validateProject = (action) => {
    let result = false;
    let isError = false;

    if (headerObj?.WCS_Project_No.trim() === "") {
      headerObj.WCS_Project_No = "";
      isError = true;
      error.WCS_Project_No = {
        status: true,
        message: "Project Number is required.",
      };
    }

    setError((prevState) => ({
      ...prevState,
      ...error,
    }));
    if (error.WCS_Project_No.status) {
      isError = true;
    }
    return isError;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      headerObj?.WCS_Project_No !== "" &&
      headerObj?.WCS_Project_No !== undefined &&
      headerObj?.WCS_Project_No !== null
    ) {
      if (!validateProject(action)) {
        handleGlobalSearch(headerObj.WCS_Project_No);
        setSelectProject(headerObj.WCS_Project_No);
      }
    } else {
      toast.error(`Please select project number and click on show result .`, {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    }
  };

  const handleGlobalSearch = (text) => {
    let searchText = text?.toLowerCase();
    if (searchText) {
      childRef.current.setSearch(searchText);
    }
  };

  const actionWCSProjectRenderer = (params) => {
    console.log("ABC", params);
    console.log("xyz", params.data);
    return (
      <WCSProjectRenderer
        selectedData={params}
        handleViewEvent={(params) => {
          handleView(params.data);
        }}
        handleHyperlink={(params) => handleHyperlink(params.data)}
        handleGlobalExport={(params) => handleExport(params.data)}
      />
    );
  };



  const handleExport = (row) => {
    console.log("row", row);
    setAction("Trainee");
    setRowData(row);
    handleGlobalExport(row);
  };

  const handleGlobalExport = (selectedRow) => {
    let filename = "WCSProjectList";
    setExcelData(filteredResult);
    let result = handleExport1(filteredResult, filename, tmpcolumnDefs);
  };

  const [showSuccess, setShowSuccess] = useState(false);
  const [msg, setMsg] = useState("");
  const history = useNavigate();
  const [show1, setShow1] = useState(false);

  const fetchProject = async () => {
    //Review use GetAllProjects
    var result = await getAllOnlyProject();
    console.log("Project", result);
    setProjectDetails(result);
  };

  return (
    <Container fluid>
      <ConfirmAlertModal
        action={action}
        handleYes={handleView}
        show={show1}
        handleClose={handleClose}
      />

      <Row className="RowHeight">
        <Col md={2}>
          <Form.Group className="pro-input-select">
            <div>
              <SearchSelectProject
                id="WCS_Project_No"
                name="WCS_Project_No"
                className={error.WCS_Project_No.status ? "err-input" : ""}
                stylegreen={
                  error.WCS_Project_No.status ? errorstyle : normalstyle
                }
                data={ProjectDetails}
                stylenormal={normalstyle}
                valueField={"wld_ProjectNumber"}
                disabled={action === Action.ActionEdit ? true : false}
                value={headerObj.WCS_Project_No}
                labelField={"wld_ProjectNumber"}
                placeholder=""
                onChange={(ProjectList) => {
                  console.log("ProjectList", ProjectList);
                  setheaderObj((prev) => ({
                    ...prev,
                    WCS_Project_No: ProjectList.value,
                  }));
                  setSelectProject(ProjectList.value);
                  setError((prevState) => ({
                    ...prevState,
                    WCS_Project_No: {},
                  }));
                }}
                onInput={(e) => {
                  let error = isAlphanumericWithDash(e.target.value);
                  setError((prevState) => ({
                    ...prevState,
                    WCS_Project_No: error,
                  }));
                }}
              ></SearchSelectProject>
            </div>
            <label>Project Number</label>
            <div className="required-text-select">
              {error.WCS_Project_No.status && (
                <Form.Text className="text-danger">
                  {error.WCS_Project_No.message}
                </Form.Text>
              )}
            </div>
          </Form.Group>
        </Col>
        <Col md={4}>
          <Button className="showresult" onClick={handleSubmit} style={{width:"109px",height:"40px"}}>
            <p style={{fontSize:"12px", position:"relative",top:"10px"}}>Show Result</p >
          </Button>
        </Col>

        <Col md={6}>
          <div className="wcs-search-container">
            <GolbalSearchWCS
              searchplaceholdertext={"Search for Project No.,WCS Change No."}
              filterFunc={handleGlobalSearch}
            />
            <img
              className="allexport"
              src={ExportIcon}
              onMouseOver={(e) => (e.currentTarget.src = Export_blue)}
              onMouseOut={(e) => (e.currentTarget.src = ExportIcon)}
              alt="ExportIcon"
              onClick={handleGlobalExport}
            />
          </div>
        </Col>
      </Row>

      {loading && (
        <BarLoader  height={5} width={195} className="gridloader" />
      )}

      <GridMaster
        rowDetails={filteredResult}
        colDetails={columnDefs}
        fieldNames={fieldName}
        headerNames={headerName}
        getDataEvent={(getFilteredData) =>
          setFilteredResulttemp(getFilteredData)
        }
        getFilterColLength={(getLength) => setFilterColLength(getLength)}
        setExcelData={setExcelData}
        ref={childRef}
      />

      {showForm && (
        <AddEditWCS
          show={showForm}
          action={action}
          rowdata={rowData}
          onHide={() => setShowForm(false)}
          resetForm={resetForm}
          setReload={setReload}
          reload={reload}
          headerObj={headerObj}
          setheaderObj={setheaderObj}
          allRecs={WCS}
          backdrop="static"
          selectProject={selectProject}
          WPSNoList={WPSNoList}
        />
      )}
      {showUploadExcelForm && (
        <UploadWCSExcel
          show={showUploadExcelForm}
          action={action}
          rowdata={rowData}
          ProjNo={headerObj.WCS_Project_No}
          onHide={() => setshowUploadExcelForm(false)}
          resetForm={resetForm}
          setReload={setReload}
          reload={reload}
          headerObj={headerObj}
          setheaderObj={setheaderObj}
          allRecs={WCS}
          backdrop="static"
        />
      )}

      <Toaster />
      <Row style={{ padding: "5px 60px" }}>
        <div>
          {isDisabled ? (
            ""
          ) : (
            <Button
              size="sm"
              variant="outline-primary"
              className="me-2"
              disabled={isDisabled}
            >
              Prev
            </Button>
          )}

          {isDisabled ? (
            ""
          ) : (
            <Button size="sm" className="float-end  me-2">
              {"Send Approval"}
            </Button>
          )}

          {isDisabled ? (
            ""
          ) : originalaction !== Action.ActionAdd ? (
            ""
          ) : (
            <Button
              size="sm"
              variant="outline-primary "
              className="float-end  me-2"
            >
              {"Send Approval"}
            </Button>
          )}
        </div>
      </Row>
    </Container>
  );
});

export default WCSDashboard;
