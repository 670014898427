import React, { useState, forwardRef, useEffect } from "react";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SearchSelectReportField } from "../utility/search-select";
import { ExportToExcel } from "./WQChart_Excel";
import { Date_Input } from "./WQChartExcel_Headers";
import { todayDate } from "../utility/CommonComponents/TodayDate";
import {
  WQCexportreport,
  getRequiredWPQNo,
  getRequiredWQCReport,
} from "../../Services/wpq_groove-services";
import { isRealNumber } from "../../functions/validations";
import { getActiveWelder, getRequiredActiveWelder } from "../../Services/Welder-services";

const WQChartReport = forwardRef((props, ref) => {
  const [reload, setReload] = useState(false);
  const [getreport, setgetreport] = useState([]);
  const [welderDetails, setWelderDetails] = useState([]);
  const [wpqData, setWpqData] = useState([]);


  const [normalstyle, setNormal] = useState({
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: "var(--col-ddd)",
    }),
  });

  let Data = [];



  const [datevalue, setdatevalue] = useState(false);

  const [wqObj, setwqObj] = useState({
    WQC_Joint_Type: "",
    WQC_WelderId: "",
    WQC_WPQRecordNo: "",
    WQC_WPQProcess: "",
    WQC_From_Date: "",
    WQC_To_Date: "",
    WQC_Inspection: "",
    WQC_Pipe_Diam: "",
    WQC_PlantLocation: "",
    WQC_Process_Status: "",
  });

  const [error, setError] = useState({
    WQC_Joint_Type: {},
    WQC_WelderId: {},
    WQC_WPQRecordNo: {},
    WQC_WPQProcess: {},
    WQC_From_Date: {},
    WQC_To_Date: {},
    WQC_Inspection: {},
    WQC_Pipe_Diam: {},
    WQC_PlantLocation: {},
    WQC_Process_Status: {},
  });

  console.log("wqObj_wqObj_", wqObj);

  console.log("errroorrr", error);

  var ProcessStatus = [
    { value: "Active", name: "Active" },
    { value: "Inactive", name: "Inactive" },
  ];


   var PlantLoc = [
    { value: "", name: "Select" },
    { value: "PED-Vikhroli", name: "PED-Vikhroli" },
    { value: "PED-Dahej", name: "PED-Dahej" },
    { value: "PED", name: "PED" },
  
  ];

  var Jointstatus = [
    { value: "Groove", name: "Groove" },
    { value: "Overlay", name: "Overlay" },
    { value: "Tubesheet", name: "Tube to tubesheet" },
  ];

  let reporName = "WQChartReport";



  const fetchWelder = async () => {
    console.log("d")
    const result = await getActiveWelder();
    // Check if wld_salarycode is empty, if so, use wld_code as wld_salarycode
    const modifiedResult = result.map(welder => ({
        ...welder,
        wld_salarycode: welder.wld_salarycode || welder.wld_code,
    }));
    setWelderDetails(modifiedResult);
    console.log("Modified Result:", modifiedResult);
};

  console.log("asdfafafaaf",welderDetails)

  const fetchWPQDetails = async () => {
    var result = await getRequiredWPQNo();
    setWpqData(result);
  };


  console.log("just_added_wpqNo", wpqData);


  const selectedWPQNo = wpqData?.filter(
    (item) =>
      item.WPQ_RecordNo !== "" &&
      item.WPQ_RecordNo !== "undefined" &&
      item.WPQ_RecordNo !== undefined
  );

  console.log("selectedWPQNo", selectedWPQNo);

  const selectedInspection = wpqData?.filter(
    (item) =>
      item.WPQ_Inspection !== "" &&
      item.WPQ_Inspection !== "undefined" &&
      item.WPQ_Inspection !== undefined
  );

  console.log("selectedInspection", selectedInspection);

  const uniqueData = selectedInspection.filter((value, index, self) =>
  index === self.findIndex((obj) => obj.WPQ_Inspection === value.WPQ_Inspection)
);

console.log("uniqueData",uniqueData); 

  console.log("selectedWPQNo", selectedWPQNo);

  console.log("fetchWelder_welderDetails", welderDetails);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setwqObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    fetchWelder();
    fetchWPQDetails();
  }, []);

  useEffect(() => {
    if (reload) {
      setReload(false);
    }
  }, [reload]);

  const getEachProcess = () => {
    let separatedArray;
    console.log("getEachProcess_wpqData", wpqData);

    const selectedJointProcess = wpqData?.find(
      (item) =>
        item.WPQ_WelderId === wqObj.WQC_WelderId &&
        item.WPQ_RecordNo === wqObj.WQC_WPQRecordNo
    );
    console.log("getEachProcess_selectedJointProcess", selectedJointProcess);

    if (selectedJointProcess) {
      const showProcess = selectedJointProcess?.WPSH_WPQ_Process;
      console.log("wpqNo_1", showProcess);

      if (
        showProcess !== null &&
        showProcess !== undefined &&
        showProcess !== ""
      ) {
        separatedArray = showProcess?.split(",").filter(function (el) {
          return el !== null && el !== undefined && el !== "";
        });
      }

      if (separatedArray && separatedArray.length > 0) {
        return separatedArray.map((eachProcess) => ({
          label: eachProcess,
          value: eachProcess,
        }));
      }
    }

    return [];
  };

  const validate = async (e) => {
    var isError = false;

    if (wqObj.WQC_From_Date !== "" && wqObj.WQC_To_Date !== "") {
      if (wqObj.WQC_From_Date > wqObj.WQC_To_Date) {
        console.log("datee", wqObj.WQC_From_Date > wqObj.WQC_To_Date);

        isError = true;
        error.WQC_From_Date = {
          status: true,
          // message:"Selected date cannot be less than From date"
        };
        error.WQC_To_Date = {
          status: true,
          // message:"Selected date cannot be less than From date"
        };
        toast.warning("ToDate Should be Greater than or Equal to FromDate", {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        });
      }
    }

    return isError;
  };

  const handleSubmit = async (e) => {
    if (!(await validate())) {
      if (wqObj.WQC_Joint_Type === "") {
        toast.warning("Joint Type is Mandatory", {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        });
      }

      if (wqObj.WQC_Joint_Type !== "" && error.WQC_Pipe_Diam.status !== true) {
        const result = await WQCexportreport(wqObj);
        console.log("rressMiss", result);
        setgetreport(result);

        let filename = "WQC-" + wqObj.WQC_Joint_Type + "";

        let sheetName = "Report_WQChart";
        let excelHeader 
        if(wqObj.WQC_Joint_Type === "Overlay"){
         excelHeader =
          "Welder's / Welding Operator's Qualifications Chart-Overlay";
        }
        else if (wqObj.WQC_Joint_Type === "Tubesheet"){
          excelHeader =
          "Welder's / Welding Operator's Qualifications Chart- Tube to Tubesheet";
        }
        else{
          excelHeader =
          "Welder's / Welding Operator's Qualifications Chart-Groove/Fillet";
        }

        console.log("FDFf",todayDate)

        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
        const day = String(currentDate.getDate()).padStart(2, "0");
      
        // const formattedDate = `${year}-${month}-${day}`;
      
        const formattedDate = `${day}-${month}-${year}`;

        let PrintDate = `As on: ${formattedDate}`
        let displayNote = "";

        let titleCell
        let titleWelderRange 
        let tbodyHeadRange
        let tbodyRange 
        let blankRow 
        let width 

        if (result?.length > 0) {
          console.log("result?.length", result?.length);

          console.log("Dataaaaa", Data);

      
          if(wqObj.WQC_Joint_Type === "Tubesheet"){

            result.forEach((row) => {
              console.log("row_row2222", row.WPQD_WP_Type);
        

              let amp1 = row?.WPQD_WP_Amperes  === undefined ?  "" : row?.WPQD_WP_Amperes[0]?.Value_A[0]
              let amp2 = row?.WPQD_WP_Amperes === undefined ? "" : row?.WPQD_WP_Amperes[1]?.Value_B[0]

              let pc1 = row?.WPQD_WP_PolarityOrCurrent  === undefined ?  "" : row?.WPQD_WP_Amperes[0]?.Value_A[0]
              let pc2 = row?.WPQD_WP_PolarityOrCurrent === undefined ? "" : row?.WPQD_WP_Amperes[1]?.Value_B[0]

              const slipDate = new Date(row.WPQ_Date);
              const formattedSlipDate = slipDate.toLocaleDateString("en-GB");
              const validUptoDate = new Date(
                slipDate.getTime() + 178 * 24 * 60 * 60 * 1000
              );
              const formattedValidUpto = validUptoDate.toLocaleDateString("en-GB")

              console.log("dddddsaddsdd",amp1)
              Data.push({
                A: "",
                B: row.WPQ_WelderId,
                C: row.WPQ_RecordNo,
                D: row.WPQ_WelderName,
                E: row.WPSH_WPQ_Process,
                F: row.WPQD_WP_Type,
                G: formattedValidUpto,
                H: row.WPQ_Width_Ligament[1].Range_Qualified[0],
                I: "Tube to Tubesheet",
                J: row.WPQ_BM_WTBM_Dia,
                K: row.WPQ_BM_WTBM_Thk,
                L: row.WPQ_BM_RangeQualified_Thk,
                M: row.WPQ_BM_RangeQualified_Dia,
                N: row.WPQ_Tube_Pitch[1].Range_Qualified[0],
                O: row.WPQ_Groove_Depth[1].Range_Qualified[0],
                P: row.WPQ_Tube_Projection[1].Range_Qualified[0],
                Q: row.WPQ_Width_Ligament[1].Range_Qualified[0],
                R: row.WPQ_Pattern[1].Range_Qualified[0],
                S: row.WPQ_Groove_Angle[1].Range_Qualified[0],
                T: row.WPQ_BM_BM_Pno,
                U: row.WPSD_FM_FNo, 
                V: row.WPSD_FM_Ano, 
                W: row.WPQ_FM_PreFillerMetal,
                X: row.WPQ_FM_WithFillerMetal,
                Y: row.WPQ_FM_FillerProduct,
                Z: row.WPQ_FM_Inserts,
                AA: row.WPQD_WP_NoofPasses,
                AB: row.WPQD_WP_WeldPosition,
                AC: row.WPQD_WP_WeldProgression,
                AD: pc1 === "" && pc2 === "" ? "" : `${pc1+"/"+pc2}`,
                AE:row.WPQD_WP_Automatic_Arc_Voltage,
                AF: amp1 === undefined || amp1 === "" ? "" : amp1,
                AG: amp2 === undefined || amp2 === "" ? "" : amp2,
              });
              console.log("Dataaaaa", Data);
            });


            titleCell = "E3";
            titleWelderRange = "G1:Y5";
            tbodyHeadRange = "A6:AG6";
            tbodyRange = "A7";
            blankRow = "A5:AC5";
            width = [
              { col: "A", width: 2.5 },
              { col: "B", width: 13.7 },
              { col: "C", width: 13.7 },
              { col: "D", width: 30 },
              { col: "E", width: 8.5 },
              { col: "F", width: 12 },
              { col: "G", width: 16 },
              { col: "H", width: 30 },
              { col: "I", width: 30 },
              { col: "J", width: 30 },
              { col: "K", width: 30 },
              { col: "L", width: 30 },
              { col: "M", width: 30 },
              { col: "N", width: 30 },
              { col: "O", width: 30 },
              { col: "P", width: 30 },
              { col: "Q", width: 30 },
              { col: "R", width: 20 },
              { col: "S", width: 20 },
              { col: "T", width: 20 },
              { col: "U", width: 20 },
              { col: "V", width: 20 },
              { col: "W", width: 30 },
              { col: "X", width: 30 },
              { col: "Y", width: 30 },
              { col: "Z", width: 30 },
              { col: "AA", width: 30 },
              { col: "AB", width: 30 },
              { col: "AC", width: 38 },
              { col: "AD", width: 38 },
              { col: "AE", width: 38 },
              { col: "AF", width: 38 },
              { col: "AG", width: 38 },

            ];
          }
          
          else if(wqObj.WQC_Joint_Type === "Overlay"){


            result.forEach((row) => {
              console.log("row_row1111", row);

              
            const slipDate = new Date(row.WPQ_Date);
            const formattedSlipDate = slipDate.toLocaleDateString("en-GB");
            const validUptoDate = new Date(
              slipDate.getTime() + 178 * 24 * 60 * 60 * 1000
            );
            const formattedValidUpto = validUptoDate.toLocaleDateString("en-GB")

              const WQC_Process = (row.WQC_Process || "").split('|').filter(Boolean);
              const WQC_Type = (row.WQC_Type || "").split('|').filter(Boolean);
              const WQC_Pcat = (row.WQC_Pcat || "").split('|').filter(Boolean);
              const WQC_Arc = (row.WQC_Arc || "").split('|').filter(Boolean);
              const WQC_DetBack = (row.WQC_DetBack || "").split('|').filter(Boolean);
              const WQC_DepThick = (row.WQC_DepThick || "").split('|').filter(Boolean);
  
              const WQC_Prog = (row.WQC_Prog || "").split('|').filter(Boolean);
              const WQC_BackGas = (row.WQC_BackGas || "").split('|').filter(Boolean);
              const WQC_DirVisCon =( row.WQC_DirVisCon || "").split('|').filter(Boolean);
              const WQC_AutJoiTrac = (row.WQC_AutJoiTrac || "").split('|').filter(Boolean);
              const WQC_FillWire = (row.WQC_FillWire || "").split('|').filter(Boolean);
              const WQC_AVCSys = (row.WQC_AVCSys || "").split('|').filter(Boolean);
              const WQC_Fno = (row.WQC_Fno || "").split('|').filter(Boolean);
              const WQC_ConIns = (row.WQC_ConIns || "").split('|').filter(Boolean);
              const WQC_Fmproform = (row.WQC_Fmproform || "").split('|').filter(Boolean);
              const WQC_CurrPol =( row.WQC_CurrPol || "").split('|').filter(Boolean);
              const WQC_SinPerside = (row.WQC_SinPerside || "").split('|').filter(Boolean);
              const WQC_Gv610 = (row.WQC_Gv610 || "").split('|').filter(Boolean);
              const WQC_Gv73to610 = (row.WQC_Gv73to610 || "").split('|').filter(Boolean);
              const WQC_Gv73 = (row.WQC_Gv73 || "").split('|').filter(Boolean);
              const WQC_Oy610 = (row.WQC_Oy610 || "").split('|').filter(Boolean);
              const WQC_Oy73to610 = (row.WQC_Oy73to610 || "").split('|').filter(Boolean);
              const WQC_Oy73 = (row.WQC_Oy73 || "").split('|').filter(Boolean);
              const WQC_Fl610 = (row.WQC_Fl610 || "").split('|').filter(Boolean);
              const WQC_Fl73to610 = (row.WQC_Fl73to610 || "").split('|').filter(Boolean);
              const WQC_Fl73 = (row.WQC_Fl73 || "").split('|').filter(Boolean);
  
  
              const length = Math.min(WQC_Process.length);
            console.log("llll",length)
              for (let i = 0; i < length; i++) {
                Data.push({
                  A: "",
                  B: row.WPQ_WelderId,
                  C: row.WPQ_RecordNo,
                  D: row.WPQ_WelderName,
                  E: (WQC_Pcat[i] || "").trim(),
                  F: (WQC_Type[i] || "").trim(),
                  G: formattedValidUpto,
                  H: row.WPQ_BM_BM_Pno,
                  I: (WQC_Fno[i] || "").trim(),
                  J: (WQC_Oy610[i] || "").trim(),
                  K: (WQC_Oy73to610[i] || "").trim(),
                  L: (WQC_Oy73[i] || "").trim(),
                  M: row.WPQ_BM_WTBM_Thk,
                  N: row.WPQ_BMT_Pipe_Diameter_RangeQ_Overlay,          
                  O: (WQC_DetBack[i] || "").trim(),
                  P: (WQC_BackGas[i] || "").trim(),
                  Q: (WQC_Prog[i] || "").trim(),
                  R: row.WPQ_Inspection,
                  S: (WQC_Arc[i] || "").trim(),
                  T: (WQC_DirVisCon[i] || "").trim(),
                  U: (WQC_AutJoiTrac[i] || "").trim(),
                  V: (WQC_FillWire[i] || "").trim(),
                  W: (WQC_ConIns[i] || "").trim(),
                  X: (WQC_Fmproform[i] || "").trim(),
                  Y: (WQC_CurrPol[i] || "").trim(),
                  Z: (WQC_SinPerside[i] || "").trim(),
                });
              }
            });
            
          
          
          // result.forEach((row) => {
          //   //sconsole.log("row_row", row);
          //   Data.push({
          //     A: "",
          //     B: row.WPQ_WelderId,
          //     C: row.WPQ_RecordNo,
          //     D: row.WPQ_WelderName,
          //     E: row.WPQ_Process,
          //     F: row.WPQ_Type,
          //     G: row.WPQ_Date,
          //     H: row.WPQ_BM_BM_Pno,
          //     I: row.WPQ_FM_F_No,
          //     J:  row.WPQ_Overlay_610,
          //     K: row.WPQ_Overlay_73to610,
          //     L: row.WPQ_Overlay_73,
          //     M: row.WPQ_BM_WTBM_Thk,
          //     N: row.WPQ_BMT_Pipe_Diameter_RangeQ_Overlay, 
          //     // Q: row.WPQ_BMT_Pipe_Diameter_RangeQ_Groove, 
          //     O: row.WPQ_Detail_Backing,
          //     P: row.WPQ_FM_Backing_Gas,
          //     Q: row.WPQ_FM_Progression,
          //     R: row.WPQ_Inspection,
          //     S: row.WPQ_FM_Arc_transfermode,
          //     T: row.WPQ_FM_Direct_VisualControl,
          //     U: row.WPQ_FM_Automatic_jointTracking,
          //     V: row.WPQ_FM_filler_Wire,
          //     W: row.WPQ_FM_Consumable_Insert,
          //     X: row.WPQ_FM_Automatic_AVC_System,
          //     Y: row.WPQ_FM_Current_Polarity,
          //     Z: row.WPQ_FM_Single_Pass_PerSide,
          //   });
          //   console.log("Dataaaaa", Data);
          // });
        

            titleCell = "E3";
            titleWelderRange = "G1:Y5";
            tbodyHeadRange = "A6:Z6";
            tbodyRange = "A7";
            blankRow = "A5:AC5";
            width = [
            { col: "A", width: 2.5 },
            { col: "B", width: 13.7 },
            { col: "C", width: 13.7 },
            { col: "D", width: 20.7 },
            { col: "E", width: 8.5 },
            { col: "F", width: 12 },
            { col: "G", width: 16 },
            { col: "H", width: 30 },
            { col: "I", width: 10 },
            { col: "J", width: 30 },
            { col: "K", width: 30 },
            { col: "L", width: 30 },
            { col: "M", width: 30 },
            { col: "N", width: 30 },
            { col: "O", width: 30 },
            { col: "P", width: 30 },
            { col: "Q", width: 30 },
            { col: "R", width: 20 },
            { col: "S", width: 20 },
            { col: "T", width: 20 },
            { col: "U", width: 20 },
            { col: "V", width: 20 },
            { col: "W", width: 30 },
            { col: "X", width: 30 },
            { col: "Y", width: 30 },
            { col: "Z", width: 30 },
            { col: "AA", width: 30 },
            { col: "AB", width: 30 },
            { col: "AC", width: 38 },
          ];
        }
        else {
     

          result.forEach((row) => {
            console.log("row_row", row);


            let WQC_Process 

            const slipDate = new Date(row.WPQ_Date);
            const formattedSlipDate = slipDate.toLocaleDateString("en-GB");
            const validUptoDate = new Date(
              slipDate.getTime() + 178 * 24 * 60 * 60 * 1000
            );
            const formattedValidUpto = validUptoDate.toLocaleDateString("en-GB");
                 
          if(wqObj?.WQC_WPQProcess === row.Process1 && wqObj?.WQC_WPQProcess !== row.Process2  && wqObj?.WQC_WPQProcess !== row.Process3){
            console.log("aaaaaaaa");

            WQC_Process = (row.Process1 || "").split('|').filter(Boolean);
          }
          else if(wqObj?.WQC_WPQProcess !== row.Process1 && wqObj?.WQC_WPQProcess === row.Process2  && wqObj?.WQC_WPQProcess !== row.Process3 ){
            console.log("bbbb");

            WQC_Process = (row.Process2 || "").split('|').filter(Boolean);
          }
          else if(wqObj?.WQC_WPQProcess !== row.Process1 && wqObj?.WQC_WPQProcess !== row.Process2  && wqObj?.WQC_WPQProcess === row.Process3 ){
            console.log("ccccc");

            WQC_Process = (row.Process3 || "").split('|').filter(Boolean);
          }
          else{
            console.log("bbdbdbdbdb");

            WQC_Process = (row.WQC_Process || "").split('|').filter(Boolean);
            
          }
            const WQC_Type = (row.WQC_Type || "").split('|').filter(Boolean);
            const WQC_Pcat = (row.WQC_Pcat || "").split('|').filter(Boolean);
            const WQC_Arc = (row.WQC_Arc || "").split('|').filter(Boolean);
            const WQC_DetBack = (row.WQC_DetBack || "").split('|').filter(Boolean);
            const WQC_DepThick = (row.WQC_DepThick || "").split('|').filter(Boolean);
            const WQC_Prog = (row.WQC_Prog || "").split('|').filter(Boolean);
            const WQC_BackGas = (row.WQC_BackGas || "").split('|').filter(Boolean);
            const WQC_DirVisCon = (row.WQC_DirVisCon || "").split('|').filter(Boolean);
            const WQC_AutJoiTrac = (row.WQC_AutJoiTrac || "").split('|').filter(Boolean);
            const WQC_FillWire = (row.WQC_FillWire || "").split('|').filter(Boolean);
            const WQC_AVCSys = (row.WQC_AVCSys || "").split('|').filter(Boolean);
            const WQC_Fno = (row.WQC_Fno || "").split('|').filter(Boolean);
            const WQC_ConIns = (row.WQC_ConIns || "").split('|').filter(Boolean);
            const WQC_Fmproform = (row.WQC_Fmproform || "").split('|').filter(Boolean);

            const WQC_CurrPol = (row.WQC_CurrPol || "").split('|').filter(Boolean);
            const WQC_SinPerside = (row.WQC_SinPerside || "").split('|').filter(Boolean);
            const WQC_Gv610 = (row.WQC_Gv610 || "").split('|').filter(Boolean);
            const WQC_Gv73to610 = (row.WQC_Gv73to610 || "").split('|').filter(Boolean);
            const WQC_Gv73 = (row.WQC_Gv73 || "").split('|').filter(Boolean);
            const WQC_Oy610 = (row.WQC_Oy610 || "").split('|').filter(Boolean);
            const WQC_Oy73to610 = (row.WQC_Oy73to610 || "").split('|').filter(Boolean);
            const WQC_Oy73 = (row.WQC_Oy73 || "").split('|').filter(Boolean);
            const WQC_Fl610 = (row.WQC_Fl610 || "").split('|').filter(Boolean);
            const WQC_Fl73to610 = (row.WQC_Fl73to610 || "").split('|').filter(Boolean);
            const WQC_Fl73 = (row.WQC_Fl73 || "").split('|').filter(Boolean);


            const length = Math.min(WQC_Process.length);
          console.log("llll",length)
            for (let i = 0; i < length; i++) {
              
              Data.push({
                A: "",
                B: row.WPQ_WelderId,
                C: row.WPQ_RecordNo,
                D: row.WPQ_WelderName,
                E: (WQC_Pcat[i] || "").trim(),
                F: (WQC_Type[i] || "").trim(),
                G: formattedValidUpto,
                H: row.WPQ_BM_BM_Pno,
                I: (WQC_Fno[i] || "").trim(),
                J: (WQC_Gv610[i] || "" ).trim(),
                K: (WQC_Gv73to610[i] || "").trim(),
                L: (WQC_Gv73[i] || "").trim(),
                M: (WQC_Fl610[i] || "").trim(),
                N: (WQC_Fl73to610[i] || "").trim(),
                O: (WQC_Fl73[i] || "").trim(),
                P: (WQC_DepThick[i]|| "").trim(),
                // Q: row.wld_WPSType === "Groove" 
                //      ? row.WPQ_BMT_Pipe_Diameter_RangeQ_Groove 
                //      : row.WPQ_BMT_Pipe_Diameter_RangeQ_Overlay, 
                Q: row.WPQ_BMT_Pipe_Diameter_RangeQ_Groove,
                R: (WQC_DetBack[i] || "").trim(),
                S: (WQC_BackGas[i] || "").trim(),
                T: (WQC_Prog[i] || "").trim(),
                U: row.WPQ_Inspection,
                V: (WQC_Arc[i] || "").trim(),
                W: (WQC_DirVisCon[i] || "").trim(),
                X: (WQC_AutJoiTrac[i] || "").trim(),
                Y: (WQC_FillWire[i] || "").trim(),
                Z: (WQC_ConIns[i] || "").trim(),
                AA: (WQC_Fmproform[i] || "").trim(),
                AB: (WQC_CurrPol[i] || "").trim(),
                AC: (WQC_SinPerside[i] || "").trim(),
              });
            }
          });


      
          

            titleCell = "E3";
            titleWelderRange = "G1:Y5";
            tbodyHeadRange = "A6:AC6";
            tbodyRange = "A7";
            blankRow = "A5:AC5";
            width = [
            { col: "A", width: 2.5 },
            { col: "B", width: 13.7 },
            { col: "C", width: 13.7 },
            { col: "D", width: 20.7 },
            { col: "E", width: 8.5 },
            { col: "F", width: 12 },
            { col: "G", width: 16 },
            { col: "H", width: 30 },
            { col: "I", width: 10 },
            { col: "J", width: 30 },
            { col: "K", width: 30 },
            { col: "L", width: 30 },
            { col: "M", width: 30 },
            { col: "N", width: 30 },
            { col: "O", width: 30 },
            { col: "P", width: 30 },
            { col: "Q", width: 30 },
            { col: "R", width: 20 },
            { col: "S", width: 20 },
            { col: "T", width: 20 },
            { col: "U", width: 20 },
            { col: "V", width: 20 },
            { col: "W", width: 30 },
            { col: "X", width: 30 },
            { col: "Y", width: 30 },
            { col: "Z", width: 30 },
            { col: "AA", width: 30 },
            { col: "AB", width: 30 },
            { col: "AC", width: 38 },
          ];
        }
          let Headings = [
            { col: "G2", value: excelHeader },
            {col: "K2", value: PrintDate},
            {
              col: "C4",
              value: displayNote ? displayNote : "",
            },
          ];
          console.log("Headings", Headings);
          console.log("call_ExportToExcel");

          ExportToExcel(
            filename,
            width,
            result,
            Data,
            titleCell,
            titleWelderRange,
            tbodyHeadRange,
            tbodyRange,
            blankRow,
            Headings,
            sheetName,
            reporName,
            wqObj.WQC_Joint_Type,
          );
        } else {
          toast.info("There are no records matching to filters.", {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
        }

        if (result.length > 0) {
          // alert("Data Exported")
          toast.success("Exported successfully", {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
        }
      }
    }
  };

  console.log("reeport", getreport);

  return (
    <>
      <Container fluid>
        <Row className="RowHeight mb-4 ml-2">
          <Col md={12} sm={12} xs={12} className="ListTitle">
            Welder Qualification Chart Filters
          </Col>
        </Row>
        <div className="px-2 pb-2">
          <Row className="gx-3" style={{ marginBottom: "2rem" }}>
            <Col md={3}>
              <Form.Group className="ListW-input-select">
                <div className="search-select">
                  <SearchSelectReportField
                    // className="inspdrop"
                    id="WQC_Joint_Type"
                    name="WQC_Joint_Type"
                    className={""}
                    stylegreen={normalstyle}
                    data={Jointstatus}
                    stylenormal={normalstyle}
                    valueField={"value"}
                    value={wqObj?.WQC_Joint_Type}
                    labelField={"name"}
                    placeholder=""
                    onChange={async (ProjectList) => {
                      setwqObj((prev) => ({
                        ...prev,
                        WQC_Joint_Type: ProjectList.value,
                        WQC_WelderId: "",
                        WQC_WPQRecordNo: "",
                        WQC_WPQProcess: "",
                        WQC_From_Date: "",
                        WQC_To_Date: "",
                        WQC_Inspection: "",
                        WQC_Pipe_Diam: "",
                        WQC_PlantLocation: "",
                        WQC_Process_Status: "",
                      }));
                    }}
                  ></SearchSelectReportField>
                </div>
                <label>Joint Type</label>
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group className="ListW-input-select">
                <div className="search-select">
                  <SearchSelectReportField
                    // className="inspdrop"
                    id="WQC_WelderId"
                    name="WQC_WelderId"
                    className={""}
                    stylegreen={normalstyle}
                    data={welderDetails}
                    stylenormal={normalstyle}
                    valueField={"wld_salarycode"}
                    value={wqObj?.WQC_WelderId}
                    labelField={"wld_salarycode"}
                    placeholder=""
                    onChange={(ProjectList) => {
                      setwqObj((prev) => ({
                        ...prev,
                        WQC_WelderId: ProjectList.value,
                      }));
                    }}
                  ></SearchSelectReportField>
                </div>
                <label>Welder Code</label>
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group className="ListW-input-select">
                <div className="search-select">
                  <SearchSelectReportField
                    id="WQC_WPQRecordNo"
                    name="WQC_WPQRecordNo"
                    className={""}
                    stylegreen={normalstyle}
                    data={selectedWPQNo}
                    stylenormal={normalstyle}
                    valueField={"WPQ_RecordNo"}
                    value={wqObj?.WQC_WPQRecordNo}
                    labelField={"WPQ_RecordNo"}
                    placeholder=""
                    onChange={(ProjectList) => {
                      setwqObj((prev) => ({
                        ...prev,
                        WQC_WPQRecordNo: ProjectList.value,
                        WQC_WPQProcess: "",
                        WQC_From_Date: "",
                        WQC_To_Date: "",
                        WQC_Inspection: "",
                        WQC_Pipe_Diam: "",
                        WQC_PlantLocation: "",
                        WQC_Process_Status: "",
                      }));
                    }}
                  ></SearchSelectReportField>
                </div>
                <label>WPQ No</label>
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group className="ListW-input-select">
                <div className="search-select">
                  <SearchSelectReportField
                    id="WQC_WPQProcess"
                    name="WQC_WPQProcess"
                    className={""}
                    stylegreen={normalstyle}
                    data={getEachProcess()}
                    stylenormal={normalstyle}
                    valueField={"value"}
                    value={wqObj?.WQC_WPQProcess}
                    labelField={"label"}
                    placeholder=""
                    onChange={(ProjectList) => {
                      setwqObj((prev) => ({
                        ...prev,
                        WQC_WPQProcess: ProjectList.value,
                        WQC_From_Date: "",
                        WQC_To_Date: "",
                        WQC_Inspection: "",
                        WQC_Pipe_Diam: "",
                        WQC_PlantLocation: "",
                        WQC_Process_Status: "",
                      }));
                    }}
                  ></SearchSelectReportField>
                </div>
                <label>Process</label>
              </Form.Group>
            </Col>

            <Col md={3}>
              <Date_Input
                divClass="Date-input w-100"
                label="Last Weld Date (From)"
                className="groove_datecolor"
                name="WQC_From_Date"
                sx={{ fontSize: "6px", fontFamily: "Work Sans", zIndex: "0" }}
                todayDate={todayDate}
                obj={wqObj}
                setobj={setwqObj}
                datevalue={datevalue}
                setdatevalue={setdatevalue}
                todate={wqObj.WQC_To_Date}
                setError={setError}
                status={error.WQC_From_Date.status}
                message={error.WQC_From_Date.message}
              />
            </Col>

            <Col md={3}>
              <Date_Input
                divClass="Date-input w-100"
                label="Last Weld Date (To)"
                className="groove_datecolor"
                name="WQC_To_Date"
                sx={{ fontSize: "6px", fontFamily: "Work Sans", zIndex: "0" }}
                todayDate={todayDate}
                obj={wqObj}
                fromdate={wqObj.WQC_From_Date}
                setobj={setwqObj}
                setdatevalue={setdatevalue}
                datevalue={datevalue}
                setError={setError}
                status={error.WQC_To_Date.status}
                message={error.WQC_To_Date.message}
              />
            </Col>

            <Col md={3}>
              <Form.Group className="ListW-input-select">
                <div className="search-select">
                  <SearchSelectReportField
                    id="WQC_Inspection"
                    name="WQC_Inspection"
                    className={""}
                    stylegreen={normalstyle}
                    data={uniqueData}
                    stylenormal={normalstyle}
                    valueField={"WPQ_Inspection"}
                    value={wqObj?.WQC_Inspection}
                    labelField={"WPQ_Inspection"}
                    placeholder=""
                    onChange={(ProjectList) => {
                      setwqObj((prev) => ({
                        ...prev,
                        WQC_Inspection: ProjectList.value,
                        WQC_Pipe_Diam: "",
                        WQC_PlantLocation: "",
                        WQC_Process_Status: "",
                      }));
                    }}
                  ></SearchSelectReportField>
                </div>
                <label>Inspection Agency</label>
              </Form.Group>
            </Col>


            <Col md={3}>
              <Form.Group>
                <div class="T-input w-100">
                  <input
                    style={{ padding: "8px" }}
                    type="text"
                    id="WQC_Pipe_Diam"
                    name="WQC_Pipe_Diam"
                    placeholder=" "
                    //disabled={WiObj.WQC_Pipe_Diam === "Plate"}
                    //maxLength={2}
                    className={error.WQC_Pipe_Diam.status ? "err-input" : ""}
                    //className={""}
                    value={wqObj.WQC_Pipe_Diam}
                    onChange={(e) => {
                      handleChange(e);

                      setwqObj((prev) => ({
                        ...prev,
                        WQC_Pipe_Diam: e.target.value,
                      }));
                    }}
                    onInput={(e) => {
                      let error = isRealNumber(e.target.value);
                      setError((prevState) => ({
                        ...prevState,
                        WQC_Pipe_Diam: error,
                      }));
                    }}
                  />
                  <label for="WQC_Pipe_Diam">
                    Pipe Diameter(Less Than Equal To)
                  </label>
                </div>
                <div className="required-text">
                  {error.WQC_Pipe_Diam.status && (
                    <Form.Text className="text-danger">
                      {error.WQC_Pipe_Diam.message}
                    </Form.Text>
                  )}
                </div>
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group className="ListW-input-select">
                <div className="search-select">
                  <SearchSelectReportField
                    id="WQC_PlantLocation"
                    name="WQC_PlantLocation"
                    className={""}
                    stylegreen={normalstyle}
                    data={PlantLoc}
                    stylenormal={normalstyle}
                    valueField={"value"}
                    value={wqObj?.WQC_PlantLocation}
                    labelField={"name"}
                    placeholder=""
                    onChange={(ProjectList) => {
                      setwqObj((prev) => ({
                        ...prev,
                        WQC_PlantLocation: ProjectList.value,
                        WQC_Process_Status: "",
                      }));
                    }}
                  ></SearchSelectReportField>
                </div>
                <label>Plant Location</label>
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group className="ListW-input-select">
                <div className="search-select">
                  <SearchSelectReportField
                    id="WQC_Process_Status"
                    name="WQC_Process_Status"
                    className={""}
                    stylegreen={normalstyle}
                    data={ProcessStatus}
                    stylenormal={normalstyle}
                    valueField={"value"}
                    value={wqObj?.WQC_Process_Status}
                    labelField={"name"}
                    placeholder=""
                    onChange={(ProjectList) => {
                      setwqObj((prev) => ({
                        ...prev,
                        WQC_Process_Status: ProjectList.value,
                      }));
                    }}
                  ></SearchSelectReportField>
                </div>
                <label>Status</label>
              </Form.Group>
            </Col>
          </Row>
        </div>
        <Row>
          <Col>
            <Button
              onClick={handleSubmit}
              className="btn-add-color semiBoldToggleButton rightAlignedButton"
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Container>

      <ToastContainer
        className="toast-position"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
});

export default WQChartReport;
