import { useState, useEffect } from "react";
import { Col, Form, Modal, Row, Alert } from "react-bootstrap";
import "../../Assests/Css/welder.css";
import {
  AddNewProject,
  UpdateProject,
  getAllLNProjects,
} from "../../Services/project-services";
import "../../Assests/Css/welder.css";
import SuccessAlertModal from "../Modals/successAlertModal";
import ConfirmAlertModal from "../Modals/confirmAlertModal";
import ButtonModal from "../Modals/buttonModal";
import { useNavigate } from "react-router-dom";
import {
  isAlphanumeric,
  isAlphanumericWithDash,
  isAlphanumericWithDotSpaceDashCommaBrackets,
} from "../../functions/validations";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getActiveInsp } from "../../Services/inspectionagency-service";
import { statusChange } from "../utility/FormControl";
import { SearchSelect } from "../utility/search-select";

const AddEditProjectMaster = (props) => {
  if (props.rowdata.wld_status === "Active") {
    props.rowdata.wld_status = true;
  } else if (props.rowdata.wld_status === "Inactive") {
    props.rowdata.wld_status = false;
  }

  let action = props.action;
  let formName = "Project";

  const [showSuccessModel, setShowSuccessModel] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showConfirmModel, setshowConfirmModel] = useState(false);
  const [ProjectNo, setProjectNo] = useState([]);
  const [alertMessage, setAlertMessage] = useState("");
  const [dropinspection, setdropinspection] = useState([]);
  const [check, setCheck] = useState(true);

  const history = useNavigate();
  let username = sessionStorage.getItem("UserName");

  const fetchInspection = async () => {
    const result = await getActiveInsp();
    console.log("result", result);
    setdropinspection(result);
  };
  useEffect(() => {
    fetchInspection();
  }, []);

  console.log("username is -- ", username);

  const fetchProjectNo = async () => {
    const result = await getAllLNProjects();
    setProjectNo(result);
  };
  useEffect(() => {
    fetchProjectNo();
  }, []);

  const [ProjectObj, setProjectObj] = useState(
    action === "Edit"
      ? props.rowdata
      : {
          wld_status: true,
          wld_projectType: "LN",
          wld_ProjectNumber: "",
          wld_code: "",
          wld_equipnumber: "",
          wld_clientname: "",
          wld_criticalcode: "",
          wld_inspectionagency: "",
          wld_deleted: "",
          SK: "",
          PK: "Project",
          wld_createdby: "",
          wld_createdon: "",
          wld_updatedby: "",
          wld_updatedon: "",
          projectNo: "",
        }
  );

  const [error, setError] = useState({
    SK: {},
    PK: {},
    wld_code: {},
    wld_status: {},
    wld_ProjectNumber: {},
    wld_equipnumber: {},
    wld_clientname: {},
    wld_criticalcode: {},
    wld_inspectionagency: {},
    wld_deleted: {},
    wld_createdby: {},
    wld_createdon: {},
    wld_updatedby: {},
    wld_updatedon: {},
    projectNo: {},
  });
  const modalOnClick = () => {
    history.push({
      pathname: "/",
      data: "setProject",
    });
  };

  const handleCloseSuccess = () => {
    setShowSuccessModel(false);
    props.onHide();
    props.setReload(!props.reload);
  };
  const handleCloseConf = () => {
    setshowConfirmModel(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setProjectObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setError((prevState) => ({
      ...prevState,
      // wld_ProjectNumber: {},
    }));
  };

  const handleSwitchChange = (e) => {
    statusChange(e);

    setProjectObj((prevState) => ({
      ...prevState,
      wld_status: e.target.checked,
    }));
  };

  const resetForm = () => {
    if (action === "Add") {
      setProjectObj((prevState) => ({
        ...prevState,
        wld_status: true,
        wld_ProjectNumber: "",
        wld_equipnumber: "",
        wld_clientname: "",
        wld_criticalcode: "",
        wld_inspectionagency: "",
        wld_deleted: "",
        SK: "",
        wld_createdby: "",
        wld_createdon: "",
        projectNo: "",
      }));
    } else if (action === "Edit") {
      setProjectObj((prevState) => ({
        ...prevState,
        PK: props.rowdata.PK,
        SK: props.rowdata.SK,
        wld_status: props.rowdata.wld_status,
        wld_projectType: props.rowdata.wld_projectType,
        wld_ProjectNumber: props.rowdata.wld_ProjectNumber,
        wld_equipnumber: props.rowdata.wld_equipnumber,
        wld_clientname: props.rowdata.wld_clientname,
        wld_criticalcode: props.rowdata.wld_criticalcode,
        wld_inspectionagency: props.rowdata.wld_inspectionagency,
        wld_deleted: props.rowdata.wld_deleted,
        wld_createdby: props.rowdata.wld_createdby,
        wld_createdon: props.rowdata.wld_createdon,
        projectNo: props.rowdata.projectNo,
      }));
    }
    setError((prevState) => ({
      ...prevState,
      wld_ProjectNumber: {},
      wld_equipnumber: {},
      SK: {},
    }));
  };

  const validateProject = (action) => {
    let result = false;
    let isError = false;
    if (ProjectObj.wld_ProjectNumber.trim() === "") {
      ProjectObj.wld_ProjectNumber = "";
      isError = true;
      error.wld_ProjectNumber = {
        status: true,
        message: "Project Number is required.",
      };
    }
    if (ProjectObj.wld_clientname.trim() === "") {
      ProjectObj.wld_clientname = "";
      isError = true;
      error.wld_clientname = {
        status: true,
        message: "Client Name is required.",
      };
    }

    if (action === "Add") {
      
      console.log("ProjectObj -- ", ProjectObj);
      result = props.allRecs.find(
        (item) =>
          item.PK?.toLowerCase() === ProjectObj.PK?.toLowerCase() &&
          item.SK?.toLowerCase() === ProjectObj.wld_ProjectNumber?.toLowerCase()
      );
      console.log("adddd", result);
    }

    setError((prevState) => ({
      ...prevState,
      ...error,
    }));
    if (
      error.SK.status ||
      error.wld_ProjectNumber.status ||
      error.wld_equipnumber.status ||
      error.wld_clientname.status ||
      error.wld_inspectionagency.status ||
      error.wld_criticalcode.status ||
      error.wld_equipnumber.status
    ) {
      isError = true;
    }
    return isError;
  };
  console.log("projectobj", ProjectObj);
  const handleAdd = (e) => {
    console.log("e", e);
    let result;
    if (ProjectObj.wld_status === true) {
      ProjectObj.wld_status = "Active";
    } else {
      ProjectObj.wld_status = "Inactive";
    }
    if (check) {
      ProjectObj.wld_projectType = "LN";
    } else {
      ProjectObj.wld_projectType = "External";
    }
    if (action === "Add") {
      ProjectObj.wld_deleted = "N";
      ProjectObj.SK = ProjectObj.wld_ProjectNumber;
      ProjectObj.wld_createdby = username;
      ProjectObj.wld_createdon = ProjectObj.wld_createdon;
      console.log("ProjectObj", ProjectObj);
      result = AddNewProject(ProjectObj);
    } else {
      ProjectObj.wld_updatedby = ProjectObj.wld_createdby;
      ProjectObj.wld_updatedon = ProjectObj.wld_updatedon;
      result = UpdateProject(ProjectObj);
    }
    result
      .then((resp) => {
        if (action === "Edit" && resp.message === "Success" ) {
          toast.success(`Selected Project No. has been Updated successfully`, {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
        }

        if (resp.message === "Duplicate") {
          toast.warning(resp.message, {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
        } 
        
        if (action === "Add" && resp.message === "Success") {
          toast.success(`Selected Project No. has been Added successfully`, {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
        }

        if( resp.message === "Failed"){
          toast.warning(`Failed to Add/Update`, {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
        })
        }
        props.setReload(!props.reload);
        setShowForm(false);
        props.onHide();
      })
      .catch((error) => {
        console.log("Failed");
      
          toast.warning(`Failed to Add/Update`, {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
        })
        setShowForm(false);
        props.onHide();
        
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateProject(action)) {
      setshowConfirmModel(true);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlertMessage("");
    }, 10000);
    return () => clearTimeout(timer);
  }, [alertMessage]);

  return (
    <>
      <SuccessAlertModal
        clickFunction={modalOnClick}
        msg={`Great! You've ${action}ed Project successfully.`}
        show={showSuccessModel}
        handleClose={handleCloseSuccess}
      />
      <ConfirmAlertModal
        action={action}
        handleYes={handleAdd}
        formName={formName}
        show={showConfirmModel}
        handleClose={handleCloseConf}
      />

      <Modal
        {...props}
        size="lg"
        centered
        className={
          showForm || showSuccessModel || showConfirmModel
            ? "opacity-0 userMasterModal"
            : "opacity-100 userMasterModal"
        }
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{action} Project </Modal.Title>
        </Modal.Header>

        <Form onSubmit={handleSubmit} autoComplete="off">
          <Modal.Body>
            <div className="px-2 pb-2">
              <Row>
                <Col md={4}>
                  <Form.Group>
                    <div className="checkboxproject">
                      <input
                        type="checkbox"
                        placeholder=" "
                        className="form-check-input"
                        id="chkcode"
                        onClick={() => {
                          setCheck(!check);
                          resetForm();
                        }}
                        disabled={action === "Edit" ? true : false}
                        // defaultChecked={checked}
                        // checked={ProjectObj.wld_projectType==="LN"?true:false}
                        checked={check}
                      />{" "}
                      &nbsp;&nbsp;
                      <label for="chkcode">Internal Project </label>
                    </div>
                    &nbsp;&nbsp;
                    {error.wld_code.status && (
                      <Form.Text className="text-danger">
                        {error.wld_code.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                {check ? (
                  <Col md={4}>
                    <Form.Group>
                      <SearchSelect
                        className="inspdrop"
                        data={ProjectNo}
                        valueField={"projectNo"}
                        disabled={action === "Edit" ? true : false}
                        value={ProjectObj.wld_ProjectNumber}
                        labelField={"projectNo"}
                        placeholder="Project Number"
                        onChange={(ProjectObj) => {
                          console.log("projectobj on", ProjectObj);
                          setProjectObj((prev) => ({
                            ...prev,
                            wld_ProjectNumber: ProjectObj.value,
                          }));
                        }}
                      ></SearchSelect>
                    </Form.Group>
                  </Col>
                ) : (
              
                  <Col md={4}>
                    <Form.Group>
                      <div class="g-input">
                        <input
                          type="text"
                          id="wld_ProjectNumber"
                          name="wld_ProjectNumber"
                          placeholder=" "
                          required
                          disabled={action === "Edit" ? true : false}
                          maxLength="15"
                          className={
                            error.wld_ProjectNumber.status
                              ? "placeHolderInput inputText text-field-with-error allow"
                              : "placeHolderInput inputText allow"
                          }
                          value={ProjectObj.wld_ProjectNumber}
                          onChange={handleChange}
                          onInput={(e) => {
                            let error = isAlphanumeric(e.target.value);
                            setError((prevState) => ({
                              ...prevState,
                              wld_ProjectNumber: error,
                            }));
                          }}
                        />
                        <label class="required-field" for="wld_ProjectNumber">
                          Project Number{" "}
                        </label>
                      </div>{" "}
                      &nbsp;&nbsp;
                      {error.wld_ProjectNumber.status && (
                        <Form.Text className="text-danger">
                          {error.wld_ProjectNumber.message}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                )}
                <Col md={4}>
                  <Form.Group>
                    <div className="g-input">
                      <input
                        id="wld_equipnumber"
                        name="wld_equipnumber"
                        type="text"
                        maxLength="20"
                        class="asterisk"
                        className={
                          error.wld_equipnumber.status
                            ? "placeHolderInput inputText text-field-with-error allow"
                            : "placeHolderInput inputText allow"
                        }
                        placeholder=" "
                        value={ProjectObj.wld_equipnumber}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error = isAlphanumericWithDash(e.target.value);
                          // alert(error);
                          setError((prevState) => ({
                            ...prevState,

                            wld_equipnumber: error,
                          }));
                        }}
                      />
                      <label for="wld_equipnumber">Equipment Number</label>
                    </div>{" "}
                    &nbsp;&nbsp;
                    {error.wld_equipnumber.status && (
                      <Form.Text className="text-danger">
                        {error.wld_equipnumber.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <div class="g-input">
                      <input
                        id="wld_clientname"
                        name="wld_clientname"
                        type="text"
                        class="asterisk"
                        maxLength="100"
                        className={
                          error.wld_clientname.status
                            ? "placeHolderInput inputText text-field-with-error allow"
                            : "placeHolderInput inputText allow"
                        }
                        placeholder=" "
                        value={ProjectObj.wld_clientname}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error =
                            isAlphanumericWithDotSpaceDashCommaBrackets(
                              e.target.value
                            );
                          setError((prevState) => ({
                            ...prevState,
                            wld_clientname: error,
                          }));
                        }}
                      />
                      <label class="required-field" for="wld_clientname">
                        Client Name
                      </label>
                    </div>{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {error.wld_clientname.status && (
                      <Form.Text className="text-danger">
                        <span style={{ position: "relative", bottom: "10px" }}>
                          {error.wld_clientname.message}
                        </span>
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row
                className="gx-3"
                style={{
                  // marginBottom: "1rem",
                  position: "relative",
                  bottom: "13px",
                }}
              >
                <Col md={4}>
                  <Form.Group>
                    <div class="g-input">
                      <input
                        id="wld_criticalcode"
                        name="wld_criticalcode"
                        type="text"
                        maxLength="5"
                        class="asterisk"
                        disabled={action === "Edit" ? true : false}
                        className={
                          error.wld_criticalcode.status
                            ? "placeHolderInput inputText text-field-with-error allow"
                            : "placeHolderInput inputText allow"
                        }
                        placeholder=" "
                        value={ProjectObj.wld_criticalcode}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error = isAlphanumeric(e.target.value);
                          setError((prevState) => ({
                            ...prevState,
                            wld_criticalcode: error,
                          }));
                        }}
                      />
                      <label for="wld_criticalcode">Critical Code</label>
                    </div>{" "}
                    &nbsp;&nbsp;&nbsp;
                    {error.wld_criticalcode.status && (
                      <Form.Text className="text-danger">
                        {error.wld_criticalcode.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>

                {/* <Col md={4}>
                  <Form.Group>
                    <SearchSelect
                      required
                      data={dropinspection}
                      valueField={"wld_code"}
                      value={ProjectObj.wld_inspectionagency}
                      disabled={action === "Edit" ? true : false}
                      labelField={"wld_code"}
                      placeholder="Inspection Agency"
                      onChange={(InspList) => {
                        setProjectObj((prev) => ({
                          ...prev,
                          wld_inspectionagency: InspList.value,
                        }));
                      }}
                    ></SearchSelect>
                  </Form.Group>
                </Col> */}

                <Col md={4}>
                  <Form.Group className="proj-input-select">
                    <div className="search-select">
                      <SearchSelect
                        required
                        data={dropinspection}
                        valueField={"wld_code"}
                        disabled={action === "Edit" ? true : false}
                        value={ProjectObj.wld_inspectionagency}
                        labelField={"wld_code"}
                        placeholder="Inspection Agency"
                        onChange={(InspList) => {
                          setProjectObj((prev) => ({
                            ...prev,
                            wld_inspectionagency: InspList.value,
                          }));
                        }}
                      ></SearchSelect>
                    </div>
                    <label>Inspection Agency</label>
                    <div className="required-text-select">
                      {error.wld_inspectionagency.status && (
                        <Form.Text className="text-danger">
                          {error.wld_inspectionagency.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>

                <Col md={4}>
                  <Form.Group className="form-switch-input">
                    <Row>
                      <Col>
                        {" "}
                        <label
                          style={{
                            fontSize: "14px",
                            padding: "10px",
                            color: "var(--col-707070)",
                            fontWeight: "bold",
                          }}
                          class="required-field"
                        >
                          Status{" "}
                        </label>
                      </Col>
                      <Col>
                        <Form.Switch
                          label={ProjectObj.wld_status ? "Active" : "Inactive"}
                          className={ProjectObj.wld_status ? "green" : "gray"}
                          name="wld_status"
                          style={{ padding: "10px" }}
                          checked={ProjectObj.wld_status}
                          onChange={(e) => {
                            handleSwitchChange(e);
                          }}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
              </Row>
            </div>
            {alertMessage && (
              <Alert className="alertBox" variant="warning">
                {alertMessage}
              </Alert>
            )}
          </Modal.Body>

          {/* <Row>
            <div>
              <div
                class={
                  action === "Edit"
                    ? " float-start m-1 invisible"
                    : "float-start m-1 visible"
                }
              >
                &nbsp;&nbsp;&nbsp;
                <Button className="resetbtn" onClick={resetForm}>
                  Reset
                </Button>
              </div>
              <div class="float-end m-1">
                <Button type="submit" className="submitbtn">
                  {action === "Edit" ? "Update" : "Add"}
                </Button>
                &nbsp;&nbsp;&nbsp;
              </div>
            </div>
          </Row> */}

          <Row>
            <ButtonModal action={action} resetForm={resetForm}></ButtonModal>
          </Row>

          <Row>
            <br></br>
          </Row>
        </Form>
      </Modal>
      <ToastContainer
        className="toast-position"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default AddEditProjectMaster;
