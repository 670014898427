import React, { useState, useEffect } from "react";
import Avatar from "react-avatar";
import ConfirmAlertModal from "./Modals/confirmAlertModal";
import "../Assests/Css/account.css";
import logout from "../icons/LogOut.svg";
import { useMsal } from "@azure/msal-react";

const Account = (props) => {
  const [emailid, setemail] = useState();
  const [username, setusername] = useState();
  const [show, setShow] = useState();
  const { instance } = useMsal();
  let formName = "Logout";

  useEffect(() => {
    setemail(sessionStorage.getItem("UserId"));
    setusername(sessionStorage.getItem("UserName"));
  }, []);
  
  const userlogout = () => {
    sessionStorage.clear();
    
    instance.logoutRedirect().catch((e) => {

      console.error(e);

    });
   
    
  };

  return (
    <>
      <ConfirmAlertModal
        handleYes={userlogout}
        formName={""}
        action={formName}
        show={show}
        handleClose={() => setShow(false)}
      />
      {/* <div className="line">|&nbsp;</div> */}
      <div className="line">&nbsp;</div>
      <div className="account">
        <div className="profile_text">
          <Avatar
            name={username}
            maxInitials={2}
            color="var(--table-bg)"
            fgColor="var(--godrej-purple)"
            round={true}
            size="40px"
          />
        </div>

        <div className="profilecontainer">
          <div className="profilename">
            <b>{username}</b>
          </div>
          <div className="profilemail">
            <b>{emailid}</b>
          </div>
        </div>
        <div className="arrow">
          <img
            src={logout}
            onClick={() => setShow(true)}
            className="user-action"
          ></img>
        </div>
      </div>
    </>
  );
};

export default Account;
