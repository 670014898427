/*      If called from 
                "Request Sent for Approval  "initated" status In Progress
                    Delete to be visible and Save not visible
                "Request Sent for Approval  "initated" status Rejected
                      Delete to be visible and Save  to be visible
                      "Request Sent for Approval  "initated" status Approved
                      Delete not to be visible and Save not to be visible
                "Request Pending for Approval" "pending flag"
                      Delete not visible at all as it's a view for final approver
                      Save is visible he can approve of reject */

import { useEffect, useState } from "react";
import {
  getQualReqByType,
  updateQualificationRequest,
} from "../../../Services/trainingRequisition-service";
import {
  Accordion,
  Alert,
  Badge,
  Button,
  Col,
  Form,
  Image,
  ListGroup,
  Row,
} from "react-bootstrap";
import Track from "../../../icons/Track.svg";
import DownArrow from "../../../icons/DownArrow.svg";
import { isAlphanumericAllCharacters, isAlphanumericWithSpecialChars } from "../../../functions/validations";
import { toast } from "react-toastify";
import { getInitials } from "../../../functions/welding-functions";


const ClosureRemarksQual = (props) => {
  
  const [closureremarksobj, setClosureRemarksObj] = useState([]);
  const [collapse, setCollapse] = useState({});
  const [alertMessage, setAlertMessage] = useState("");

  const [accRejStatus, setAccRejStatus] = useState("");
  const [disabledRadio, setDisabledRadio] = useState(true);
  const [closureRemarksData, SetClosureRemarksData] = useState();
  const [showForm, setShowForm] = useState(false);

  console.log("Closure Qualification props error", props.error);
  console.log("prprprp", props);

  

  let levelCount = [
    { levelName: "Final", approverDesignation: "Qualification Engineer" },
  ];

  let Role = sessionStorage.getItem("Role");
  let finalApprover = Role === process.env.REACT_APP_ROLE_QCENGINEER ;

  const getAllRecords = async () => {
    let tmpObj = { fieldValue: props.rowData.SK, flag: "reqNumber" };
    const Records = await getQualReqByType(tmpObj);

    if (Records?.length > 0) {
      setClosureRemarksObj(Records);

      let currRevision = Records.filter(
        (item) => item.RevisionNo === props.rowData.RevisionNo
      );

      if (currRevision?.length > 0) {
        SetClosureRemarksData(currRevision[0]);
      }

      setCollapse({ activeItem: props.rowData.RevisionNo });
    }
  };
  const [error, setError] = useState({
    wld_TrainingReqApproverComment: {},
    wld_TrainingReqApprover2: {},
    wld_TrainingReqApproverSalCode:{},
    wld_TrainingWPQ:{}
  });

  const handleCollapsible = (collpasedItem) => {
    if (collapse.activeItem === collpasedItem) {
      setCollapse({ activeItem: -1 });
    } else {
      setCollapse({ activeItem: collpasedItem });
    }
  };

  function handleChange(e) {
    SetClosureRemarksData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  }

  const handleRadioChange = (e) => {
    console.log(" Qual handleRadioChange_value", e.target.value);
    setDisabledRadio(false);
    setAccRejStatus(e.target.value);
    SetClosureRemarksData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const validateData = () => {
    let currLevel = props.rowData.wld_pendingStatus;
    console.log(
      "currLevellll_final",
      closureRemarksData["wld_TrainingReq" + currLevel + "Comment"]
    );

    let err = {};
    let isError = false;
  
    setError((prevState) => ({
      ...prevState,
      ...err,
    }));
    return isError;
  };

  const handleSubmit = async (flag) => {
    
    let result;
    var valid = false;
   
    if (flag !== "Delete") valid = validateData();
    if (!valid) {
      let trainingObj = Object.assign({}, props.rowData);

      if (flag === "Delete") {
        closureRemarksData.wld_status = "Deleted";
        closureRemarksData.wld_pendingStatus = "Deleted";
        closureRemarksData.wld_TrainingReqPendingWith = "system";
      } else {
        if (props.rowData.wld_pendingStatus === "Rejected") {
          closureRemarksData.wld_status = "In Progress";
          closureRemarksData.wld_pendingStatus = "Final";
          closureRemarksData.wld_TrainingReqPendingWith = process.env.REACT_APP_ROLE_QCENGINEER;
          trainingObj.wld_TrainingReqExperience = props.ExperienceNewRev;
        } else {
          if (accRejStatus === "Rejected") {
            closureRemarksData.wld_status = "Rejected";
            closureRemarksData.wld_pendingStatus = "Rejected";
            closureRemarksData.wld_TrainingReqPendingWith =
              props.rowData.wld_TrainingReqRequesterEmailId;
          } else if (accRejStatus === "Approved") {
            trainingObj.wld_TrainingReqApproverFinalComment = closureRemarksData.wld_TrainingReqApproverFinalComment;
            trainingObj.wld_TrainingReqSalaryEmployeeCode = closureRemarksData.wld_TrainingReqApproverFinalComment1;
            trainingObj.wld_WpqNo = closureRemarksData.wld_TrainingReqApproverFinalComment2;
            closureRemarksData.wld_status = "Approved";
            closureRemarksData.wld_pendingStatus = "Approved";
            closureRemarksData.wld_TrainingReqPendingWith = "system";
            closureRemarksData.wld_TrainingReqApproverFinalEmailId =  sessionStorage.getItem("UserId")
          }
            closureRemarksData.wld_TrainingReqApproverFinal = sessionStorage.getItem("UserName")
        }
      }
      let obj = { closureRemarksData, trainingObj };
      result = updateQualificationRequest(obj);
      result
        .then((resp) => {
          toast.success(
            `Welder Qualification Request has been Updated successfully`,
            {
              position: toast.POSITION.TOP_LEFT,
              className: "toast-message",
            }
          );
          
          props.callReload();
          setShowForm(false);
        })
        .catch((error) => {
          console.log("Qualification Failed", error);
        });
    }
  };

  function handleCancel() {
    props.close(true, "Cancel");
  }


  useEffect(() => {
    getAllRecords();
  }, [showForm]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlertMessage("");
    }, 1000);
    return () => clearTimeout(timer);
  }, [alertMessage]);

  console.log("ffee",closureremarksobj)
  console.log("fffffffee",closureremarksobj[0]?.wld_TrainingReqApproverFinalComment1)


  return (
    <>
      <Accordion
        defaultActiveKey="1"
        style={{
          marginTop: "20px",
        }}
      >
        {closureremarksobj?.map((value, index) => {
          return (
            <Accordion.Item
              eventKey={index}
              style={{
                backgroundColor: "white",
                marginBottom: "2rem",
              }}
              key={index}
            >
              <Accordion.Header
                className={
                  collapse?.activeItem === index
                    ? `dispaly-none accordion-header`
                    : `accordion-header`
                }
                onClick={() => handleCollapsible(index)}
              >
                <Image
                  src={Track}
                  alt="Track"
                  className="profile-initials top-3 left43"
                ></Image>
                <label
                  className="remarkLabel size-20"
                  style={{ marginLeft: "3rem" }}
                >
                  Track Report - Rev.{index}
                </label>
                <Image
                  src={DownArrow}
                  alt="DownArrow"
                  className="blue-arrow downarrow"
                ></Image>
              </Accordion.Header>
              <Accordion.Body
                className={
                  collapse?.activeItem !== index ? `dispaly-none` : `show-block`
                }
              >
                <Form>
                  <ListGroup as="ol">
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div
                        className={"profile-initialsClosure "}
                        style={{ marginLeft: "-0.4%" }}
                      >
                        {getInitials(
                          props.rowData.wld_TrainingReqRequesterName
                        )}
                      </div>

                      <div className="ms-5 me-auto">
                        <div className="fw-bold">
                          {props.rowData.wld_TrainingReqRequesterName}
                        </div>
                        <span
                          style={{
                            fontSize: "12px",
                            color: "grey",
                            paddingTop: "122px",
                          }}
                        >
                          Engineer
                        </span>
                      </div>
                      <span
                        style={{
                          fontSize: "12px",
                          color: "grey",
                          paddingRight: "32px",
                        }}
                      >
                        {props.rowData.wld_TrainingReqDate}
                      </span>
                      <Badge bg="light" style={{ color: "black" }} pill>
                        Initiated
                      </Badge>
                    </ListGroup.Item>
                    {levelCount.map((ind, rowNo) => {
                      
                      return (
                        <ListGroup.Item
                          as="li"
                          className="d-flex justify-content-between align-items-start"
                        >
                          {" "}
                          <div
                            className={"profile-initialsClosure "}
                            style={{ marginLeft: "-0.4%" }}
                          >
                            {getInitials(
                              value["wld_TrainingReqApprover" + ind.levelName]
                            )}
                          </div>
                          <div
                            className="ms-5 me-auto"
                            style={{ width: "20%" }}
                          >
                            {finalApprover && props.calledFrom === "Approval" &&
                            value.RevisionNo === props.rowData.RevisionNo &&
                            props.rowData.wld_pendingStatus === "Final" ? (
                              <div className="fw-bold">
                                {
                                  value[
                                    "wld_TrainingReqApprover" + ind.levelName
                                  ]
                                }
                              </div>
                            ) : (

                              finalApprover &&
                              props.rowData.wld_pendingStatus === "Approved" ? (
                                <div className="fw-bold">
                                  {
                                    value[
                                      "wld_TrainingReqApproverFinal"
                                    ]
                                  }
                                </div>):
(
                              <div className="fw-bold">
                                Pending With
                                
                              </div>)
                            )}
                            <span style={{ fontSize: "12px", color: "grey" }}>
                              {ind.approverDesignation}
                            </span>
                            <br />
                            <br />
                           
                            {finalApprover &&
                            props.calledFrom === "Approval" &&
                            value.RevisionNo === props.rowData.RevisionNo &&
                            props.rowData.wld_pendingStatus === "Final" ? (
                              <>
                                <Form.Group>
                                  <div
                                    class="g-input"
                                    style={{ width: "400%" }}
                                  >
                                    <input
                                      id={
                                        "wld_TrainingReqApprover" +
                                        ind.levelName +
                                        "Comment"
                                      }
                                      name={
                                        "wld_TrainingReqApprover" +
                                        ind.levelName +
                                        "Comment"
                                      }
                                      type="text"
                                      className={
                                        error.wld_TrainingReqApproverComment
                                          .status
                                          ? "placeHolderInput inputText text-field-with-error"
                                          : "placeHolderInput inputText"
                                      }
                                      placeholder=" "
                                      onChange={(e) => handleChange(e)}
                                      style={{ width: "80%" }}
                                      onInput={(e) => {
                                        let error =
                                          isAlphanumericWithSpecialChars(
                                            e.target.value
                                          );
                                        setError((prevState) => ({
                                          ...prevState,
                                          wld_TrainingReqApproverComment: error,
                                        }));
                                      }}
                                    />
                                    <label
                                      class="required-field"
                                      for={
                                        "wld_TrainingReqApprover" +
                                        ind.levelName +
                                        "Comment"
                                      }
                                    >
                                      Comments
                                    </label>
                                  </div>
                                  &nbsp;&nbsp;
                                  {error.wld_TrainingReqApproverComment
                                    .status && (
                                    <Form.Text className="text-danger">
                                      {
                                        error.wld_TrainingReqApproverComment
                                          .message
                                      }
                                    </Form.Text>
                                  )}
                                </Form.Group>

                                

          <div style={{display:"flex", position:"relative", bottom:"10px"}}>
              <div>
                
              {props.rowData.wld_TrainingReqEmployeeType === "New Welder" ? 
                                <Form.Group>
                                  <div
                                    class="g-input"
                                    style={{ width: "400%" }}
                                  >
                                    <input
                                      id={
                                        "wld_TrainingReqApprover" +
                                        ind.levelName +
                                        "Comment1"
                                      }
                                      name={
                                        "wld_TrainingReqApprover" +
                                        ind.levelName +
                                        "Comment1"
                                      }
                                      type="text"
                                      maxLength="10"
                                      className={
                                        error.wld_TrainingReqApproverSalCode
                                          .status
                                          ? "placeHolderInput inputText text-field-with-error"
                                          : "placeHolderInput inputText"
                                      }
                                      placeholder=" "
                                      onChange={(e) => handleChange(e)}
                                      style={{ width: "80%" }}
                                      onInput={(e) => {
                                        let error =
                                          isAlphanumericAllCharacters(
                                            e.target.value
                                          );
                                        setError((prevState) => ({
                                          ...prevState,
                                          wld_TrainingReqApproverSalCode: error,
                                        }));
                                      }}
                                    />
                                    <label
                                      class="required-field"
                                      for={
                                        "wld_TrainingReqApprover" +
                                        ind.levelName +
                                        "Comment1"
                                      }
                                    >
                                      Salary code
                                    </label>
                                  </div>
                                  &nbsp;&nbsp;
                                  {error.wld_TrainingReqApproverSalCode
                                    .status && (
                                    <Form.Text className="text-danger">
                                      {
                                        error.wld_TrainingReqApproverSalCode
                                          .message
                                      }
                                    </Form.Text>
                                  )}
                                </Form.Group>
                                :""}

                                </div>
                                <div>
                                <Form.Group>
                                  <div
                                    class={props.rowData.wld_TrainingReqEmployeeType === "New Welder"? "g-input wqcwelder" : "g-input wqcnowelder" }
                                    // classname={props.rowData.wld_TrainingReqEmployeeType === "New Welder" ? "wqcwelder" : "wqcnowelder" }
                                    // style={{ position:"relative",left:"200px",width: "200%" }}
                                  >
                                    <input
                                      id={
                                        "wld_TrainingReqApprover" +
                                        ind.levelName +
                                        "Comment2"
                                      }
                                      name={
                                        "wld_TrainingReqApprover" +
                                        ind.levelName +
                                        "Comment2"
                                      }
                                      type="text"
                                      maxLength="12"
                                      className={
                                        error.wld_TrainingWPQ
                                          .status
                                          ? "placeHolderInput inputText text-field-with-error"
                                          : "placeHolderInput inputText"
                                      }
                                      placeholder=" "
                                      onChange={(e) => handleChange(e)}
                                      style={{ width: "80%" }}
                                      onInput={(e) => {
                                        let error =
                                          isAlphanumericAllCharacters(
                                            e.target.value
                                          );
                                        setError((prevState) => ({
                                          ...prevState,
                                          wld_TrainingWPQ: error,
                                        }));
                                      }}
                                    />
                                    <label
                                      class="required-field"
                                      for={
                                        "wld_TrainingReqApprover" +
                                        ind.levelName +
                                        "Comment2"
                                      }
                                    >
                                      WPQ No.
                                    </label>
                                  </div>
                                  &nbsp;&nbsp;
                                  {error.wld_TrainingWPQ
                                    .status && (
                                    <Form.Text className="text-danger">
                                      {
                                        error.wld_TrainingWPQ
                                          .message
                                      }
                                    </Form.Text>
                                  )}
                                </Form.Group>
                                </div>
                                </div>
                                <div className="m-input">
                                  <div className="d-flex">
                                    <div className="ms-5" style={{position:"relative", right:"45px"}}>
                                      <input
                                        id={
                                          "wld_TrainingReqApprover" +
                                          ind.levelName +
                                          "Status"
                                        }
                                        type="radio"
                                        name={
                                          "wld_TrainingReqApprover" +
                                          ind.levelName +
                                          "Status"
                                        }
                                        value="Approved"
                                        onChange={(e) => handleRadioChange(e)}
                                      />
                                      <span style={{position:"relative", bottom:"25px", left:"25px"}}>Approve</span>
                                    </div>
                                    <div className="ms-5" style={{position:"relative", right:"20px"}}>
                                      <input
                                        id={
                                          "wld_TrainingReqApprover" +
                                          ind.levelName +
                                          "Status"
                                        }
                                        type="radio"
                                        name={
                                          "wld_TrainingReqApprover" +
                                          ind.levelName +
                                          "Status"
                                        }
                                        value="Rejected"
                                        onChange={(e) => handleRadioChange(e)}
                                      />
                                      <span style={{position:"relative", bottom:"25px", left:"25px"}}>Reject</span>

                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                              <div>
                              <i style={{ fontSize: "12px", color: "grey" }}>
                                {
                                  value[
                                    "wld_TrainingReqApprover" +
                                      ind.levelName +
                                      "Comment" 
                                  ]
                                }
                                
                              </i>                           
                              </div>

                              <div>
                              {props.rowData.wld_TrainingReqEmployeeType === "New Welder" ? 
                                  <i style={{ fontSize: "12px", color: "grey" }}>
                                {
                                  value[
                                    "wld_TrainingReqApprover" +
                                      ind.levelName +
                                      "Comment1" 
                                  ]
                                } 
                                
                              </i>
                       
                              :""} 
                              <i style={{ fontSize: "12px", color: "grey" }}
                              class= {props.rowData.wld_TrainingReqEmployeeType === "New Welder" ? "wqcwpqno" :""}>
                                {
                                  value[
                                    "wld_TrainingReqApprover" +
                                      ind.levelName +
                                      "Comment2" 
                                  ]
                                }
                                
                              </i>
                                </div>
                                </>

                            )}
                          </div>
                          <div className="ms-2 me-auto"></div>
                          <span
                            style={{
                              fontSize: "12px",
                              color: "grey",
                              paddingRight: "32px",
                            }}
                          >
                            {
                              value[
                                "wld_TrainingReqApprover" +
                                  ind.levelName +
                                  "CreatedOn"
                              ]
                            }
                          </span>
                          <Badge
                            pill
                            style={{
                              position: "relative",
                              color:
                                value[
                                  "wld_TrainingReqApprover" +
                                    ind.levelName +
                                    "Status"
                                ] === "Approved"
                                  ? "white"
                                  : value[
                                      "wld_TrainingReqApprover" +
                                        ind.levelName +
                                        "Status"
                                    ] === "In Progress"
                                  ? "black"
                                  : "white",
                            }}
                            bg={
                              value[
                                "wld_TrainingReqApprover" +
                                  ind.levelName +
                                  "Status"
                              ] === "Approved"
                                ? "success"
                                : value[
                                    "wld_TrainingReqApprover" +
                                      ind.levelName +
                                      "Status"
                                  ] === "In Progress"
                                ? "warning"
                                : "dark"
                            }
                          >
                            {
                              value[
                                "wld_TrainingReqApprover" +
                                  ind.levelName +
                                  "Status"
                              ]
                            }
                          </Badge>
                        </ListGroup.Item>
                      );
                    })}
                  </ListGroup>
                </Form>
                <Row style={{ alignContent: "right" }}>
                  <Col style={{ padding: "0" }}>
                    <div className="container history-box"></div>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
      <Row>
        {alertMessage && (
          <Alert className="alertBox" variant="warning">
            {alertMessage}
          </Alert>
        )}
        <div>
          <div className="float-start m-1 visible">
            <Button variant="outline-primary" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
          {}
          { (props.rowData.wld_status == "Rejected" ||
            props.rowData.wld_status == "In Progress")&&
          (
              <div class="float-end m-1">
                {(props.calledFrom === "Initiated" &&  props.rowData.wld_status != "Rejected") ? (
                  <Button
                    className="btn-add-custom"
                    onClick={() => handleSubmit("Delete")}
                  >
                    Delete
                  </Button>
                ) : (
                  <Button
                    className="btn-add-custom"
                    onClick={() => handleSubmit("Save")}
                    disabled={
                      props.rowData.wld_status === "Rejected" || !disabledRadio
                        ? false
                        : true
                    }
                  >
                    Save
                  </Button>
                )}
              </div>
            )}
        </div>
      </Row>
    </>
  );
};
export default ClosureRemarksQual;
